import React, { useContext, useEffect } from "react";
import SideBarMenu from "../../../Components/SideBarMenu";
import { DefaultDate, HTTPResponse, PageDesignConstants, SideMenuItems, ToastMessageType, ToastMessages } from "../../../Helpers/Enums";
import DateControl from "../../../Components/DateControl";
import { useState } from "react";
import LabTable from "./LabTable";
import SaveAndNavigate from "../../../Components/SaveAndNavigate";
import PatientDetails from "../../../Components/PatientInformation";
import { ClinicalCharacteristics_Msgs, CommonError_Msgs } from "../../../Helpers/HelperText";
import ErrorField from "../../../Components/ErrorField";
import { AllowPositiveDecimalNumbers, GetLocalStorageData, GetLoggedInUserID, SetDateState, isStringEmpty, IsDateEmpty, SubtractMonths, DateDisplayFormat, IsDateValid, GetOrdinal, GetOrdinalInWord, AllowOnlyIntegers, ValueInRange, ParseDate, GetDisplayDate, GetDMUID, ValidateIndex, IsDateBetween, ValidateDtBetweenIndexAndChart, IsValidDate3, IsAfterIndex, IsMonthUnk } from "../../../Helpers/Utilities";
import { AppContext } from "../../../Contexts/AppContextProvider";
import { ClinicalService } from "../../../WebApiServices/Clinical.service"
import LabRowModel from "../../../DataModels/LabRowModel";
//import CCQ18 from "./CCQ18";



const ClinicalCharacteristics = () => {

    // const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    // const [isTooltipClicked, setIsTooltipClicked] = useState(false);
  
    // const handleTooltipToggle = () => {
    //   setIsTooltipClicked(!isTooltipClicked);
    //    setIsTooltipVisible(!isTooltipVisible);
    // };
  
    // const handleMouseEnter = () => {
    //   if (!isTooltipClicked) {
    //     setIsTooltipVisible(true);
    //   }
    // };
  
    // const handleMouseLeave = () => {
    //   if (!isTooltipClicked) {
    //     setIsTooltipVisible(false);
    //   }
    // };

    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isTooltipClicked, setIsTooltipClicked] = useState(false);
  
    const handleTooltipToggle = () => {
      setIsTooltipClicked(!isTooltipClicked);
      setIsTooltipVisible(!isTooltipVisible);
    };
  
    const handleMouseEnter = () => {
      if (!isTooltipClicked) {
        setIsTooltipVisible(true);
      }
    };
  
    const handleMouseLeave = () => {
      // Keep the tooltip visible if it was clicked
      if (!isTooltipClicked) {
        setIsTooltipVisible(false);
      }
    };
  
    const navigatePaths = {
        prevPage: "/Demographics",
        currPage: "/ClinicalCharacteristics",
        nextPage: "/Epcoritamab",
    };
    let patientData = GetLocalStorageData("patient")
    patientData = patientData && JSON.parse(patientData);
    const patientId = patientData?.patientId ?? 0;
    const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout } = useContext(AppContext);
    const [showTopErrMsg, setShowTopErrMsg] = useState(false);

    const [chartAbstractionDate, setChartAbstractionDate] = useState("");

    const [showLabtable, setShowLabtable] = useState("");
    const InitialQ11ChkBoxFields = {
        q11_infla_bowel_dis: false,
        q11_diabetes: false,
        q11_cerebro_dis: false,
        q11_cardio_dis: false,
        q11_cardio_dis_txt: "",
        q11_cns: false,
        q11_cns_txt: "",
        q11_psychiatric: false,
        q11_hiv: false,
        q11_hepatic: false,
        q11_obesity: false,
        q11_rheuma_dis: false,
        q11_peptic_ulcer: false,
        q11_renal_dys: false,
        q11_pulmo_dys: false,
        q11_cancer: false,
        q11_oth: false,
        q11_oth_txt: "",
    } 
    const InitialQ27ChkBoxFields = {
        q27_fever: false,
        q27_sweats: false,
        q27_weight_loss: false,
        q27_lack_energy: false,
        q27_tired: false,
        q27_body_pain: false,
        q27_oth: false,
        q27_oth_txt: "",
    }
    const InitialSoftErrList = {
        q11Unk: false,
        q12DateUnk: false,
        q12TypeUnk: false,
        q18GradeUnk: false,
        q18LymClassUnk: false,
        q20StageUnk: false,
        q21StageDescrUnk: false,


        q22Unk: false,
        q23Unk: false,
        q24SizeUnk: false,
        q14Unk: false,
        q19Unk: false,
        q25Unk: false,
        q26Unk: false,

        q27Unk: false,

        q13Unk: false,
        q29Unk: false,
        q33Unk: false,
    }
    const InitialHardErrList = {
        q11Empty: false,
        //q11TxtEmpty:false,
        q11cardioTxtEmpty: false,
        q11cnsTxtEmpty: false,
        q11othTxtEmpty: false,
        q12Empty: false,//unk not sel, date not complete
        q12DateValidation:false,
        q12TypeEmpty: false,
        q12TypeTxtEmpty: false,
        q13Empty: false,
        q13TxtEmpty: false,
        q14DateEmpty: false,
        q14Validation:false,
        q14StatusEmpty: false,
        q15Empty: false,
        q18GradeEmpty: false,
        q18LymClassEmpty: false,
        q19Empty: false,
        q20StageEmpty: false,
        q20SubEmpty: false,
        q21StageDescrEmpty: false,
        q21SubEmpty: false,
        q22Empty: false,
        q22TxtEmpty: false,
        q22ExtranodalNodesRangeLimHard: false,
        q23Empty: false,
        q23TxtEmpty: false,
        q23NodalNodesRangeLimHard: false,
        q24DateEmpty: false,
        q24DateValidation:false,
        q24SizeEmpty: false,
        q24SizeTxtEmpty: false,
        q24SizeRangeLimHard: false,
        q25Empty: false,
        q26Empty: false,
        q27Empty: false,
        q27TxtEmpty: false,
        q29Empty: false,
        q33Empty: false,
        labTableNotaEmpty: false
    }
    const [softErrList, setSoftErrList] = useState(InitialSoftErrList)
    const [hardErrList, setHardErrList] = useState(InitialHardErrList)
    const [ccDetails, setCCDetails] = useState({
        clinicalCharacteristicsId: 0,
        ...InitialQ11ChkBoxFields,
        q11_none_unk: null,
        q12_diagnosis: null,//radio state holder
        q12_diagnosis_date: DefaultDate,//""
        q12_cancer_type: null,
        q13_conf_dx_mal: null,
        q13_conf_dx_mal_txt: "",
        q14_assessment_date: DefaultDate,
        q14_ecog_status: null,

        q15_ecog_assess: null,
        q18_hist_grade: null,
        q18_lym_class: null,
        q19_mol_class: null,
        q20_ann_arbor_stage: null,
        q20_sub_question: null,
        q21_stage_descr: null,
        q21_sub_question: null,
        q22_extranodal_site: null,
        q22_extranodal_site_txt: "",
        q23_nodal_site: null,
        q23_nodal_site_txt: "",
        q24_assessment_date: DefaultDate,
        q24_size: null,
        q24_size_txt: "",
        q25_ipi_score: null,
        q26_cd20_status: null,
        ...InitialQ27ChkBoxFields,
        q27_none_unk: null,
        q29_covid_vax: null,
        q33_covid_dx: null,
        labTableNota: false,
    })
    const labTestTypeIdArray = //Array.from({ length: 15 }, (_, index) => index + 1);
     //pass the test id and id for radio beside the text under unit column
     [
         { num: 1, unitOthTxtId: 4 },
         { num: 2, unitOthTxtId: 5 },
         { num: 3, unitOthTxtId: 5 },
         { num: 4, unitOthTxtId: 2 },
         { num: 5, unitOthTxtId: 2 },
         { num: 6, unitOthTxtId: 2 },
         { num: 7, unitOthTxtId: 2 },
         { num: 8, unitOthTxtId: 3 },
         { num: 9, unitOthTxtId: 4 },
         { num: 10, unitOthTxtId: 2 },
         { num: 11, unitOthTxtId: 3 },
         { num: 12, unitOthTxtId: 2 },
         { num: 13, unitOthTxtId: 2 },
         { num: 14, unitOthTxtId: 2 },
         { num: 15, unitOthTxtId: 3 },
     ];
    const [q28LabData, setQ28LabData] = useState(labTestTypeIdArray.map((a) => (
        new LabRowModel({ LabTestTypeId: a.num, UnitTxtId: a.unitOthTxtId })
    )));
    // const labTestTypeIdArray = Array.from({ length: 15 }, (_, index) => index + 1);
    // const [q28LabData, setQ28LabData] = useState(labTestTypeIdArray.map((num) => (
    //     new LabRowModel(num)
    // )));
    //Q16 ECOG Assessment rpt
    const ECOGAssesmentRow = {
        ecogScoreDetailsId: 0,
        q16_assessment_date: DefaultDate,
        q16_ecog_status: null,

        q16StatusUnkSoft: false,
        q16DateEmptyHard: false,
        // q16DateValidation:false,
        q16StatusEmptyHard: false,
        q16DatOnOrBeforeChartAbs: false,
        q16DateAfterIndex: false,
    }
    //Q30,31 COVID-19 Vaccine rpt
    const CovidVaccineRow = {
        covidVaccineDetailsid: 0,
        covid_vax: null,
        covid_vax_date: "MM/UNK/YYYY",//DefaultDate,
        company: null,
        company_oth_txt: "",

        covidVaxDateUnkSoft: false,
        covidVaxEmptyHard: false,
        covidVaxAfterHard:false,
        companyUnkSoft: false,
        companyEmptyHard: false,
        companyTxtEmptyHard: false,
    }
    //Q34 COVID-19 Covid Diagnosis rpt
    const CovidDiagnosisRow = {
        covidDiagnosisDetailsId: 0,
        covid_dx: null,
        covid_dx_date: DefaultDate,

        covidDxDateUnkSoft: false,
        covidDxEmptyHard: false,
    }
    const [ecogAssessmentsDetails, setECOGAssessmentsDetails] = useState([ECOGAssesmentRow]);
    const [covidVaccineDetails, setCovidVaccineDetails] = useState([CovidVaccineRow]);
    const [covidDiagnosisDetails, setCovidDiagnosisDetails] = useState([CovidDiagnosisRow]);
    const [index_date, setIndexDate] = useState('[index_date]');//""
    const [index_dt_minus_6mo, setIndexDateMinus6Months] = useState('[index_date] - 6 months')
    const [index_dt_minus_12mo, setIndexDateMinus12Months] = useState('[index_date] - 12 months')
    const HandleQ11FieldChange = (e) => {
        
        const { name, id, value } = e.target;
        switch (name) {
            //checkbox
            case "q11_infla_bowel_dis":
            case "q11_diabetes":
            case "q11_cerebro_dis":
            case "q11_psychiatric":
            case "q11_hiv":
            case "q11_hepatic":
            case "q11_obesity":
            case "q11_rheuma_dis":
            case "q11_peptic_ulcer":
            case "q11_renal_dys":
            case "q11_pulmo_dys": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: !prev[name],
                    q11_none_unk: null,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q11Unk: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q11Empty: false,
                    q11cardioTxtEmpty:false,
                    q11cnsTxtEmpty:false,
                    q11othTxtEmpty:false,
                }));
                break;
            }
            case "q11_cancer": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: !prev[name],
                    q11_none_unk: null,
                    q12_diagnosis: null,
                    q12_diagnosis_date: DefaultDate,//reset q12 date
                    q12_cancer_type: null,
                    q12_cancer_type_oth_txt: "",
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q12DateUnk: false,
                    q12TypeUnk: false
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q12Empty: false,
                    q12TypeEmpty: false,
                    q12TypeTxtEmpty: false,
                    q12DateValidation:false,

                    q11cardioTxtEmpty:false,
                    q11cnsTxtEmpty:false,
                    q11othTxtEmpty:false,
                }));
                break;
            }
            //checkbox with text
            case "q11_cardio_dis":
            case "q11_cns":
            case "q11_oth": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: !prev[name],
                    [`${name}_txt`]: "",
                    q11_none_unk: null,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q11Unk: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q11Empty: false,
                 
                    q11cardioTxtEmpty:false,
                    q11cnsTxtEmpty:false,
                    q11othTxtEmpty:false,
                }));
                break;
            }
            //text
            case "q11_cardio_dis_txt":
            case "q11_cns_txt":
            case "q11_oth_txt": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value,
                    [`${name.replace('_txt', '')}`]: true,
                    q11_none_unk: null,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q11Unk: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q11Empty: false,
                    [`q11${name.split('_')[1]}TxtEmpty`]: false,
            

                }));
                break;
            }
            //radio
            case "q11_none_unk": {
                const value_int = Number(value);
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                    ...InitialQ11ChkBoxFields
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q11Unk: value_int === 99//unknown selected
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q11Empty: false,
                    q11cardioTxtEmpty: false,
                    q11cnsTxtEmpty: false,
                    q11othTxtEmpty: false,
                }));
                break;
            }
            default:
                break;
        }

    }
    const HandleQ12FieldChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "q12_diagnosis": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: Number(value),
                    q12_diagnosis_date: DefaultDate,//reset q12 date
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q12DateUnk: Number(value) === 99//unknown selected
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q12Empty: false,
                    q12DateValidation:false,
                }));
                break;
            }
            case "q12_cancer_type": {
                setCCDetails((prev) => ({
                    ...prev,
                    q12_cancer_type: Number(value),
                    q12_cancer_type_oth_txt: ""
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q12TypeUnk: Number(value) === 99//unknown selected
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q12TypeEmpty: false,
                    q12TypeTxtEmpty: false,
                }));
                break;
            }
            case "q12_cancer_type_oth_txt": {
                setCCDetails((prev) => ({
                    ...prev,
                    q12_cancer_type_oth_txt: value,
                    q12_cancer_type: 6,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q12TypeUnk: false
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q12TypeEmpty: false,
                    q12TypeTxtEmpty: false,
                }));
                break;
            }
            default: break;
        }
    }
    const HandleQ13FieldChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "q13_conf_dx_mal": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: Number(value),
                    [`${name}_txt`]: ""
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q13Empty: false,
                    q13TxtEmpty: false,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q13Unk: Number(value)===99,
                }));
                break;
            }
            case "q13_conf_dx_mal_txt": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value,
                    [`${name.replace('_txt', '')}`]: 1
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q13Empty: false,
                    q13TxtEmpty: false,
                }));    
                break;
            }
            default: break;
        }
    }
    //This event handler is used only for questions with radio buttons
    const HandleFieldChange = (e) => { 
        const { name, value } = e.target;
        const value_int = Number(value);
        switch (name) {
            case "q15_ecog_assess": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q15Empty: false,
                }))
                setECOGAssessmentsDetails([{
                    ...ECOGAssesmentRow
                }]);
                break;
            }
            case "q20_ann_arbor_stage": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                    q20_sub_question: null,
                    q21_stage_descr: null,
                    q21_sub_question: null,
                    q22_extranodal_site: null,
                    q22_extranodal_site_txt: "",
                    q23_nodal_site: null,
                    q23_nodal_site_txt: "",
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q20StageUnk: value_int === 99,
                    q21StageDescrUnk: false,
                    q22Unk: false,
                    q23Unk: false
                }))
                setHardErrList((prev) => ({
                    ...prev,
                    q20StageEmpty: false,
                    q20SubEmpty: false,
                    q21StageDescrEmpty: false,
                    q21SubEmpty: false,
                    q22Empty: false,
                    q22TxtEmpty: false,
                    q22ExtranodalNodesRangeLimHard: false,
                    q23Empty: false,
                    q23TxtEmpty: false,
                    q23NodalNodesRangeLimHard: false
                }));
                break;
            }
            case "q20_sub_question": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q20SubEmpty: value_int === 99,
                }))
                setHardErrList((prev) => ({
                    ...prev,
                    q20SubEmpty: false,
                }));
                break;
            }
            case "q21_stage_descr": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                    q21_sub_question: null,
                    q22_extranodal_site: null,
                    q22_extranodal_site_txt: "",
                    q23_nodal_site: null,
                    q23_nodal_site_txt: "",
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q21StageDescrUnk: value_int === 99,
                    q22Unk: false,
                    q23Unk: false
                }))
                setHardErrList((prev) => ({
                    ...prev,
                    q21StageDescrEmpty: false,
                    q21SubEmpty: false,
                    q22Empty: false,
                    q22TxtEmpty: false,
                    q22ExtranodalNodesRangeLimHard: false,
                    q23Empty: false,
                    q23TxtEmpty: false,
                    q23NodalNodesRangeLimHard: false
                }));
                break;
            }
            case "q21_sub_question": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q21SubEmpty: false,
                }));
                break;
            }
            case "q22_extranodal_site": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                    q22_extranodal_site_txt: ""
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q22Unk: value_int === 99
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q22Empty: false,
                    q22TxtEmpty: false,
                    q22ExtranodalNodesRangeLimHard: false,
                }));
                break;
            }
            case "q22_extranodal_site_txt": {
                if (value !== "" && !AllowOnlyIntegers(value)) {
                    break;
                }
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value,
                    q22_extranodal_site: 1
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q22Unk: false
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q22Empty: false,
                    q22TxtEmpty: false,
                    q22ExtranodalNodesRangeLimHard: false,
                }));
                break;
            }
            case "q23_nodal_site": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                    q23_nodal_site_txt: ""
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q23Unk: value_int === 99
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q23Empty: false,
                    q23TxtEmpty: false,
                    q23NodalNodesRangeLimHard: false
                }));
                break;
            }
            case "q23_nodal_site_txt": {
                if (value !== "" && !AllowOnlyIntegers(value)) {
                    break;
                }
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value,
                    q23_nodal_site: 1
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q23Unk: false
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q23Empty: false,
                    q23TxtEmpty: false,
                    q23NodalNodesRangeLimHard: false
                }));
                break;
            }
            case "q24_size": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                    q24_size_txt: ""
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q24SizeUnk: value_int === 99,
                }))
                setHardErrList((prev) => ({
                    ...prev,
                    q24SizeEmpty: false,
                    q24SizeTxtEmpty: false,
                    q24SizeRangeLimHard: false
                }))
                break;
            }
            case "q24_size_txt": {
                if (!AllowPositiveDecimalNumbers(value)) {
                    break;
                }
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value,
                    q24_size: 1
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q24SizeUnk: false,
                }))
                setHardErrList((prev) => ({
                    ...prev,
                    q24SizeEmpty: false,
                    q24SizeTxtEmpty: false,
                    q24SizeRangeLimHard: false
                }))
                break;
            }
            //radios with unknown option
            case "q14_ecog_status": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q14Unk: value_int === 99
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q14StatusEmpty: false,
                }));
                break;
            }
            case "q18_hist_grade": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                    q18_lym_class: null,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q18GradeUnk: value_int === 99,
                    q18LymClassUnk: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q18GradeEmpty: false,
                    q18LymClassEmpty: false,
                }));
                break;
            }
            case "q18_lym_class": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q18LymClassUnk: value_int === 99
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q18LymClassEmpty: false,
                }));
                break;
            }
            // case "q19_mol_class": {
            //     setCCDetails((prev) => ({
            //         ...prev,
            //         [name]: value_int,
            //     }));
            //     break;
            // }
            case "q19_mol_class":
            case "q25_ipi_score":
            case "q26_cd20_status": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                }));
                //show soft err if unknown is selected
                setSoftErrList((prev) => ({
                    ...prev,
                    [`${name.split('_')[0]}Unk`]: value_int === 99
                }))
                setHardErrList((prev) => ({
                    ...prev,
                    [`${name.split('_')[0]}Empty`]: false,
                }));
                break;
            }
            //radios without unknown option
            case "q29_covid_vax": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    [`${name.split('_')[0]}Empty`]: false
                }))
                setCovidVaccineDetails([{
                    ...CovidVaccineRow
                }]);
                setSoftErrList((prev) => ({
                    ...prev,
                    q29Unk: value_int===99,
                }));
                break;
            }
            case "q33_covid_dx": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    [`${name.split('_')[0]}Empty`]: false
                }))
                setCovidDiagnosisDetails([{
                    ...CovidDiagnosisRow
                }]);
                setSoftErrList((prev) => ({
                    ...prev,
                    q33Unk: value_int === 99
                }))
                break;
            }

            case "labTableNota": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: !prev[name],
                }));

                setHardErrList( prev => ({
                    ...prev,
                    labTableNotaEmpty: false
                }));

                const newLabTableNotaValue = !ccDetails[name];
               
                setShowLabtable(newLabTableNotaValue)

                // Reset q28LabData when labTableNota is checked
                if (newLabTableNotaValue) {
                    setQ28LabData(resetQ28LabData());
                }
                
                break;
            }
            default: break;
        }
    }
    const HandleQ27FieldChange = (e) => {
        const { name, id, value } = e.target;
        switch (name) {
            //checkbox 
            case "q27_fever":
            case "q27_sweats":
            case "q27_weight_loss":
            case "q27_lack_energy":
            case "q27_tired":
            case "q27_body_pain": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: !prev[name],
                    q27_none_unk: null,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q27Unk: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q27Empty: false,
                    q27TxtEmpty:false,
                }));
                break;
            }
            //checkbox with text 
            case "q27_oth": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: !prev[name],
                    q27_oth_txt: "",
                    q27_none_unk: null,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q27Unk: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q27Empty: false,
                    q27TxtEmpty: false,
                }));
                break;
            }
            //text 
            case "q27_oth_txt": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value,
                    q27_oth: true,
                    q27_none_unk: null,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q27Unk: false,
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q27Empty: false,
                    q27TxtEmpty: false,
                }));
                break;
            }
            //radio
            case "q27_none_unk": {
                const value_int = Number(value);
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                    ...InitialQ27ChkBoxFields
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q27Unk: value_int === 99
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q11Empty: false,
                    q27Empty: false,
                    q27TxtEmpty: false,
                }));
                break;
            }
            default:
                break;
        }

    }
    const HandleDateChange = (name, date, datePart) => {
        switch (name) {
            //date with radio beside it
            case "q12_diagnosis_date": {
                setCCDetails((prev) => ({
                    ...prev,
                    q12_diagnosis: 1,//[`${name.replace('_date', '')}`]: 1,
                    [name]: SetDateState(prev.q12_diagnosis_date, date, datePart),
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q12DateUnk: false
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q12Empty: false,
                    q12DateValidation:false,
                }));
                break;
            }
            //date without radio
            case "q14_assessment_date": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: SetDateState(prev[name], date, datePart),
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q14DateEmpty: false,
                    q14Validation:false,
                }));
                break;
            }
            case "q24_assessment_date": {
                setCCDetails((prev) => ({
                    ...prev,
                    [name]: SetDateState(prev[name], date, datePart),
                }));
                setHardErrList((prev) => ({
                    ...prev,
                    q24DateEmpty: false,
                    q24DateValidation: false,
                }));
                break;
            }
            default: break;
        }
    };
    const HandleQ28DateChange = (name, date, datePart) => {
        const id = name?.split("-")?.[1] || null;
        if (q28LabData != null && q28LabData[id - 1] != null) {
            switch (name) {
                case "lab_collection_date-" + id: {
                    let updatedArray = [...q28LabData];
                    updatedArray[id - 1].collectionDateSelect = 1;
                    updatedArray[id - 1].collectionDate = SetDateState(updatedArray[id - 1].collectionDate, date, datePart);
                    updatedArray[id - 1].collectDtUnkSoft = false;
                    updatedArray[id - 1].collectDtEmptyHard = false;
                    updatedArray[id-1].collectionDateInvalid = false;
                    setQ28LabData(updatedArray);
                    break;
                }
                default: break;
            }
        }
    };

    const resetQ28LabData = () => {
        return q28LabData.map(item => ({
            ...item,
            selected: false,

        }));
    };
    
    const HandleQ28FieldChange = (e) => {
        const { name, id, value } = e.target;

        if (q28LabData != null && q28LabData[id - 1] != null) {
            setCCDetails((prev) => ({
                ...prev,
                labTableNota: false
            }));
            // setHardErrList( prev => ({
            //     ...prev,
            //     labTableNotaEmpty: false
            // }) )
            const txtRadioId = name?.split("-")?.[1] || null;
            switch (name) {
                case "lab_selected" + id: {
                    setHardErrList( prev => ({
                        ...prev,
                        labTableNotaEmpty: false
                    }) )
                    let updatedArray = [...q28LabData];                  
                    updatedArray[id - 1].selected = !updatedArray[id - 1].selected;

                    updatedArray[id - 1].collectionDateSelect = null;
                    updatedArray[id - 1].collectionDate = DefaultDate;
                    updatedArray[id - 1].collectDtUnkSoft = Number(value) === 99;
                    updatedArray[id - 1].collectDtEmptyHard = false;
                    updatedArray[id - 1].collectionDateInvalid = false;


                    updatedArray[id - 1].assessSelect = null;
                    updatedArray[id - 1].assessTxt = "";
           
                    updatedArray[id - 1].assessEmptyHard = false;
                    updatedArray[id - 1].assessTxtEmptyHard = false;
                    updatedArray[id - 1].assessRangeLimitHard = false;



                    updatedArray[id - 1].assessUnkSoft = false;



                    updatedArray[id - 1].upperLimitSelect = null;
                    updatedArray[id - 1].upperLimitTxt = "";
                    updatedArray[id - 1].upperLimUnkSoft = false;
                    updatedArray[id - 1].upperLimEmptyHard = false;
                    updatedArray[id - 1].upperLimTxtEmptyHard = false;
                    updatedArray[id - 1].upperLimRangeLimitHard = false;



                    updatedArray[id - 1].lowerLimitSelect = null;
                    updatedArray[id - 1].lowerLimitTxt = "";
                    updatedArray[id - 1].lowerLimUnkSoft = Number(value) === 99;
                    updatedArray[id - 1].lowerLimEmptyHard = false;
                    updatedArray[id - 1].lowerLimTxtEmptyHard = false;
                    updatedArray[id - 1].lowerLimRangeLimitHard = false;


                    updatedArray[id - 1].unitSelect = null;
                    updatedArray[id - 1].unitTxt = "";
                    updatedArray[id - 1].unitUnkSoft = Number(value) === 99;
                    updatedArray[id - 1].unitEmptyHard = false;
                    updatedArray[id - 1].unitTxtEmptyHard = false;
                    

                    updatedArray[id - 1].unitTxt = "";
                    updatedArray[id - 1].unitSelect = null;

                    setQ28LabData(updatedArray);
                    break;
                }



                case "lab_collection" + id: {
                    let updatedArray = [...q28LabData];
                    updatedArray[id - 1].collectionDateSelect = Number(value);
                    updatedArray[id - 1].collectionDate = DefaultDate;
                    updatedArray[id - 1].collectDtUnkSoft = Number(value) === 99;
                    updatedArray[id - 1].collectDtEmptyHard = false;
                    updatedArray[id - 1].collectionDateInvalid = false;
                    setQ28LabData(updatedArray);
                    break;
                }
                
                case "q28_assess" + id: {
                    let updatedArray = [...q28LabData];
                    updatedArray[id - 1].assessSelect = Number(value);
                    updatedArray[id - 1].assessTxt = "";
                    updatedArray[id - 1].assessUnkSoft = Number(value) === 99;
                    updatedArray[id - 1].assessEmptyHard = false;
                    updatedArray[id - 1].assessTxtEmptyHard = false;
                    updatedArray[id - 1].assessRangeLimitHard = false;
                    setQ28LabData(updatedArray);
                    break;
                }
                case "q28_assess_txt" + id: {
                    if (AllowPositiveDecimalNumbers(value)) {
                        let updatedArray = [...q28LabData];
                        updatedArray[id - 1].assessSelect = 1;
                        updatedArray[id - 1].assessUnkSoft = false;
                        updatedArray[id - 1].assessEmptyHard = false;
                        updatedArray[id - 1].assessTxtEmptyHard = false;
                        updatedArray[id - 1].assessRangeLimitHard = false;
                        updatedArray[id - 1].assessTxt = (value);
                        setQ28LabData(updatedArray);
                    }
                    break;
                }
                case "q28_upper_lim" + id: {
                    let updatedArray = [...q28LabData];
                    updatedArray[id - 1].upperLimitSelect = Number(value);
                    updatedArray[id - 1].upperLimitTxt = "";
                    updatedArray[id - 1].upperLimUnkSoft = Number(value) === 99;
                    updatedArray[id - 1].upperLimEmptyHard = false;
                    updatedArray[id - 1].upperLimTxtEmptyHard = false;
                    updatedArray[id - 1].upperLimRangeLimitHard = false;
                    setQ28LabData(updatedArray);
                    break;
                }
                case "q28_upper_lim_txt" + id: {
                    if (AllowPositiveDecimalNumbers(value)) {
                        let updatedArray = [...q28LabData];
                        updatedArray[id - 1].upperLimitSelect = 1;
                        updatedArray[id - 1].upperLimitTxt = (value);
                        updatedArray[id - 1].upperLimUnkSoft = false;
                        updatedArray[id - 1].upperLimEmptyHard = false;
                        updatedArray[id - 1].upperLimTxtEmptyHard = false;
                        updatedArray[id - 1].upperLimRangeLimitHard = false;
                        setQ28LabData(updatedArray);
                    }
                    break;
                }
                case "q28_lower_lim" + id: {
                    let updatedArray = [...q28LabData];
                    updatedArray[id - 1].lowerLimitSelect = Number(value);
                    updatedArray[id - 1].lowerLimitTxt = "";
                    updatedArray[id - 1].lowerLimUnkSoft = Number(value) === 99;
                    updatedArray[id - 1].lowerLimEmptyHard = false;
                    updatedArray[id - 1].lowerLimTxtEmptyHard = false;
                    updatedArray[id - 1].lowerLimRangeLimitHard = false;
                    setQ28LabData(updatedArray);
                    break;
                }
                case "q28_lower_lim_txt" + id: {
                    if (AllowPositiveDecimalNumbers(value)) {
                        let updatedArray = [...q28LabData];
                        updatedArray[id - 1].lowerLimitSelect = 1;
                        updatedArray[id - 1].lowerLimitTxt = (value);
                        updatedArray[id - 1].lowerLimUnkSoft = false;
                        updatedArray[id - 1].lowerLimEmptyHard = false;
                        updatedArray[id - 1].lowerLimTxtEmptyHard = false;
                        updatedArray[id - 1].lowerLimRangeLimitHard = false;
                        setQ28LabData(updatedArray);
                    }
                    break;
                }
                case "q28_unit" + id + "-" + txtRadioId: {
                    let updatedArray = [...q28LabData];
                    updatedArray[id - 1].unitSelect = Number(value);
                    updatedArray[id - 1].unitTxt = "";
                    // if (Number(txtRadioId)) {
                    //     updatedArray[id - 1].unitTxtId = Number(txtRadioId);
                    // }
                    updatedArray[id - 1].unitUnkSoft = Number(value) === 99;
                    updatedArray[id - 1].unitEmptyHard = false;
                    updatedArray[id - 1].unitTxtEmptyHard = false;
                    setQ28LabData(updatedArray);
                    break;
                }
                case "q28_unit_txt" + id + "-" + txtRadioId: {
                    let updatedArray = [...q28LabData];
                    updatedArray[id - 1].unitTxt = value;
                    updatedArray[id - 1].unitSelect = Number(txtRadioId);
                    //updatedArray[id - 1].unitTxtId = Number(txtRadioId);

                    updatedArray[id - 1].unitUnkSoft = false;
                    updatedArray[id - 1].unitEmptyHard = false;
                    updatedArray[id - 1].unitTxtEmptyHard = false;
                    setQ28LabData(updatedArray);
                    break;
                }
                default: break;
            }
        }
    };
    const SavePage = async (valid) => {
        try {
            ToggleLoader(true);
            //remove soft errors on click of Save
            setSoftErrList(InitialSoftErrList);
            setHardErrList(InitialHardErrList);


            // if (!Validate()) {
            //     setShowTopErrMsg(true);
            //     ToggleLoader(false);
            //     return false;
            // }
            // setShowTopErrMsg(false);

            let isValid = valid ? Validate() : true;

            
      //12
      if(!IsDateEmpty(ccDetails.q12_diagnosis_date) && !IsDateValid(ccDetails.q12_diagnosis_date))
      {
         isValid = false;
      }

      //14
          
          if(!IsDateEmpty(ccDetails.q14_assessment_date) && !IsDateValid(ccDetails.q14_assessment_date))
          {
             isValid = false;
          }

      //16
      if ( ecogAssessmentsDetails && ecogAssessmentsDetails.length > 0) {
        ecogAssessmentsDetails.map(item => {
           if (!IsDateEmpty(item.q16_assessment_date) && !IsDateValid(item.q16_assessment_date)) {
             isValid = false;
           } 
       
           return item; 
         });
         
       }

            //24
          
            if(!IsDateEmpty(ccDetails.q24_assessment_date) && !IsDateValid(ccDetails.q24_assessment_date))
            {
               isValid = false;
            }

            //34
            if ( covidDiagnosisDetails && covidDiagnosisDetails.length > 0) {
                covidDiagnosisDetails.map(item => {
                   if (!IsDateEmpty(item.covid_dx_date) && !IsDateValid(item.covid_dx_date)) {
                     isValid = false;
                   } 
               
                   return item; 
                 });
                 
               }

               //28
               if ( q28LabData && q28LabData.length > 0) {
                q28LabData.map(item => {
                   if (!IsDateEmpty(item.collectionDate) && !IsDateValid(item.collectionDate)) {
                     isValid = false;
                   } 
               
                   return item; 
                 });
                 
               }

            if (isValid) {
                setShowTopErrMsg(false)
            const clinicalModel = {
                ClinicalDetails: {
                    ClinicalCharacteristicsId: ccDetails.clinicalCharacteristicsId,
                    //Q11
                    ComorbidInflammatoryBowel: ccDetails.q11_infla_bowel_dis,
                    ComorbidDiabetes: ccDetails.q11_diabetes,
                    ComorbidCerebrovascular: ccDetails.q11_cerebro_dis,
                    ComorbidCardiovascular: ccDetails.q11_cardio_dis,
                    ComorbidCardiovascularOthText: ccDetails.q11_cardio_dis_txt,
                    ComorbidPrimaryCentralNervous: ccDetails.q11_cns,
                    ComorbidPrimaryCentralNervousOthTxt: ccDetails.q11_cns_txt,
                    ComorbidPsychiatric: ccDetails.q11_psychiatric,
                    ComorbidHiv: ccDetails.q11_hiv,
                    ComorbidHepatic: ccDetails.q11_hepatic,
                    ComorbidObesity: ccDetails.q11_obesity,
                    ComorbidRheumatologic: ccDetails.q11_rheuma_dis,
                    ComorbidPeptic: ccDetails.q11_peptic_ulcer,
                    ComorbidRenal: ccDetails.q14Comorb_renal_failure,
                    ComorbidPulmonary: ccDetails.q11_pulmo_dys,
                    ComorbidCancer: ccDetails.q11_cancer,
                    ComorbidOth: ccDetails.q11_oth,
                    ComorbidOthTxt: ccDetails.q11_oth_txt,
                    ComorbidNocondition: ccDetails.q11_none_unk === 1,
                    ComorbidUnk: ccDetails.q11_none_unk === 99,
                    //Q12
                    
                    CancerDiagnosedDate: ccDetails.q11_cancer && ccDetails.q12_diagnosis === 1 &&  !IsDateEmpty(ccDetails.q12_diagnosis_date) ? ParseDate(ccDetails.q12_diagnosis_date) : null,//ParseDate(ccDetails.q12_diagnosis_date) ParseDate not req as UNK not an option
                    CancerDiagnosedDateDmuid:ccDetails.q12_diagnosis === 1? GetDMUID(ccDetails.q12_diagnosis_date):null ,

                    CancerDiagnosedDateUnk: ccDetails.q11_cancer ? ccDetails.q12_diagnosis === 99 : null,
                    Cancertype: ccDetails.q11_cancer ? ccDetails.q12_cancer_type : null,
                    CancertypeOthTxt: ccDetails.q11_cancer && ccDetails.q12_cancer_type === 6 ? ccDetails.q12_cancer_type_oth_txt : "",
                    //Q13
                    DiagnosisOtherMalignancies: ccDetails.q13_conf_dx_mal,
                    DiagnosisOtherMalignanciesTxt: ccDetails.q13_conf_dx_mal_txt,
                    //Q14
                    //EcogAssessmentDate: ccDetails.q14_assessment_date,
                    EcogAssessmentDate: ccDetails.q14_assessment_date && !IsDateEmpty(ccDetails.q14_assessment_date) ? ParseDate(ccDetails.q14_assessment_date) : null,
                    EcogAssessmentDateDmuid:ccDetails.q14_assessment_date? GetDMUID(ccDetails.q14_assessment_date):null,

                    Ecogstatus: ccDetails.q14_ecog_status,
                    //Q15
                    EcogAssesmentAfterindexdate: ccDetails.q15_ecog_assess,
                    //Q18
                    HistologicGrade: ccDetails.q18_hist_grade,
                    LymphomaClassification: ccDetails.q18_lym_class,
                    //Q19
                    MolecularClassification: ccDetails.q19_mol_class,
                    //Q20
                    AnnArborDiseaseStage: ccDetails.q20_ann_arbor_stage,
                    AnnArborDiseaseStageOptions: ccDetails.q20_sub_question,
                    //Q21
                    PatientsDiseaseStage: ccDetails.q20_ann_arbor_stage === 99 ? ccDetails.q21_stage_descr : null,
                    PatientsDiseaseStageOptions: ccDetails.q20_ann_arbor_stage === 99 ? ccDetails.q21_sub_question : null,
                    //Q22
                    MoreExtranodalSite: ccDetails.q20_ann_arbor_stage === 4 || ccDetails.q21_stage_descr === 4 ? ccDetails.q22_extranodal_site : null,
                    MoreExtranodalSiteTxt: ccDetails.q20_ann_arbor_stage === 4 || ccDetails.q21_stage_descr === 4 ? ccDetails.q22_extranodal_site_txt : "",
                    //Q23
                    MoreNodalSite: ccDetails.q20_ann_arbor_stage === 4 || ccDetails.q21_stage_descr === 4 ? ccDetails.q23_nodal_site : null,
                    MoreNodalSiteTxt: ccDetails.q20_ann_arbor_stage === 4 || ccDetails.q21_stage_descr === 4 ? ccDetails.q23_nodal_site_txt : null,
                    //Q24
                    LymphNodeAssessmentDate: ccDetails.q24_assessment_date && !IsDateEmpty(ccDetails.q24_assessment_date)?ParseDate(ccDetails.q24_assessment_date):null,
                    LymphNodeAssessmentDateDmuid:ccDetails.q24_assessment_date? GetDMUID(ccDetails.q24_assessment_date):null,

                    LymphNodeResultTxt: ccDetails.q24_size === 1 ? ccDetails.q24_size_txt : "",
                    LymphNodeResultUnk: ccDetails.q24_size === 99,
                    //Q25
                    IntPrognosticIndexRiskscore: ccDetails.q25_ipi_score,
                    //Q26
                    Cd20Status: ccDetails.q26_cd20_status,
                    //Q27
                    BSymptomFever: ccDetails.q27_fever,
                    BSymptomDrenching: ccDetails.q27_sweats,
                    BSymptomWeightloss: ccDetails.q27_weight_loss,
                    BSymptomEnergyloss: ccDetails.q27_lack_energy,
                    BSymptomTired: ccDetails.q27_tired,
                    BSymptomBodypain: ccDetails.q27_body_pain,
                    BSymptomOth: ccDetails.q27_oth,
                    BSymptomOthTxt: ccDetails.q27_oth ? ccDetails.q27_oth_txt : "",
                    BNosymtoms: ccDetails.q27_none_unk === 1,
                    BSymptomUnk: ccDetails.q27_none_unk === 99,

                    //Q29
                    ReceiveCovidVaccine: ccDetails.q29_covid_vax,
                    //Q33
                    RecordedCovidDiagnosis: ccDetails.q33_covid_dx,
                    LabTableNota: ccDetails.labTableNota
                },
                EcogScoreDetailsList: [],
                LabResultDetailsList: [],
                CovidVaccineDetailsList: [],
                CovidDiagnosisDetailsList: [],
                PatientId: patientId,// GetLocalStorageData("patientId") ?? 0,
                UserId: GetLoggedInUserID(),
                isValidated: valid,
            }
            //ECOG Assessment rpt
            if (ccDetails.q15_ecog_assess === 1 && ecogAssessmentsDetails && ecogAssessmentsDetails.length > 0) {
                clinicalModel.EcogScoreDetailsList = ecogAssessmentsDetails.map((a) => ({
                    Ecogscoredetailsid: a.ecogScoreDetailsId,
                    EcogscoreAssessmentDate: a.q16_assessment_date && !IsDateEmpty(a.q16_assessment_date)?ParseDate(a.q16_assessment_date):null,
                    EcogscoreAssessmentDateDmuid:a.q16_assessment_date? GetDMUID(a.q16_assessment_date):null ,
                    Ecogscore: a.q16_ecog_status
                }));
            }
            if (q28LabData && q28LabData.length > 0) {
                clinicalModel.LabResultDetailsList = q28LabData.map((a) => ({
                    Labresultsid: a.labResultsId,
                    Labtesttypeid: a.labTestTypeId,
                    CollectionDate: a.collectionDateSelect === 1 && !IsDateEmpty(a.collectionDate)? ParseDate(a.collectionDate) : null,
                    CollectionDateDmuid: a.collectionDateSelect === 1 ? GetDMUID(a.collectionDate) : null,
                    CollectionDateUnk: a.collectionDateSelect === 99,
                    AssessedResultTxt: a.assessSelect === 1 && a.assessTxt && !isNaN(Number(a.assessTxt)) ? Number(a.assessTxt) : "",
                    // NotAssessed: a.assessSelect === 2,
                    AssessedUnk: a.assessSelect === 99,
                    UpperLimitRangeTxt: a.upperLimitSelect === 1 && a.upperLimitTxt && !isNaN(Number(a.upperLimitTxt))  ? Number(a.upperLimitTxt) : "",
                    UpperLimitRangeUnk: a.upperLimitSelect === 99,
                    LowerLimitRangeTxt: a.lowerLimitSelect === 1 && a.lowerLimitTxt && !isNaN(Number(a.lowerLimitTxt))? Number(a.lowerLimitTxt) : "",
                    LowerLimitRangeUnk: a.lowerLimitSelect === 99,
                    Unit: a.unitSelect,
                    UnitOthTxt: a.unitSelect === a.unitTxtId ? a.unitTxt : "",
                    ChartAvailability: a.selected,
                }));
            }
            //COVID-19 Vaccine rpt
            if (ccDetails.q29_covid_vax === 1 && covidVaccineDetails && covidVaccineDetails.length > 0) {
                clinicalModel.CovidVaccineDetailsList = covidVaccineDetails.map((a) => ({
                    CovidVaccineDetailsId: a.covidVaccineDetailsid,
                    CovidVaccineReceivedDate: a.covid_vax === 1 && !IsDateEmpty(a.covid_vax_date) ? ParseDate(a.covid_vax_date) : null,
                    CovidVaccineReceivedDateDmuid: a.covid_vax === 1 ? GetDMUID(a.covid_vax_date) : null,
                    CovidVaccineReceivedDateUnk: a.covid_vax === 99,
                    CovidVaccineCompany: a.company,
                    CovidVaccineCompanyOthTxt: a.company_oth_txt,
                }));
            }
            //COVID-19 Covid Diagnosis rpt
            if (ccDetails.q33_covid_dx === 1 && covidDiagnosisDetails && covidDiagnosisDetails.length > 0) {
                clinicalModel.CovidDiagnosisDetailsList = covidDiagnosisDetails.map((a) => ({
                    CovidDiagnosisDetailsId: a.covidDiagnosisDetailsId,
                    CovidDiagnosisDate: a.covid_dx === 1 && !IsDateEmpty(a.covid_dx_date) ? ParseDate(a.covid_dx_date) : null,
                    CovidDiagnosisDateDmuid: a.covid_dx === 1 ? GetDMUID(a.covid_dx_date) : null,
                    CovidDiagnosisDateUnk: a.covid_dx === 99,
                }));
            }
            const response = await ClinicalService.SaveClinicalData(clinicalModel);


            if (response?.status !== HTTPResponse.OK) {
                if (response?.status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else
                    throw response.error;
            } else {
                await LoadData();//can take data from after saving
                ToggleLoader(false);
                ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            }
            return true;
        }
        else {
            ToggleLoader(false);
            setShowTopErrMsg(true)
            return false;
        }
        }
        catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
        }
    }; 

    //For int state check against null if 0 is valid since 0 is considered falsy.
    const Validate = () => {
        let result = true;
        //Q11
        if (!ccDetails.q11_infla_bowel_dis && !ccDetails.q11_diabetes && !ccDetails.q11_cerebro_dis && !ccDetails.q11_cardio_dis
            && !ccDetails.q11_cns && !ccDetails.q11_psychiatric && !ccDetails.q11_hiv && !ccDetails.q11_hepatic
            && !ccDetails.q11_obesity && !ccDetails.q11_rheuma_dis && !ccDetails.q11_peptic_ulcer && !ccDetails.q11_renal_dys
            && !ccDetails.q11_pulmo_dys && !ccDetails.q11_cancer && !ccDetails.q11_oth && !ccDetails.q11_none_unk) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q11Empty: true,
            }))
        }
        else {
            if (ccDetails.q11_cardio_dis && isStringEmpty(ccDetails.q11_cardio_dis_txt)) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    q11cardioTxtEmpty: true,
                }))
            }
            if (ccDetails.q11_cns && isStringEmpty(ccDetails.q11_cns_txt)) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    q11cnsTxtEmpty: true,
                }))
            }
            if (ccDetails.q11_oth && isStringEmpty(ccDetails.q11_oth_txt)) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    q11othTxtEmpty: true,
                }))
            }
        }
        // else if (ccDetails.q11_cardio_dis && isStringEmpty(ccDetails.q11_cardio_dis_txt) ||
        //     ccDetails.q11_cns && isStringEmpty(ccDetails.q11_cns_txt) ||
        //     ccDetails.q11_oth && isStringEmpty(ccDetails.q11_oth_txt)) {
        //     result = false;
        //     setHardErrList((prev) => ({
        //         ...prev,
        //         q11TxtEmpty: true,
        //     }))
        // }
     
        //Q12
        const diagnosisDateU = ParseDate(ccDetails.q12_diagnosis_date)
        if (ccDetails.q11_cancer) {
            //Main Q  
            if (!ccDetails.q12_diagnosis || (ccDetails.q12_diagnosis === 1)) {
                if (IsDateEmpty(diagnosisDateU)) {
                    result = false;
                    setHardErrList((prev) => ({
                        ...prev,
                        q12Empty: true,
                    }))
                }
                else if (!IsDateValid(diagnosisDateU)) {
                    result = false;
                }
                //q12DateValidation
                 else if(IsDateValid(diagnosisDateU) && !IsMonthUnk(ccDetails.q12_diagnosis_date) && !IsMonthUnk(index_dt_minus_12mo) && !IsMonthUnk(index_date) && !ValidateIndex(diagnosisDateU,index_dt_minus_12mo,index_date))
                 {            
                    result = false;
                    setHardErrList((prev) => ({
                        ...prev,
                        q12DateValidation: true,
                    }))
                 }
            }
            //Sub Q
            if (!ccDetails.q12_cancer_type) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    q12TypeEmpty: true,
                }))
            }
            else if (ccDetails.q12_cancer_type === 6 && isStringEmpty(ccDetails.q12_cancer_type_oth_txt)) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    q12TypeTxtEmpty: true,
                }))
            }
        }
        //Q13
        //0 is considered falsy
        if (ccDetails.q13_conf_dx_mal == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q13Empty: true,
            }))
        }
        else if (ccDetails.q13_conf_dx_mal === 1 && isStringEmpty(ccDetails.q13_conf_dx_mal_txt)) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q13TxtEmpty: true,
            }))
        }
        //Q14
        const assessmentDateQ14 = ParseDate(ccDetails.q14_assessment_date)
        
        if (IsDateEmpty(assessmentDateQ14)) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q14DateEmpty: true,
            }))
        }

        //14 Validation date
        else if(IsDateValid(assessmentDateQ14) && !IsMonthUnk(ccDetails.q14_assessment_date) && !IsMonthUnk(index_dt_minus_12mo) && !IsMonthUnk(index_date) && !ValidateIndex(assessmentDateQ14,index_dt_minus_12mo,index_date) )
        {
        result = false;
        setHardErrList((prev) => ({
            ...prev,
            q14Validation: true,
        }))
        }
        else {
            if (!IsDateValid(assessmentDateQ14) && !IsMonthUnk(ccDetails.q14_assessment_date)) {
                result = false;
            }
        }
        if (!ccDetails.q14_ecog_status) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q14StatusEmpty: true,
            }))
        }
        
        //Q15 
        if (ccDetails.q15_ecog_assess == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q15Empty: true,
            }))
        }

        //Q16 ECOG Assessment rpt
        if (ccDetails.q15_ecog_assess === 1 && ecogAssessmentsDetails && ecogAssessmentsDetails.length > 0) {
            const updatedArray = ecogAssessmentsDetails.map(item => {
                const assessmentDateQ16 = ParseDate(item.q16_assessment_date)
                const q16DateEmptyHard = IsDateEmpty(assessmentDateQ16);// || !IsDateValid(item.q16_assessment_date);
                const q16StatusEmptyHard = item.q16_ecog_status == null;
                // const q16DateValidation = !IsDateEmpty(item.q16_assessment_date) && IsDateValid(item.q16_assessment_date) && !ValidateDtBetweenIndexAndChart(item.q16_assessment_date,index_date,chartAbstractionDate);

                //ST: 24/10/2024 https://ideawharf.sifterapp.com/issues/12663
                const q16DatOnOrBeforeChartAbs = !IsDateEmpty(assessmentDateQ16) && IsDateValid(assessmentDateQ16) && 
                 !IsMonthUnk(item.q16_assessment_date) && !IsMonthUnk(chartAbstractionDate) &&  !IsValidDate3(assessmentDateQ16,chartAbstractionDate) ;

                const q16DateAfterIndex = !IsDateEmpty(assessmentDateQ16) && IsDateValid(assessmentDateQ16) && 
                !IsMonthUnk(item.q16_assessment_date) && !IsMonthUnk(index_date) &&
                !IsAfterIndex(assessmentDateQ16,index_date);
                
                
                if (q16DateEmptyHard || !IsDateValid(assessmentDateQ16) || q16StatusEmptyHard 
                // || q16DateValidation 
                || q16DatOnOrBeforeChartAbs || q16DateAfterIndex) {
                    result = false;
                }

                
                return {
                    ...item,
                    q16DateEmptyHard,
                    q16StatusUnkSoft: false,
                    q16StatusEmptyHard,
                    // q16DateValidation,
                    q16DatOnOrBeforeChartAbs,
                    q16DateAfterIndex
                };
            });
            setECOGAssessmentsDetails(updatedArray);
        }
        //Q18
        if (ccDetails.q18_hist_grade == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q18GradeEmpty: true,
            }))
        }
        if (ccDetails.q18_hist_grade != null && ccDetails.q18_hist_grade !== 99 && ccDetails.q18_lym_class == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q18LymClassEmpty: true,
            }))
        }
        //Q19
        if (ccDetails.q19_mol_class == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q19Empty: true,
            }))
        }
        //Q20
        if (ccDetails.q20_ann_arbor_stage == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q20StageEmpty: true,
            }))
        }
        if (ccDetails.q20_ann_arbor_stage != null && ccDetails.q20_ann_arbor_stage !== 99 && ccDetails.q20_sub_question == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q20SubEmpty: true,
            }))
        }
        //Q21
        if (ccDetails.q20_ann_arbor_stage === 99 && ccDetails.q21_stage_descr == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q21StageDescrEmpty: true,
            }))
        }
        if (ccDetails.q21_stage_descr != null && ccDetails.q21_sub_question == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q21SubEmpty: true,
            }))
        }
        //Q22, Q23
        if (ccDetails.q20_ann_arbor_stage === 4 || ccDetails.q21_stage_descr === 4) {
            //Q22
            if (ccDetails.q22_extranodal_site == null) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    q22Empty: true,
                }))
            }
            else if (ccDetails.q22_extranodal_site === 1) {
                if (isStringEmpty(ccDetails.q22_extranodal_site_txt)) {
                    result = false;
                    setHardErrList((prev) => ({
                        ...prev,
                        q22TxtEmpty: true,
                    }))
                } else if (!ValueInRange(parseFloat(ccDetails.q22_extranodal_site_txt), 0, 100)) {
                    result = false;
                    setHardErrList((prev) => ({
                        ...prev,
                        q22ExtranodalNodesRangeLimHard: true,
                    }))
                }
            }
            //Q23
            if (ccDetails.q23_nodal_site == null) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    q23Empty: true,
                }))
            }
            else if (ccDetails.q23_nodal_site === 1) {
                if (isStringEmpty(ccDetails.q23_nodal_site_txt)) {
                    result = false;
                    setHardErrList((prev) => ({
                        ...prev,
                        q23TxtEmpty: true,
                    }))
                } else if (!ValueInRange(parseFloat(ccDetails.q23_nodal_site_txt), 0, 100)) {
                    result = false;
                    setHardErrList((prev) => ({
                        ...prev,
                        q23NodalNodesRangeLimHard: true,
                    }))
                }
            }
        }
        //Q24
        const assessmentDateQ24 = ParseDate(ccDetails.q24_assessment_date)
        if (IsDateEmpty(assessmentDateQ24)) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q24DateEmpty: true,
            }))
        }

        else if(IsDateValid(assessmentDateQ24) && !IsMonthUnk(ccDetails.q24_assessment_date) && !IsMonthUnk(index_dt_minus_12mo) &&  !IsMonthUnk(index_date) && !ValidateIndex(assessmentDateQ24,index_dt_minus_12mo,index_date))
        {
        result = false;
        setHardErrList((prev) => ({
            ...prev,
            q24DateValidation: true,
        }))
        }
        else {
            if (!IsDateValid(assessmentDateQ24) && !IsMonthUnk(ccDetails.q24_assessment_date)) {
                result = false;
            }
        }
        if (!ccDetails.q24_size) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q24SizeEmpty: true,
            }))
        } else if (ccDetails.q24_size === 1) {
            if (isStringEmpty(ccDetails.q24_size_txt)) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    q24SizeTxtEmpty: true,
                }))
            } else if (!ValueInRange(parseFloat(ccDetails.q24_size_txt), 0, 100)) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    q24SizeRangeLimHard: true,
                }))
            }
        }
        //Q25
        if (ccDetails.q25_ipi_score == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q25Empty: true,
            }))
        }
        //Q26
        if (ccDetails.q26_cd20_status == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q26Empty: true,
            }))
        }
        //Q27
        if (!ccDetails.q27_fever && !ccDetails.q27_sweats && !ccDetails.q27_weight_loss && !ccDetails.q27_lack_energy
            && !ccDetails.q27_tired && !ccDetails.q27_body_pain && !ccDetails.q27_oth && !ccDetails.q27_none_unk) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q27Empty: true,
            }))
        }
        else if (ccDetails.q27_oth && isStringEmpty(ccDetails.q27_oth_txt)) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q27TxtEmpty: true,
            }))
        }
      
        //Q28 
        if (q28LabData && q28LabData.length > 0) {       
            const updatedArray = q28LabData.map(item => {
                let collectDtEmptyHard = false;
                const collectionDateQ28 = ParseDate(item.collectionDate)
                if (item.selected && item.collectionDateSelect == null || (item.collectionDateSelect === 1 && IsDateEmpty(item.collectionDate))) {
                    collectDtEmptyHard = true;
                }

                let collectionDateInvalid =false;
                // if (item.selected && item.collectionDateSelect !== null || (item.collectionDateSelect === 1 && !IsDateEmpty(item.collectionDate))  && !IsDateBetween(index_dt_minus_6mo,item.collectionDate,index_date)) {
                //     collectionDateInvalid = true;
                // }else{
                //     collectionDateInvalid = false;
                // }

                if (
                    item.selected && 
                    item.collectionDateSelect === 1 && !IsDateEmpty(collectionDateQ28) && 
                    !IsDateBetween(index_dt_minus_6mo, collectionDateQ28, index_date) && !IsMonthUnk(item.collectionDate)
                  ) {
                    collectionDateInvalid = true;
                  } else {
                    collectionDateInvalid = false;
                  }
                  

                const assessEmptyHard =item.selected && item.assessSelect == null;
                const assessTxtEmptyHard = assessEmptyHard ? false :item.selected && item.assessSelect === 1 && isStringEmpty(item.assessTxt);
                const assessRangeLimitHard =item.selected && isStringEmpty(item.assessTxt) ? false :item.selected && !ValueInRange(parseFloat(item.assessTxt), 0.00, 9999.99);

                const upperLimEmptyHard = item.selected &&  item.upperLimitSelect == null;
                const upperLimTxtEmptyHard = assessEmptyHard ? false : item.selected && item.upperLimitSelect === 1 && isStringEmpty(item.upperLimitTxt);
                const upperLimRangeLimitHard = isStringEmpty(item.upperLimitTxt) ? false :item.selected && !ValueInRange(parseFloat(item.upperLimitTxt), 0.00, 9999.99);

                const lowerLimEmptyHard = item.selected && item.lowerLimitSelect == null;
                const lowerLimTxtEmptyHard = assessEmptyHard ? false :item.selected && item.lowerLimitSelect === 1 && isStringEmpty(item.lowerLimitTxt);
                const lowerLimRangeLimitHard = isStringEmpty(item.lowerLimitTxt) ? false :item.selected && !ValueInRange(parseFloat(item.lowerLimitTxt), 0.00, 9999.99);

                const unitEmptyHard =item.selected && item.unitSelect == null;
                const unitTxtEmptyHard = unitEmptyHard ? false : item.selected && item.unitSelect === item.unitTxtId && isStringEmpty(item.unitTxt);


                if (collectDtEmptyHard || (item.collectionDateSelect == 1 && !IsDateValid(collectionDateQ28) && !IsMonthUnk(item.collectionDate)) || collectionDateInvalid ||
                    assessEmptyHard || assessTxtEmptyHard || assessRangeLimitHard ||
                    upperLimEmptyHard || upperLimTxtEmptyHard || upperLimRangeLimitHard ||
                    lowerLimEmptyHard || lowerLimTxtEmptyHard || lowerLimRangeLimitHard ||
                    unitEmptyHard || unitTxtEmptyHard) {
                    result = false;
                } 
                return {
                    ...item,
                    collectDtUnkSoft: false,
                    collectDtEmptyHard,
                    collectionDateInvalid,
                    assessUnkSoft: false,
                    assessEmptyHard,
                    assessTxtEmptyHard,
                    assessRangeLimitHard,
                    upperLimUnkSoft: false,
                    upperLimEmptyHard,
                    upperLimTxtEmptyHard,
                    upperLimRangeLimitHard,
                    lowerLimUnkSoft: false,
                    lowerLimEmptyHard,
                    lowerLimTxtEmptyHard,
                    lowerLimRangeLimitHard,
                    unitUnkSoft: false,
                    unitEmptyHard,
                    unitTxtEmptyHard,
                };
            });
            setQ28LabData(updatedArray);
        }

        //NOTA
        const allSelectedFalse = q28LabData.every(item => item.selected === false);
        if (allSelectedFalse && (ccDetails.labTableNota == false || ccDetails.labTableNota == "")) {
           result = false;
            setHardErrList((prev) => ({
                ...prev,
                labTableNotaEmpty: true,
            }))

        }

        //Q29 
        if (ccDetails.q29_covid_vax == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q29Empty: true,
            }))
        }
        //Q30,31 COVID-19 Vaccine rpt 
        if (ccDetails.q29_covid_vax === 1 && covidVaccineDetails && covidVaccineDetails.length > 0) {
            const updatedArray = covidVaccineDetails.map(item => {

                let covid_vax_dateQ30 = ParseDate(item.covid_vax_date)
                let covidVaxEmptyHard = false;
                if (item.covid_vax == null || (item.covid_vax === 1 && IsDateEmpty(item.covid_vax_date))) {
                    covidVaxEmptyHard = true;
                }

                let covidVaxAfterHard = false;
                if(item.covid_vax === 1 && !IsDateEmpty(item.covid_vax_date) && IsDateValid(item.covid_vax_date) && IsAfterIndex(item.covid_vax_date,chartAbstractionDate)){
                    covidVaxAfterHard = true;
                }

                const companyEmptyHard = item.company == null;
                const companyTxtEmptyHard = companyEmptyHard ? false : item.company === 3 && isStringEmpty(item.company_oth_txt);

                if (covidVaxEmptyHard || (item.covid_vax == 1 && !IsDateValid(item.covid_vax_date)) || companyEmptyHard || companyTxtEmptyHard || covidVaxAfterHard) {
                    result = false;
                }
                return {
                    ...item,
                    covidVaxDateUnkSoft: false,
                    covidVaxEmptyHard,
                    companyUnkSoft: false,
                    companyEmptyHard,
                    companyTxtEmptyHard,
                    covidVaxAfterHard
                };
            });
            setCovidVaccineDetails(updatedArray);
        }
        //Q33 
        if (ccDetails.q33_covid_dx == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                q33Empty: true,
            }))
        }
        //Q34 COVID-19 Covid Diagnosis rpt  
        if (ccDetails.q33_covid_dx === 1 && covidDiagnosisDetails && covidDiagnosisDetails.length > 0) {
            const updatedArray = covidDiagnosisDetails.map(item => {
                let covid_dx_dateQ34 = ParseDate(item.covid_dx_date);
                let covidDxEmptyHard = false;
                if (item.covid_dx == null || (item.covid_dx === 1 && IsDateEmpty(item.covid_dx_date))) {
                    covidDxEmptyHard = true;
                }
                if (covidDxEmptyHard || (item.covid_dx == 1 && !IsDateValid(covid_dx_dateQ34) && !IsMonthUnk(item.covid_dx_date))) {
                    result = false;
                }
                return {
                    ...item,
                    covidDxDateUnkSoft: false,
                    covidDxEmptyHard
                };
            });
            setCovidDiagnosisDetails(updatedArray);
        }

        return result;
    }
    useEffect(() => {
        async function FetchData() {
            if (!isNaN(patientId) && Number(patientId) !== 0) {

                ToggleLoader(true);
                await LoadData();
                ToggleLoader(false);
            }
        }
        FetchData()
    }, [])

    /* <summary>
    date: 06-06-2024
    Name: AP
    description: Load function
    <summary>*/
    async function LoadData() {
        try {
            const response = await ClinicalService.GetClinicalData(patientId);
            if (response?.status === HTTPResponse.OK) {
                const { data } = response;

                if (data.chartAbstractDate) setChartAbstractionDate(data.chartAbstractDate);
                if (!isStringEmpty(response.data.indexDate)) {
                    setIndexDate(response.data.indexDate);
                    const indexDtMinus6MoObj = SubtractMonths(response.data.indexDate, 6);
                    const indexDtMinus6Mo = DateDisplayFormat(indexDtMinus6MoObj);

                    const indexDtMinus12MoObj = SubtractMonths(response.data.indexDate, 12);
                    const indexDtMinus12Mo = DateDisplayFormat(indexDtMinus12MoObj);

                    setIndexDateMinus6Months(indexDtMinus6Mo);
                    setIndexDateMinus12Months(indexDtMinus12Mo)
                }
                if (data.clinicalDetails != null) {
                    const { clinicalDetails } = data; 
                    setCCDetails((prev) => ({
                        ...prev,
                        clinicalCharacteristicsId: clinicalDetails.clinicalCharacteristicsId != null
                            ? clinicalDetails.clinicalCharacteristicsId
                            : 0,
                        q11_infla_bowel_dis: clinicalDetails.comorbidInflammatoryBowel,
                        q11_diabetes: clinicalDetails.comorbidDiabetes,
                        q11_cerebro_dis: clinicalDetails.comorbidCerebrovascular,
                        q11_cardio_dis: clinicalDetails.comorbidCardiovascular,
                        q11_cardio_dis_txt: clinicalDetails.comorbidCardiovascularOthText,
                        q11_cns: clinicalDetails.comorbidPrimaryCentralNervous,
                        q11_cns_txt: clinicalDetails.comorbidPrimaryCentralNervousOthTxt,
                        q11_psychiatric: clinicalDetails.comorbidPsychiatric,
                        q11_hiv: clinicalDetails.comorbidHiv,
                        q11_hepatic: clinicalDetails.comorbidHepatic,
                        q11_obesity: clinicalDetails.comorbidObesity,
                        q11_rheuma_dis: clinicalDetails.comorbidRheumatologic,
                        q11_peptic_ulcer: clinicalDetails.comorbidPeptic,
                        q14Comorb_renal_failure: clinicalDetails.comorbidRenal,
                        q11_pulmo_dys: clinicalDetails.comorbidPulmonary,
                        q11_cancer: clinicalDetails.comorbidCancer,
                        q11_oth: clinicalDetails.comorbidOth,
                        q11_oth_txt: clinicalDetails.comorbidOthTxt,
                        q11_none_unk: clinicalDetails.comorbidNocondition ? 1 : clinicalDetails.comorbidUnk ? 99 : null,
                        // q12_diagnosis_date: !isStringEmpty(clinicalDetails.cancerDiagnosedDate) ?
                        //     clinicalDetails.cancerDiagnosedDate : DefaultDate,
                        q12_diagnosis_date: !isStringEmpty(clinicalDetails.cancerDiagnosedDate) ?
                        GetDisplayDate(clinicalDetails.cancerDiagnosedDate,clinicalDetails.cancerDiagnosedDateDmuid) : DefaultDate,
                        q12_diagnosis: clinicalDetails.cancerDiagnosedDateUnk ? 99 : clinicalDetails.cancerDiagnosedDate != null ? 1 : null,
                        q12_cancer_type: clinicalDetails.cancertype,
                        q12_cancer_type_oth_txt: clinicalDetails.cancertypeOthTxt,
                        q13_conf_dx_mal: clinicalDetails.diagnosisOtherMalignancies,
                        q13_conf_dx_mal_txt: clinicalDetails.diagnosisOtherMalignanciesTxt,
                        q14_assessment_date: !isStringEmpty(clinicalDetails.ecogAssessmentDate) ?
                        GetDisplayDate(clinicalDetails.ecogAssessmentDate,clinicalDetails.ecogAssessmentDateDmuid) : DefaultDate,
                        q14_ecog_status: clinicalDetails.ecogstatus,
                        q15_ecog_assess: clinicalDetails.ecogAssesmentAfterindexdate,
                        q18_hist_grade: clinicalDetails.histologicGrade,
                        q18_lym_class: clinicalDetails.lymphomaClassification,
                        q19_mol_class: clinicalDetails.molecularClassification,
                        q20_ann_arbor_stage: clinicalDetails.annArborDiseaseStage,
                        q20_sub_question: clinicalDetails.annArborDiseaseStageOptions,
                        q21_stage_descr: clinicalDetails.patientsDiseaseStage,
                        q21_sub_question: clinicalDetails.patientsDiseaseStageOptions,
                        q22_extranodal_site: clinicalDetails.moreExtranodalSite,
                        q22_extranodal_site_txt: clinicalDetails.moreExtranodalSiteTxt,
                        q23_nodal_site: clinicalDetails.moreNodalSite,
                        q23_nodal_site_txt: clinicalDetails.moreNodalSiteTxt,
                        q24_assessment_date: !isStringEmpty(clinicalDetails.lymphNodeAssessmentDate) ?
                        GetDisplayDate(clinicalDetails.lymphNodeAssessmentDate,clinicalDetails.lymphNodeAssessmentDateDmuid) : DefaultDate,
                        q24_size: clinicalDetails.lymphNodeResultUnk ? 99 : !isStringEmpty(clinicalDetails.lymphNodeResultTxt) ? 1 : null,
                        q24_size_txt: clinicalDetails.lymphNodeResultTxt??"",
                        q25_ipi_score: clinicalDetails.intPrognosticIndexRiskscore,
                        q26_cd20_status: clinicalDetails.cd20Status,
                        q27_fever: clinicalDetails.bSymptomFever,
                        q27_sweats: clinicalDetails.bSymptomDrenching,
                        q27_weight_loss: clinicalDetails.bSymptomWeightloss,
                        q27_lack_energy: clinicalDetails.bSymptomEnergyloss,
                        q27_tired: clinicalDetails.bSymptomTired,
                        q27_body_pain: clinicalDetails.bSymptomBodypain,
                        q27_oth: clinicalDetails.bSymptomOth,
                        q27_oth_txt: clinicalDetails.bSymptomOthTxt,
                        q27_none_unk: clinicalDetails.bNosymtoms ? 1 : clinicalDetails.bSymptomUnk ? 99 : null,
                        q29_covid_vax: clinicalDetails.receiveCovidVaccine,
                        q33_covid_dx: clinicalDetails.recordedCovidDiagnosis,
                        labTableNota: clinicalDetails.labTableNota                       
                    }))
                }
                //ECOG Assessment rpt
                if (
                    data.ecogScoreDetailsList != null &&
                    data.ecogScoreDetailsList.length > 0
                ) {
                    const { ecogScoreDetailsList } = data;
                    const ecogDtailsArray = ecogScoreDetailsList.map(item => {
                        const ecogScoreDetailsId = item.ecogscoredetailsid != null ? item.ecogscoredetailsid : 0;
                        const q16_assessment_date = !isStringEmpty(item.ecogscoreAssessmentDate) ? GetDisplayDate(item.ecogscoreAssessmentDate,item.ecogscoreAssessmentDateDmuid) : DefaultDate;
                        const q16_ecog_status = item.ecogscore
                        return {
                            ecogScoreDetailsId,
                            q16_assessment_date,
                            q16_ecog_status,

                            q16StatusUnkSoft: false,
                            q16DateEmptyHard: false,
                            q16StatusEmptyHard: false,
                            // q16DateValidation: false,
                            q16DatOnOrBeforeChartAbs: false,
                            q16DateAfterIndex: false

                        };
                    });
                    setECOGAssessmentsDetails(ecogDtailsArray);
                    // let i = 0;
                    // let dataarray = [];
                    // for (i; i < ecogScoreDetailsList.length; i++) {
                    //     let datacomponent = {
                    //         ...ECOGAssesmentRow,
                    //         ecogScoreDetailsId:
                    //             ecogScoreDetailsList[i].ecogscoredetailsid != null
                    //                 ? ecogScoreDetailsList[i].ecogscoredetailsid
                    //                 : 0,
                    //         q16_assessment_date: !isStringEmpty(ecogScoreDetailsList[i].ecogscoreAssessmentDate) ?
                    //             ecogScoreDetailsList[i].ecogscoreAssessmentDate : DefaultDate,
                    //         q16_ecog_status: ecogScoreDetailsList[i].ecogscore
                    //     };
                    //     dataarray.push(datacomponent);
                    // }
                    // setECOGAssessmentsDetails(dataarray);
                }
                //Q28 
                if (
                    data.labResultDetailsList != null &&
                    data.labResultDetailsList.length > 0
                ) {
                    const { labResultDetailsList } = data;
                    let q28LabDataClone = [...q28LabData];
                    // const labDataArray = labResultDetailsList.map(item => {

                    //     let a = q28LabData.filter(a => a.labTestTypeId === item.labtesttypeid)
                    //     a.labResultsId = item.labresultsid != null ? item.labresultsid : 0;
                
                    const labDataArray = q28LabDataClone.map(s => {
                        const dbItem = labResultDetailsList.find(d => d.labtesttypeid === s.labTestTypeId);              
                        if (dbItem) {
                            return {
                                ...s,
                                labResultsId: dbItem.labresultsid,
                                collectionDate: !isStringEmpty(dbItem.collectionDate) ?
                                    GetDisplayDate(dbItem.collectionDate, dbItem.collectionDateDmuid) : DefaultDate,
                                collectionDateSelect: dbItem.collectionDateUnk ? 99 : !isStringEmpty(dbItem.collectionDate) ? 1 : null,
                                assessSelect: !isStringEmpty(dbItem.assessedResultTxt) ? 1 : dbItem.assessedUnk ? 99 : null,//dbItem.notAssessed ? 2 : 
                                assessTxt: dbItem.assessedResultTxt || "",
                
                                upperLimitSelect: dbItem.upperLimitRangeUnk ? 99 : !isStringEmpty(dbItem.upperLimitRangeTxt) ? 1 : null,
                                upperLimitTxt: dbItem.upperLimitRangeTxt || "",
                                //LowerLimitRangeUnk LowerLimitRangeTxt
                                lowerLimitSelect: dbItem.lowerLimitRangeUnk ? 99 : !isStringEmpty(dbItem.lowerLimitRangeTxt) ? 1 : null,
                                lowerLimitTxt: dbItem.lowerLimitRangeTxt || "",
                
                                unitSelect: dbItem.unit,
                                unitTxt: dbItem.unitOthTxt || "",
                                //unitTxtId: !isStringEmpty(dbItem.unitOthTxt) ? dbItem.unit : null,
                                selected:dbItem.chartAvailability,
                                collectDtUnkSoft: false,
                                assessUnkSoft: false,
                                upperLimUnkSoft: false,
                                lowerLimUnkSoft: false,
                                unitUnkSoft: false,
                
                                collectDtEmptyHard: false,
                                collectionDateInvalid: false,
                                assessEmptyHard: false,
                                assessTxtEmptyHard: false,
                                assessRangeLimitHard: false,
                                upperLimEmptyHard: false,
                                upperLimTxtEmptyHard: false,
                                upperLimRangeLimitHard: false,
                                lowerLimEmptyHard: false,
                                lowerLimTxtEmptyHard: false,
                                lowerLimRangeLimitHard: false,
                                unitEmptyHard: false,
                                unitTxtEmptyHard: false,
                            };
                        }
                        // If no matching dbItem is found, return the original item from q28LabData
                        return s;
                    });
                    //});
                    setQ28LabData(labDataArray);
                } 
                //COVID-19 Vaccine rpt 
                if (
                    data.covidVaccineDetailsList != null &&
                    data.covidVaccineDetailsList.length > 0
                ) {
                    const { covidVaccineDetailsList } = data;
                    const covidVaxDetailsArray = covidVaccineDetailsList.map(item => {
                        const covidVaccineDetailsid = item.covidVaccineDetailsId != null ? item.covidVaccineDetailsid : 0;
                        const covid_vax_date = !isStringEmpty(item.covidVaccineReceivedDate) ?
                            GetDisplayDate(item.covidVaccineReceivedDate, item.covidVaccineReceivedDateDmuid) : "MM/UNK/YYYY";
                        return {
                            ...CovidVaccineRow,
                            covidVaccineDetailsid,
                            covid_vax: item.covidVaccineReceivedDateUnk ? 99 : !isStringEmpty(item.covidVaccineReceivedDate) ? 1 : null,
                            covid_vax_date,
                            company: item.covidVaccineCompany,
                            company_oth_txt: item.covidVaccineCompanyOthTxt,
                        };
                    });
                    setCovidVaccineDetails(covidVaxDetailsArray);
                }
                //COVID-19 Diagnosis rpt 
                if (
                    data.covidDiagnosisDetailsList != null &&
                    data.covidDiagnosisDetailsList.length > 0
                ) {
                    const { covidDiagnosisDetailsList } = data;
                    const covidDxDetailsArray = covidDiagnosisDetailsList.map(item => {
                        const covidDiagnosisDetailsId = item.covidDiagnosisDetailsId != null ? item.covidDiagnosisDetailsId : 0;
                        const covid_dx_date = !isStringEmpty(item.covidDiagnosisDate) ?
                            GetDisplayDate(item.covidDiagnosisDate, item.covidDiagnosisDateDmuid) : DefaultDate;
                        return {
                            ...CovidDiagnosisRow,
                            covidDiagnosisDetailsId,
                            covid_dx: item.covidDiagnosisDateUnk ? 99 : !isStringEmpty(item.covidDiagnosisDate) ? 1 : null,
                            covid_dx_date
                        };
                    });
                    setCovidDiagnosisDetails(covidDxDetailsArray);
                }

            } else {
                if (response?.status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else
                    throw response.error;
            }
        } catch (er) {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    }
    //Repeater
    const HandleRepeaterDateChange = (name, date, datePart) => {
        const lastIndex = name.lastIndexOf("-");
        const id = name.slice(lastIndex + 1);
        switch (name) {
            case "q16_assessment_date-" + id: {
                let updatedArray = [...ecogAssessmentsDetails];
                const idx = name.slice(lastIndex + 1);
                updatedArray[id].q16_assessment_date = SetDateState(updatedArray[id].q16_assessment_date, date, datePart);

                updatedArray[id].q16DateEmptyHard = false;
                // updatedArray[id].q16DateValidation = false;
                updatedArray[id].q16DateAfterIndex = false;
                updatedArray[id].q16DatOnOrBeforeChartAbs = false;

                setECOGAssessmentsDetails(updatedArray);
            }
                break;
            case "covid_vax_date-" + id: {
                let updatedArray = [...covidVaccineDetails];
                const idx = name.slice(lastIndex + 1);
                updatedArray[id].covid_vax_date = SetDateState(updatedArray[id].covid_vax_date, date, datePart);
                updatedArray[id].covid_vax = 1;

                updatedArray[id].covidVaxEmptyHard = false;
                updatedArray[id].covidVaxAfterHard = false;
                updatedArray[id].covidVaxDateUnkSoft = false;
                setCovidVaccineDetails(updatedArray);
            }
                break;
            case "covid_dx_date-" + id: {
                let updatedArray = [...covidDiagnosisDetails];
                const idx = name.slice(lastIndex + 1);
                updatedArray[id].covid_dx_date = SetDateState(updatedArray[id].covid_dx_date, date, datePart);
                updatedArray[id].covid_dx = 1;

                updatedArray[id].covidDxEmptyHard = false;
                updatedArray[id].covidDxDateUnkSoft = false;
                setCovidDiagnosisDetails(updatedArray);
            }
                break;
            default: break;
        }
    };
    const HandleRepeaterFieldChange = (e) => {
        const { name, id, value } = e.target;
        const value_int = Number(value);

        switch (name) {
            case "q16_ecog_status" + id: {
                let updatedArray = [...ecogAssessmentsDetails];
                updatedArray[id].q16_ecog_status = value_int;

                updatedArray[id].q16StatusUnkSoft = value_int === 99;
                updatedArray[id].q16StatusEmptyHard = false;
                setECOGAssessmentsDetails(updatedArray);
                break;
            }
            case "covid_vax" + id: {
                let updatedArray = [...covidVaccineDetails];
                updatedArray[id].covid_vax = value_int;
                updatedArray[id].covid_vax_date = "MM/UNK/YYYY";

                updatedArray[id].covidVaxDateUnkSoft = value_int === 99;
                updatedArray[id].covidVaxEmptyHard = false;
                updatedArray[id].covidVaxAfterHard = false;
                setCovidVaccineDetails(updatedArray);
                break;
            }
            case "company" + id: {
                let updatedArray = [...covidVaccineDetails];
                updatedArray[id].company = value_int;
                updatedArray[id].company_oth_txt = "";

                updatedArray[id].companyUnkSoft = value_int === 99;
                updatedArray[id].companyEmptyHard = false;
                updatedArray[id].companyTxtEmptyHard = false;
                setCovidVaccineDetails(updatedArray);
                break;
            }
            case "company_oth_txt": {
                let updatedArray = [...covidVaccineDetails];
                updatedArray[id].company = 3;
                updatedArray[id].company_oth_txt = value;

                updatedArray[id].companyUnkSoft = value_int === 99;
                updatedArray[id].companyEmptyHard = false;
                updatedArray[id].companyTxtEmptyHard = false;
                setCovidVaccineDetails(updatedArray);
                break;
            }
            case "covid_dx" + id: {
                let updatedArray = [...covidDiagnosisDetails];
                updatedArray[id].covid_dx = value_int;
                updatedArray[id].covid_dx_date = DefaultDate;

                updatedArray[id].covidDxDateUnkSoft = value_int === 99;
                updatedArray[id].covidDxEmptyHard = false;
                setCovidDiagnosisDetails(updatedArray);
                break;
            }
            default: break;
        }
    }
    //Q16 ECOG Assessment Repeater
    const DeleteECOGAssessmentRow = (id) => {
        const newArray = [...ecogAssessmentsDetails];
        newArray.splice(id, 1)
        setECOGAssessmentsDetails(newArray);
    }
    const AddECOGAssessmentRow = () => {
        setECOGAssessmentsDetails((prev) => [
            ...prev,
            {
                ...ECOGAssesmentRow
            },
        ]);
    }
    //Q30,31 COVID Vaccine Repeater
    const DeleteCovidVaccineRow = (id) => {
        const newArray = [...covidVaccineDetails];
        newArray.splice(id, 1)
        setCovidVaccineDetails(newArray);
    }
    const AddCovidVaccineRow = () => {
        setCovidVaccineDetails((prev) => [
            ...prev,
            {
                ...CovidVaccineRow
            },
        ]);
    }
    //Q34 COVID Vaccine Repeater
    const DeleteCovidDiagnosisRow = (id) => {
        const newArray = [...covidDiagnosisDetails];
        newArray.splice(id, 1)
        setCovidDiagnosisDetails(newArray);
    }
    const AddCovidDiagnosisRow = () => {
        setCovidDiagnosisDetails((prev) => [
            ...prev,
            {
                ...CovidDiagnosisRow
            },
        ]);
    }

    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                    </div>
                </div>
            </section>

            <section className="main-survey-section">
                <div className="row">
                    <SideBarMenu activePageId={SideMenuItems.ClinicalCharacteristics} />
                    <div className="col-md-9 col-pad-left">
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>CLINICAL CHARACTERISTICS</h4>
                            </div>
                            {showTopErrMsg && <div className="clean-error ">
                                <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
                            </div>
                            }
                            <div className="survey-question-section">
                                <div className="survey-question-content">
                                    <div style={PageDesignConstants.PageDescriptionStyle}>
                                        In this section, you will be asked to answer questions
                                        related to the patient’s clinical characteristics.
                                    </div>
                                    <div className="question-answer">
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q11.</span>
                                                <span className="quest-text-pad">
                                                    Did the patient have any of the following comorbid
                                                    conditions in the 12 months prior to{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    , the date of initiation of treatment with
                                                    epcoritamab? <i>(select all that apply)</i>
                                                </span>
                                            </div>
                                            <div className="double-dig-answer">
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_infla_bowel_dis"
                                                            name="q11_infla_bowel_dis"
                                                            checked={ccDetails.q11_infla_bowel_dis}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Inflammatory bowel disease
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_diabetes"
                                                            name="q11_diabetes"
                                                            checked={ccDetails.q11_diabetes}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Diabetes{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_cerebro_dis"
                                                            name="q11_cerebro_dis"
                                                            checked={ccDetails.q11_cerebro_dis}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Cerebrovascular disease{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_cardio_dis"
                                                            name="q11_cardio_dis"
                                                            checked={ccDetails.q11_cardio_dis}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Cardiovascular disease, specify:{" "}
                                                            <input
                                                                name="q11_cardio_dis_txt"
                                                                className="input-dash"
                                                                type="text"
                                                                value={ccDetails.q11_cardio_dis_txt}
                                                                onChange={HandleQ11FieldChange}
                                                                maxLength={100}
                                                            />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label className="">
                                                        <input
                                                            type="checkbox"
                                                            id="q11_cns"
                                                            name="q11_cns"
                                                            checked={ccDetails.q11_cns}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Primary central nervous system (CNS) lymphoma or
                                                            lymphoma with CNS involvement, specify:{" "}
                                                            <input
                                                                name="q11_cns_txt"
                                                                className="input-dash"
                                                                type="text"
                                                                value={ccDetails.q11_cns_txt}
                                                                onChange={HandleQ11FieldChange}
                                                                maxLength={100}
                                                            />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_psychiatric"
                                                            name="q11_psychiatric"
                                                            checked={ccDetails.q11_psychiatric}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Psychiatric disturbance{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_hiv"
                                                            name="q11_hiv"
                                                            checked={ccDetails.q11_hiv}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Human Immunodeficiency Virus (HIV) infection{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_hepatic"
                                                            name="q11_hepatic"
                                                            checked={ccDetails.q11_hepatic}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Hepatic dysfunction{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_obesity"
                                                            name="q11_obesity"
                                                            checked={ccDetails.q11_obesity}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Obesity (BMI ≥35 kg/m<sup>2</sup>){" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_rheuma_dis"
                                                            name="q11_rheuma_dis"
                                                            checked={ccDetails.q11_rheuma_dis}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Rheumatologic disease
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_peptic_ulcer"
                                                            name="q11_peptic_ulcer"
                                                            checked={ccDetails.q11_peptic_ulcer}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Peptic ulcer
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_renal_dys"
                                                            name="q11_renal_dys"
                                                            checked={ccDetails.q11_renal_dys}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Renal dysfunction
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_pulmo_dys"
                                                            name="q11_pulmo_dys"
                                                            checked={ccDetails.q11_pulmo_dys}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Pulmonary dysfunction
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_cancer"
                                                            name="q11_cancer"
                                                            checked={ccDetails.q11_cancer}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">Cancer</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q11_oth"
                                                            name="q11_oth"
                                                            checked={ccDetails.q11_oth}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Other, specify:{" "}
                                                            <input
                                                                name="q11_oth_txt"
                                                                className="input-dash"
                                                                type="text"
                                                                value={ccDetails.q11_oth_txt}
                                                                onChange={HandleQ11FieldChange}
                                                                maxLength={100}
                                                            />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q11_none_unk_1"
                                                            name="q11_none_unk"
                                                            value={1}
                                                            checked={ccDetails.q11_none_unk === 1}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            The patient did not have any comorbid conditions
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q11_none_unk_99"
                                                            name="q11_none_unk"
                                                            value={99}
                                                            checked={ccDetails.q11_none_unk === 99}
                                                            onChange={HandleQ11FieldChange}
                                                        />
                                                        <span className="radio-text-padding">Unknown</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={softErrList.q11Unk} message={CommonError_Msgs.UnknownSelect} />
                                            <ErrorField show={hardErrList.q11Empty} message={ClinicalCharacteristics_Msgs.q11Empty} />
                                            <ErrorField show={hardErrList.q11cardioTxtEmpty || hardErrList.q11cnsTxtEmpty || hardErrList.q11othTxtEmpty} message={ClinicalCharacteristics_Msgs.q11TxtEmpty} />
                                        </div>
                                        {ccDetails.q11_cancer && (
                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>Q12.</span>
                                                    <span className="quest-text-pad">
                                                        When was the patient diagnosed with cancer?
                                                    </span>
                                                </div>
                                                <div className="double-dig-answer">
                                                    <div className="">
                                                        <b>Date of diagnosis: </b>
                                                        <br />
                                                        <div className="date-field-padding">
                                                            <input
                                                                type="radio"
                                                                id="q12_diagnosis_1"
                                                                name="q12_diagnosis"
                                                                value={1}
                                                                checked={ccDetails.q12_diagnosis === 1}
                                                                onChange={HandleQ12FieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                <DateControl
                                                                    ctrlId={"q12_diagnosis_date"}
                                                                    HandleDateChange={HandleDateChange}
                                                                    date={ccDetails.q12_diagnosis_date}
                                                                   //  hideUnk={true}
                                                                     showUnkInfoTxt={true}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q12_diagnosis_99"
                                                                name="q12_diagnosis"
                                                                value={99}
                                                                checked={ccDetails.q12_diagnosis === 99}
                                                                onChange={HandleQ12FieldChange}
                                                            />
                                                            <span className="radio-text-padding">Unknown</span>
                                                        </label>
                                                    </div>
                                                    <ErrorField show={softErrList.q12DateUnk} message={CommonError_Msgs.UnknownSelect} style={{ paddingLeft: '0px' }} />
                                                    <ErrorField show={hardErrList.q12Empty} message={ClinicalCharacteristics_Msgs.q12Empty} style={{ paddingLeft: '0px' }} />
                                                    <ErrorField show={hardErrList.q12DateValidation} message={ClinicalCharacteristics_Msgs.q12DateValid.replace("{ID minus Index}", index_dt_minus_12mo)
                                                        .replace("{index date}", index_date)}
                                                        style={{ paddingLeft: '0px' }} />

                                                    <br />

                                                    <b>Cancer type </b>
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q12_cancer_type_1"
                                                                name="q12_cancer_type"
                                                                value={1}
                                                                checked={ccDetails.q12_cancer_type === 1}
                                                                onChange={HandleQ12FieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Cervical carcinoma of Stage 1B or less{" "}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q12_cancer_type_2"
                                                                name="q12_cancer_type"
                                                                value={2}
                                                                checked={ccDetails.q12_cancer_type === 2}
                                                                onChange={HandleQ12FieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Non-invasive basal cell or squamous cell skin
                                                                carcinoma{" "}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q12_cancer_type_3"
                                                                name="q12_cancer_type"
                                                                value={3}
                                                                checked={ccDetails.q12_cancer_type === 3}
                                                                onChange={HandleQ12FieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Non-invasive, superficial bladder cancer{" "}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q12_cancer_type_4"
                                                                name="q12_cancer_type"
                                                                value={4}
                                                                checked={ccDetails.q12_cancer_type === 4}
                                                                onChange={HandleQ12FieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Prostate cancer with a current prostate-specific antigen (PSA) level {"<0.1"}{" "}
                                                                ng/mL{" "}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q12_cancer_type_5"
                                                                name="q12_cancer_type"
                                                                value={5}
                                                                checked={ccDetails.q12_cancer_type === 5}
                                                                onChange={HandleQ12FieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Any curable cancer with a complete response (CR)
                                                                of {">2"} years duration{" "}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q12_cancer_type_6"
                                                                name="q12_cancer_type"
                                                                value={6}
                                                                checked={ccDetails.q12_cancer_type === 6}
                                                                onChange={HandleQ12FieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Other, specify: {""}
                                                                <input
                                                                    name="q12_cancer_type_oth_txt"
                                                                    className="input-dash"
                                                                    type="text"
                                                                    value={ccDetails.q12_cancer_type_oth_txt}
                                                                    onChange={HandleQ12FieldChange}
                                                                    maxLength={100}
                                                                />
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q12_cancer_type_99"
                                                                name="q12_cancer_type"
                                                                value={99}
                                                                checked={ccDetails.q12_cancer_type === 99}
                                                                onChange={HandleQ12FieldChange}
                                                            />
                                                            <span className="radio-text-padding">Unknown</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorField show={softErrList.q12TypeUnk} message={CommonError_Msgs.UnknownSelect} />
                                                <ErrorField show={hardErrList.q12TypeEmpty} message={ClinicalCharacteristics_Msgs.q12TypeEmpty} />
                                                <ErrorField show={hardErrList.q12TypeTxtEmpty} message={ClinicalCharacteristics_Msgs.q12TypeTxtEmpty} />
                                            </div>
                                        )}
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q13.</span>
                                                <span className="quest-text-pad">
                                                    At, or preceding, epcoritamab initiation on{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    , did the patient have a confirmed diagnosis of any
                                                    other malignancies?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q13_conf_dx_mal_1"
                                                            name="q13_conf_dx_mal"
                                                            value={1}
                                                            checked={ccDetails.q13_conf_dx_mal === 1}
                                                            onChange={HandleQ13FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Yes, specify: {""}
                                                            <input
                                                                type="text"
                                                                name="q13_conf_dx_mal_txt"
                                                                className="input-dash"
                                                                value={ccDetails.q13_conf_dx_mal_txt}
                                                                onChange={HandleQ13FieldChange}
                                                                maxLength={100}
                                                            />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q13_conf_dx_mal_0"
                                                            name="q13_conf_dx_mal"
                                                            value={0}
                                                            checked={ccDetails.q13_conf_dx_mal === 0}
                                                            onChange={HandleQ13FieldChange}
                                                        />
                                                        <span className="radio-text-padding">No</span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q13_conf_dx_mal_99"
                                                            name="q13_conf_dx_mal"
                                                            value={99}
                                                            checked={ccDetails.q13_conf_dx_mal === 99}
                                                            onChange={HandleQ13FieldChange}
                                                        />
                                                        <span className="radio-text-padding">Unknown</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={hardErrList.q13Empty} message={ClinicalCharacteristics_Msgs.q13Empty} />
                                            <ErrorField show={hardErrList.q13TxtEmpty} message={ClinicalCharacteristics_Msgs.q13TxtEmpty} />
                                            <ErrorField show={softErrList.q13Unk} message={CommonError_Msgs.UnknownSelect} />
                                        </div>
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q14.</span>
                                                <span className="quest-text-pad">
                                                    What was the patient’s Eastern Cooperative Oncology
                                                    Group (ECOG) status closest to (within 12 months of),
                                                    or on {" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    , the date of initiation of treatment with
                                                    epcoritamab?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <b>Date of assessment: </b>
                                                    <br />
                                                    <div className="date-field-padding">
                                                        <span className=" ">
                                                            <DateControl
                                                                ctrlId={"q14_assessment_date"}
                                                                HandleDateChange={HandleDateChange}
                                                                date={ccDetails.q14_assessment_date}
                                                                //hideUnk={true}
                                                                removeErrMsgPadding={true}
                                                                showUnkInfoTxt ={true}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                                <ErrorField show={hardErrList.q14DateEmpty} message={ClinicalCharacteristics_Msgs.q14DateEmpty} style={{ paddingLeft: '0px' }} />
                                                <ErrorField show={hardErrList.q14Validation} message={ClinicalCharacteristics_Msgs.q14Validation.replace("{ID minus Index}", index_dt_minus_12mo)
                                                        .replace("{index date}", index_date)} style={{ paddingLeft: '0px' }} />

                                                <br />
                                                <b>ECOG status </b>
                                                <div className="">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q14_ecog_status_1"
                                                            name="q14_ecog_status"
                                                            value={1}
                                                            checked={ccDetails.q14_ecog_status === 1}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">0</span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q14_ecog_status_2"
                                                            name="q14_ecog_status"
                                                            value={2}
                                                            checked={ccDetails.q14_ecog_status === 2}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">1</span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q14_ecog_status_3"
                                                            name="q14_ecog_status"
                                                            value={3}
                                                            checked={ccDetails.q14_ecog_status === 3}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">2</span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q14_ecog_status_4"
                                                            name="q14_ecog_status"
                                                            value={4}
                                                            checked={ccDetails.q14_ecog_status === 4}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">3</span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q14_ecog_status_5"
                                                            name="q14_ecog_status"
                                                            value={5}
                                                            checked={ccDetails.q14_ecog_status === 5}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">4</span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q14_ecog_status_6"
                                                            name="q14_ecog_status"
                                                            value={6}
                                                            checked={ccDetails.q14_ecog_status === 6}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Not assessed{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q14_ecog_status_99"
                                                            name="q14_ecog_status"
                                                            value={99}
                                                            checked={ccDetails.q14_ecog_status === 99}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">Unknown</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={softErrList.q14Unk} message={CommonError_Msgs.UnknownSelect} />
                                            <ErrorField show={hardErrList.q14StatusEmpty} message={ClinicalCharacteristics_Msgs.q14StatusEmpty} />
                                        </div>
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q15.</span>
                                                <span className="quest-text-pad">
                                                    Does the patient have any ECOG assessments <b><u>after</u></b> the initiation of epcoritamab on{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    {""} ?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q15_ecog_assess_1"
                                                            name="q15_ecog_assess"
                                                            value={1}
                                                            checked={ccDetails.q15_ecog_assess === 1}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">Yes</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q15_ecog_assess_0"
                                                            name="q15_ecog_assess"
                                                            value={0}
                                                            checked={ccDetails.q15_ecog_assess === 0}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">No</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={hardErrList.q15Empty} message={ClinicalCharacteristics_Msgs.q15Empty} />
                                        </div>
                                        {/* 16-17 Set1 start */}
                                        {ccDetails.q15_ecog_assess === 1 &&
                                            <div className="dependent-section">
                                                {
                                                    ecogAssessmentsDetails && ecogAssessmentsDetails.length > 0 && (
                                                        ecogAssessmentsDetails.map((data, idx) => (
                                                            <div className="loop-section-ul">
                                                                <div className="loop-head">
                                                                    <span className="question-weight">{GetOrdinal(idx + 1)} ECOG Assessment</span>
                                                                    {idx != 0 && <div className="delete-btn-div">
                                                                        <img
                                                                            width="16px"
                                                                            src="../Assets/images/Icon-material-delete.png"
                                                                            alt=""
                                                                            onClick={() => DeleteECOGAssessmentRow(idx)}
                                                                        />
                                                                    </div>}
                                                                </div>
                                                                <div className="sub-question-bot-sapce answer-pad-left">
                                                                    <div className="question question-weight">
                                                                        <span>Q16.</span>
                                                                        <span className="quest-text-pad">
                                                                            What was the patient’s {GetOrdinalInWord(idx + 1)} ECOG score after{" "}
                                                                            <span
                                                                                style={{ color: PageDesignConstants.fontColor }}
                                                                            >
                                                                                {index_date}
                                                                            </span>
                                                                            , the date of initiation of treatment with
                                                                            epcoritamab?
                                                                        </span>
                                                                    </div>
                                                                    <div className=" double-dig-answer">
                                                                        <div className="">
                                                                            <b>Date of assessment: </b>
                                                                            <br />
                                                                            <div className="date-field-padding">
                                                                                <span className=" ">
                                                                                    <DateControl
                                                                                        ctrlId={"q16_assessment_date-" + idx}
                                                                                        HandleDateChange={HandleRepeaterDateChange}
                                                                                        date={data.q16_assessment_date}
                                                                                       // hideUnk={true}
                                                                                        removeErrMsgPadding={true}
                                                                                        showUnkInfoTxt ={true}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorField show={data.q16DateEmptyHard} message={ClinicalCharacteristics_Msgs.q16DateEmpty.replace('{id}', GetOrdinalInWord(idx + 1))} style={{ paddingLeft: '0px' }} />
                                                                        <ErrorField show={data.q16DateAfterIndex} message={ClinicalCharacteristics_Msgs.q16DateAfterIndex} style={{ paddingLeft: '0px' }}/>
                                                                        <ErrorField show={data.q16DatOnOrBeforeChartAbs} message={ClinicalCharacteristics_Msgs.q16DatOnOrBeforeChartAbs} style={{ paddingLeft: '0px' }}/>

                                                                        {/* <ErrorField show={data.q16DateValidation} message={ClinicalCharacteristics_Msgs.q16Validation.replace("{ID minus Index}", index_date)
                                                                         .replace("{index date}", chartAbstractionDate)} style={{ paddingLeft: '0px' }} /> */}

                                                                       
                                                                        <br />
                                                                        
                                                                        <b>ECOG Status</b>
                                                                        <div className="">
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={idx}
                                                                                    name={"q16_ecog_status" + idx}
                                                                                    value={1}
                                                                                    checked={data.q16_ecog_status === 1}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding">0</span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="">
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={idx}
                                                                                    name={"q16_ecog_status" + idx}
                                                                                    value={2}
                                                                                    checked={data.q16_ecog_status === 2}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding">1</span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="">
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={idx}
                                                                                    name={"q16_ecog_status" + idx}
                                                                                    value={3}
                                                                                    checked={data.q16_ecog_status === 3}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding">2</span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="">
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={idx}
                                                                                    name={"q16_ecog_status" + idx}
                                                                                    value={4}
                                                                                    checked={data.q16_ecog_status === 4}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding">3</span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="">
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={idx}
                                                                                    name={"q16_ecog_status" + idx}
                                                                                    value={5}
                                                                                    checked={data.q16_ecog_status === 5}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding">4</span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="">
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={idx}
                                                                                    name={"q16_ecog_status" + idx}
                                                                                    value={99}
                                                                                    checked={data.q16_ecog_status === 99}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding">Unknown</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <ErrorField show={data.q16StatusUnkSoft} message={CommonError_Msgs.UnknownSelect} />
                                                                    <ErrorField show={data.q16StatusEmptyHard} message={ClinicalCharacteristics_Msgs.q16StatusEmpty} />
                                                                </div>
                                                            </div>))
                                                    )}
                                                {ecogAssessmentsDetails && ecogAssessmentsDetails.length < 8 &&
                                                    <div className="sub-question-bot-sapce-ul mt-2">
                                                        <div className="answer-list-text" >
                                                            <a onClick={AddECOGAssessmentRow}>
                                                                <img width="18px" src="../Assets/images/plus.png" alt="" />
                                                            </a>
                                                            <a onClick={AddECOGAssessmentRow} className="radio-text-padding">
                                                                Click here to add another ECOG assessment after {index_date}.
                                                            </a>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q18.</span>
                                                <span className="quest-text-pad">
                                                    <span>What was the patient’s</span>
                                                    <span
                                                        className="histologic-grade-container"
                                                        // title={
                                                        //     isTooltipVisible
                                                        //       ? "Grade I: 0-5 CB/HPF\nGrade II: 6-15 CB/HPF\nGrade IIIA: >15 CB/HPF with centrocytes present\nGrade IIIB: >15 CB/HPF with only centroblasts present.\n*CB/HPF = centroblasts per standard microscopic high power field."
                                                        //       : ""
                                                        //   }
                                                          onClick={handleTooltipToggle}
                                                          onMouseEnter={handleMouseEnter}
                                                          onMouseLeave={handleMouseLeave}
                                                    >
                                                        {" "}
                                                        <span className="histologic-grade">
                                                            histologic grade
                                                        </span>
                                                        {isTooltipVisible && (
                                                            <div className="custom-tool-tip">
                                                            Grade I: 0-5 CB/HPF<br/>
                                                            Grade II: 6-15 CB/HPF<br/>
                                                            Grade IIIA: &gt;15 CB/HPF with centrocytes present<br/>
                                                            Grade IIIB: &gt;15 CB/HPF with only centroblasts present<br/>
                                                            *CB/HPF = centroblasts per standard microscopic high power field.
                                                            </div>
                                                        )}
                                                        
                                                    </span>{" "}
                                                    on{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    , the date of initiation of treatment with
                                                    epcoritamab?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <form>
                                                    <div className="form-group">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q18_hist_grade_1"
                                                                name="q18_hist_grade"
                                                                value={1}
                                                                checked={ccDetails.q18_hist_grade === 1}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">I</span>
                                                        </label>
                                                        <br />
                                                        {/* <Q18SubSection/> */}
                                                        {ccDetails.q18_hist_grade === 1 &&
                                                            <CCQ18LymClass HandleFieldChange={HandleFieldChange} q18_lym_class={ccDetails.q18_lym_class} q18LymClassEmpty={hardErrList.q18LymClassEmpty} q18LymClassUnk={softErrList.q18LymClassUnk} />
                                                        }
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q18_hist_grade_2"
                                                                name="q18_hist_grade"
                                                                value={2}
                                                                checked={ccDetails.q18_hist_grade === 2}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">II</span>
                                                        </label>
                                                        <br />
                                                        {ccDetails.q18_hist_grade === 2 &&
                                                            <CCQ18LymClass HandleFieldChange={HandleFieldChange} q18_lym_class={ccDetails.q18_lym_class} q18LymClassEmpty={hardErrList.q18LymClassEmpty} q18LymClassUnk={softErrList.q18LymClassUnk} />
                                                        }
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q18_hist_grade_3"
                                                                name="q18_hist_grade"
                                                                value={3}
                                                                checked={ccDetails.q18_hist_grade === 3}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">III</span>
                                                        </label>
                                                        <br />
                                                        {ccDetails.q18_hist_grade === 3 &&
                                                            <CCQ18LymClass HandleFieldChange={HandleFieldChange} q18_lym_class={ccDetails.q18_lym_class} q18LymClassEmpty={hardErrList.q18LymClassEmpty} q18LymClassUnk={softErrList.q18LymClassUnk} />
                                                        }
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q18_hist_grade_4"
                                                                name="q18_hist_grade"
                                                                value={4}
                                                                checked={ccDetails.q18_hist_grade === 4}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">IIIA</span>
                                                        </label>
                                                        <br />
                                                        {ccDetails.q18_hist_grade === 4 &&
                                                            <CCQ18LymClass HandleFieldChange={HandleFieldChange} q18_lym_class={ccDetails.q18_lym_class} q18LymClassEmpty={hardErrList.q18LymClassEmpty} q18LymClassUnk={softErrList.q18LymClassUnk} />
                                                        }
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q18_hist_grade_5"
                                                                name="q18_hist_grade"
                                                                value={5}
                                                                checked={ccDetails.q18_hist_grade === 5}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">IIIB</span>
                                                        </label>
                                                        <br />
                                                        {ccDetails.q18_hist_grade === 5 &&
                                                            <CCQ18LymClass HandleFieldChange={HandleFieldChange} q18_lym_class={ccDetails.q18_lym_class} q18LymClassEmpty={hardErrList.q18LymClassEmpty} q18LymClassUnk={softErrList.q18LymClassUnk} />
                                                        }
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q18_hist_grade_99"
                                                                name="q18_hist_grade"
                                                                value={99}
                                                                checked={ccDetails.q18_hist_grade === 99}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Unknown
                                                            </span>
                                                        </label>
                                                    </div>
                                                </form>
                                            </div>
                                            <ErrorField show={softErrList.q18GradeUnk} message={CommonError_Msgs.UnknownSelect} />
                                            <ErrorField show={hardErrList.q18GradeEmpty} message={ClinicalCharacteristics_Msgs.q18GradeEmpty} />
                                        </div>
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q19.</span>
                                                <span className="quest-text-pad">
                                                    What was the molecular classification of the patient’s
                                                    lymphoma on{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    , the date of initiation of treatment with
                                                    epcoritamab?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q19_mol_class_1"
                                                            name="q19_mol_class"
                                                            value={1}
                                                            checked={ccDetails.q19_mol_class === 1}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Germinal Center B-Cell (GCB){" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q19_mol_class_2"
                                                            name="q19_mol_class"
                                                            value={2}
                                                            checked={ccDetails.q19_mol_class === 2}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Non-Germinal Center B-Cell (non-GCB){" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q19_mol_class_1"
                                                            name="q19_mol_class"
                                                            value={3}
                                                            checked={ccDetails.q19_mol_class === 3}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Activated B-Cell (ABC){" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q19_mol_class_99"
                                                            name="q19_mol_class"
                                                            value={99}
                                                            checked={ccDetails.q19_mol_class === 99}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">Unknown </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q19_mol_class_5"
                                                            name="q19_mol_class"
                                                            value={5}
                                                            checked={ccDetails.q19_mol_class === 5}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Not assessed{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={softErrList.q19Unk} message={CommonError_Msgs.UnknownSelect} />
                                            <ErrorField show={hardErrList.q19Empty} message={ClinicalCharacteristics_Msgs.q19Empty} />
                                        </div>
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q20.</span>
                                                <span className="quest-text-pad">
                                                    What was the patient’s Ann Arbor disease stage closest
                                                    to (within 12 months prior to), or on{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    , the date of initiation of treatment with
                                                    epcoritamab?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                {/* <form> */}
                                                <div className="form-group">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q20_ann_arbor_stage_1"
                                                            name="q20_ann_arbor_stage"
                                                            value={1}
                                                            checked={ccDetails.q20_ann_arbor_stage === 1}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Stage I
                                                        </span>
                                                    </label>
                                                    <br />
                                                    {ccDetails.q20_ann_arbor_stage === 1 &&
                                                        <CCQ20_21_SubQuestion ctrlId={"q20_sub_question"} value={ccDetails.q20_sub_question} HandleFieldChange={HandleFieldChange} subOptionsEmpty={hardErrList.q20SubEmpty} />
                                                    }
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q20_ann_arbor_stage_2"
                                                            name="q20_ann_arbor_stage"
                                                            value={2}
                                                            checked={ccDetails.q20_ann_arbor_stage === 2}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Stage II
                                                        </span>
                                                    </label>
                                                    <br />
                                                    {ccDetails.q20_ann_arbor_stage === 2 &&
                                                        <CCQ20_21_SubQuestion ctrlId={"q20_sub_question"} value={ccDetails.q20_sub_question} HandleFieldChange={HandleFieldChange} subOptionsEmpty={hardErrList.q20SubEmpty} />
                                                    }
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q20_ann_arbor_stage_3"
                                                            name="q20_ann_arbor_stage"
                                                            value={3}
                                                            checked={ccDetails.q20_ann_arbor_stage === 3}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Stage III
                                                        </span>
                                                    </label>
                                                    <br />
                                                    {ccDetails.q20_ann_arbor_stage === 3 &&
                                                        <CCQ20_21_SubQuestion ctrlId={"q20_sub_question"} value={ccDetails.q20_sub_question} HandleFieldChange={HandleFieldChange} subOptionsEmpty={hardErrList.q20SubEmpty} />
                                                    }
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q20_ann_arbor_stage_4"
                                                            name="q20_ann_arbor_stage"
                                                            value={4}
                                                            checked={ccDetails.q20_ann_arbor_stage === 4}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Stage IV
                                                        </span>
                                                    </label>
                                                    <br />
                                                    {ccDetails.q20_ann_arbor_stage === 4 &&
                                                        <CCQ20_21_SubQuestion ctrlId={"q20_sub_question"} value={ccDetails.q20_sub_question} HandleFieldChange={HandleFieldChange} subOptionsEmpty={hardErrList.q20SubEmpty} />
                                                    }
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q20_ann_arbor_stage_99"
                                                            name="q20_ann_arbor_stage"
                                                            value={99}
                                                            checked={ccDetails.q20_ann_arbor_stage === 99}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Unknown
                                                        </span>
                                                    </label>
                                                </div>
                                                {/* </form> */}
                                            </div>
                                            <ErrorField show={softErrList.q20StageUnk} message={CommonError_Msgs.UnknownSelect} />
                                            <ErrorField show={hardErrList.q20StageEmpty} message={ClinicalCharacteristics_Msgs.q20StageEmpty} />
                                        </div>
                                        {ccDetails.q20_ann_arbor_stage === 99 && (
                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>Q21.</span>
                                                    <span className="quest-text-pad">
                                                        Please choose the best description of the patient’s
                                                        disease stage (i.e., involvement of the nodal region)
                                                        closest to (within 12 months prior to), or on{" "}
                                                        <span
                                                            style={{ color: PageDesignConstants.fontColor }}
                                                        >
                                                            {index_date}
                                                        </span>
                                                        , the date of initiation of treatment with
                                                        epcoritamab?
                                                    </span>
                                                </div>
                                                <div className=" double-dig-answer">
                                                    <div className="form-group">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q21_stage_descr_1"
                                                                name="q21_stage_descr"
                                                                value={1}
                                                                checked={ccDetails.q21_stage_descr === 1}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Single lymph node or lymph node region
                                                            </span>
                                                        </label>
                                                        <br />
                                                        {ccDetails.q21_stage_descr === 1 &&
                                                            <CCQ20_21_SubQuestion ctrlId={"q21_sub_question"} value={ccDetails.q21_sub_question} HandleFieldChange={HandleFieldChange} subOptionsEmpty={hardErrList.q21SubEmpty} />
                                                        }
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q21_stage_descr_2"
                                                                name="q21_stage_descr"
                                                                value={2}
                                                                checked={ccDetails.q21_stage_descr === 2}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Two or more lymph node regions on same side of
                                                                diaphragm
                                                            </span>
                                                        </label>
                                                        <br />
                                                        {ccDetails.q21_stage_descr === 2 &&
                                                            <CCQ20_21_SubQuestion ctrlId={"q21_sub_question"} value={ccDetails.q21_sub_question} HandleFieldChange={HandleFieldChange} subOptionsEmpty={hardErrList.q21SubEmpty} />
                                                        }
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q21_stage_descr_3"
                                                                name="q21_stage_descr"
                                                                value={3}
                                                                checked={ccDetails.q21_stage_descr === 3}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Lymph node regions on both sides of the diaphragm
                                                                affected
                                                            </span>
                                                        </label>
                                                        <br />
                                                        {ccDetails.q21_stage_descr === 3 &&
                                                            <CCQ20_21_SubQuestion ctrlId={"q21_sub_question"} value={ccDetails.q21_sub_question} HandleFieldChange={HandleFieldChange} subOptionsEmpty={hardErrList.q21SubEmpty} />
                                                        }
                                                        <label className="long-text-option">
                                                            <input
                                                                type="radio"
                                                                id="q21_stage_descr_4"
                                                                name="q21_stage_descr"
                                                                value={4}
                                                                checked={ccDetails.q21_stage_descr === 4}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                {" "}
                                                                Disease is widespread, including multiple
                                                                involvement at one or more extranodal sites
                                                                (beyond the lymph node, spleen, tonsils and
                                                                Waldeyer’s ring), such as the bone marrow, liver
                                                                or Pleura
                                                            </span>
                                                        </label>
                                                        {ccDetails.q21_stage_descr === 4 &&
                                                            <CCQ20_21_SubQuestion ctrlId={"q21_sub_question"} value={ccDetails.q21_sub_question} HandleFieldChange={HandleFieldChange} subOptionsEmpty={hardErrList.q21SubEmpty} />
                                                        }
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q21_stage_descr_99"
                                                                name="q21_stage_descr"
                                                                value={99}
                                                                checked={ccDetails.q21_stage_descr === 99}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding ">Unknown</span>
                                                        </label>
                                                        {ccDetails.q21_stage_descr === 99 &&
                                                            <CCQ20_21_SubQuestion ctrlId={"q21_sub_question"} value={ccDetails.q21_sub_question} HandleFieldChange={HandleFieldChange} subOptionsEmpty={hardErrList.q21SubEmpty} />
                                                        }
                                                    </div>
                                                </div>
                                                <ErrorField show={softErrList.q21StageDescrUnk} message={CommonError_Msgs.UnknownSelect} />
                                                <ErrorField show={hardErrList.q21StageDescrEmpty} message={ClinicalCharacteristics_Msgs.q21StageDescrEmpty} />
                                            </div>
                                        )}
                                        {(ccDetails.q20_ann_arbor_stage === 4 || ccDetails.q21_stage_descr === 4) && (
                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>Q22.</span>
                                                    <span className="quest-text-pad">
                                                        Was there more than 1 extranodal site? {""}
                                                        <i>(Please only input numbers)</i>
                                                    </span>
                                                </div>
                                                <div className=" double-dig-answer">
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q22_extranodal_site_1"
                                                                name="q22_extranodal_site"
                                                                value={1}
                                                                checked={ccDetails.q22_extranodal_site === 1}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Yes, specify number of nodes: {""}
                                                                <input
                                                                    type="text"
                                                                    name="q22_extranodal_site_txt"
                                                                    className="sub-input-dash"
                                                                    value={ccDetails.q22_extranodal_site_txt}
                                                                    onChange={HandleFieldChange}
                                                                    maxLength={100}
                                                                />
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q22_extranodal_site_0"
                                                                name="q22_extranodal_site"
                                                                value={0}
                                                                checked={ccDetails.q22_extranodal_site === 0}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">No</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q22_extranodal_site_99"
                                                                name="q22_extranodal_site"
                                                                value={99}
                                                                checked={ccDetails.q22_extranodal_site === 99}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">Unknown</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorField show={softErrList.q22Unk} message={CommonError_Msgs.UnknownSelect} />
                                                <ErrorField show={hardErrList.q22Empty} message={ClinicalCharacteristics_Msgs.q22Empty} />
                                                <ErrorField show={hardErrList.q22TxtEmpty} message={ClinicalCharacteristics_Msgs.NodeNotSpecified} />
                                                <ErrorField show={hardErrList.q22ExtranodalNodesRangeLimHard} message={ClinicalCharacteristics_Msgs.RangeLimit} />
                                            </div>
                                        )}
                                        {(ccDetails.q20_ann_arbor_stage === 4 || ccDetails.q21_stage_descr === 4) && (
                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>Q23.</span>
                                                    <span className="quest-text-pad">
                                                        Was there more than 1 nodal site? {""}
                                                        <i>(Please only input numbers)</i>
                                                    </span>
                                                </div>
                                                <div className=" double-dig-answer">
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q23_nodal_site_1"
                                                                name="q23_nodal_site"
                                                                value={1}
                                                                checked={ccDetails.q23_nodal_site === 1}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Yes, specify number of nodes: {""}
                                                                <input
                                                                    type="text"
                                                                    name="q23_nodal_site_txt"
                                                                    className="sub-input-dash"
                                                                    value={ccDetails.q23_nodal_site_txt}
                                                                    onChange={HandleFieldChange}
                                                                    maxLength={100}
                                                                />
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q23_nodal_site_0"
                                                                name="q23_nodal_site"
                                                                value={0}
                                                                checked={ccDetails.q23_nodal_site === 0}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">No</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id="q23_nodal_site_99"
                                                                name="q23_nodal_site"
                                                                value={99}
                                                                checked={ccDetails.q23_nodal_site === 99}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">Unknown</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorField show={softErrList.q23Unk} message={CommonError_Msgs.UnknownSelect} />
                                                <ErrorField show={hardErrList.q23Empty} message={ClinicalCharacteristics_Msgs.q23Empty} />
                                                <ErrorField show={hardErrList.q23TxtEmpty} message={ClinicalCharacteristics_Msgs.NodeNotSpecified} />
                                                <ErrorField show={hardErrList.q23NodalNodesRangeLimHard} message={ClinicalCharacteristics_Msgs.RangeLimit} /> </div>
                                        )}
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q24.</span>
                                                <span className="quest-text-pad">
                                                    What was the size of the patient’s largest lymph node
                                                    closest to (within 12 months prior to) or on{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    , the date of initiation of treatment with epcoritamab
                                                    (based on physical exam, CT imaging, PET/CT or MRI)?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <b>Date of assessment:</b>
                                                    <br />
                                                    <div className="date-field-padding">
                                                        <span className=" ">
                                                            <DateControl
                                                                ctrlId={"q24_assessment_date"}
                                                                HandleDateChange={HandleDateChange}
                                                                date={ccDetails.q24_assessment_date}
                                                               // hideUnk={true}
                                                                removeErrMsgPadding={true}
                                                                showUnkInfoTxt ={true}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                                <ErrorField show={hardErrList.q24DateEmpty} message={ClinicalCharacteristics_Msgs.q24DateEmpty} style={{ paddingLeft: '0px' }} />
                                                
                                                <ErrorField show={hardErrList.q24DateValidation} message={ClinicalCharacteristics_Msgs.q24DateValidation.replace("{ID minus Index}", index_dt_minus_12mo)
                                                        .replace("{index date}", index_date)} style={{ paddingLeft: '0px' }} />
                                                <br />
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q24_size_1"
                                                            name="q24_size"
                                                            value={1}
                                                            checked={ccDetails.q24_size === 1}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Result: {""}
                                                            <input
                                                                type="text"
                                                                name="q24_size_txt"
                                                                className="sub-input-dash"
                                                                value={ccDetails.q24_size_txt}
                                                                onChange={HandleFieldChange}
                                                                maxLength={100}
                                                            />{" "}
                                                            cm
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q24_size_99"
                                                            name="q24_size"
                                                            value={99}
                                                            checked={ccDetails.q24_size === 99}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">Unknown</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={softErrList.q24SizeUnk} message={CommonError_Msgs.UnknownSelect} />
                                            <ErrorField show={hardErrList.q24SizeEmpty || hardErrList.q24SizeTxtEmpty} message={ClinicalCharacteristics_Msgs.q24SizeEmpty} />
                                            <ErrorField show={hardErrList.q24SizeRangeLimHard} message={ClinicalCharacteristics_Msgs.RangeLimit} />
                                        </div>

                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q25.</span>
                                                <span className="quest-text-pad">
                                                    What was the patient’s International Prognostic Index
                                                    (IPI) risk score closest to (within 12 months prior
                                                    to) or on{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    , the date of initiation of treatment with
                                                    epcoritamab?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q25_ipi_score_1"
                                                            name="q25_ipi_score"
                                                            value={1}
                                                            checked={ccDetails.q25_ipi_score === 1}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            0-1 points (low risk){" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q25_ipi_score_2"
                                                            name="q25_ipi_score"
                                                            value={2}
                                                            checked={ccDetails.q25_ipi_score === 2}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            2 points (low-intermediate risk){" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q25_ipi_score_3"
                                                            name="q25_ipi_score"
                                                            value={3}
                                                            checked={ccDetails.q25_ipi_score === 3}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            3 points (high-intermediate risk){" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q25_ipi_score_4"
                                                            name="q25_ipi_score"
                                                            value={4}
                                                            checked={ccDetails.q25_ipi_score === 4}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            4-5 points (high-risk){" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q25_ipi_score_99"
                                                            name="q25_ipi_score"
                                                            value={99}
                                                            checked={ccDetails.q25_ipi_score === 99}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">Unknown </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={softErrList.q25Unk} message={CommonError_Msgs.UnknownSelect} />
                                            <ErrorField show={hardErrList.q25Empty} message={ClinicalCharacteristics_Msgs.q25Empty} />
                                        </div>

                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q26.</span>
                                                <span className="quest-text-pad">
                                                    What was the patient’s CD20 status closest to (within
                                                    12 months prior to) or on{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    , the date of initiation of treatment with
                                                    epcoritamab?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q26_cd20_status_1"
                                                            name="q26_cd20_status"
                                                            value={1}
                                                            checked={ccDetails.q26_cd20_status === 1}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Positive (any % &gt;0){" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q26_cd20_status_2"
                                                            name="q26_cd20_status"
                                                            value={2}
                                                            checked={ccDetails.q26_cd20_status === 2}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Negative{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q26_cd20_status_99"
                                                            name="q26_cd20_status"
                                                            value={99}
                                                            checked={ccDetails.q26_cd20_status === 99}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">Unknown </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={softErrList.q26Unk} message={CommonError_Msgs.UnknownSelect} />
                                            <ErrorField show={hardErrList.q26Empty} message={ClinicalCharacteristics_Msgs.q26Empty} />
                                        </div>

                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q27.</span>
                                                <span className="quest-text-pad">
                                                    Please check any of the following B symptoms the
                                                    patient experienced closest to (within 12 months prior
                                                    to) or on{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    , the date of initiation of treatment with
                                                    epcoritamab.
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q27_fever"
                                                            name="q27_fever"
                                                            checked={ccDetails.q27_fever}
                                                            onChange={HandleQ27FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Fever
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q27_sweats"
                                                            name="q27_sweats"
                                                            checked={ccDetails.q27_sweats}
                                                            onChange={HandleQ27FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Drenching night sweats{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q27_weight_loss"
                                                            name="q27_weight_loss"
                                                            checked={ccDetails.q27_weight_loss}
                                                            onChange={HandleQ27FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Loss of more than 10% of body weight over 6 months{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q27_lack_energy"
                                                            name="q27_lack_energy"
                                                            checked={ccDetails.q27_lack_energy}
                                                            onChange={HandleQ27FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Lack of energy{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q27_tired"
                                                            name="q27_tired"
                                                            checked={ccDetails.q27_tired}
                                                            onChange={HandleQ27FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Easily tired
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q27_body_pain"
                                                            name="q27_body_pain"
                                                            checked={ccDetails.q27_body_pain}
                                                            onChange={HandleQ27FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Body pain{" "}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="q27_oth"
                                                            name="q27_oth"
                                                            checked={ccDetails.q27_oth}
                                                            onChange={HandleQ27FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Other, specify:{" "}
                                                            <input
                                                                name="q27_oth_txt"
                                                                className="input-dash"
                                                                type="text"
                                                                value={ccDetails.q27_oth_txt}
                                                                onChange={HandleQ27FieldChange}
                                                                maxLength={100}
                                                            />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q27_none_unk_1"
                                                            name="q27_none_unk"
                                                            value={1}
                                                            checked={ccDetails.q27_none_unk === 1}
                                                            onChange={HandleQ27FieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            The patient did not display any of the above B
                                                            symptoms
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q27_none_unk_99"
                                                            name="q27_none_unk"
                                                            value={99}
                                                            checked={ccDetails.q27_none_unk === 99}
                                                            onChange={HandleQ27FieldChange}
                                                        />
                                                        <span className="radio-text-padding">Unknown</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={softErrList.q27Unk} message={CommonError_Msgs.UnknownSelect} />
                                            <ErrorField show={hardErrList.q27Empty} message={ClinicalCharacteristics_Msgs.q27Empty} />
                                            <ErrorField show={hardErrList.q27TxtEmpty} message={ClinicalCharacteristics_Msgs.q27TxtEmpty} />
                                        </div>

                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q28.</span>
                                                <span className="quest-text-pad">
                                                    Please provide the following information regarding the
                                                    patient’s hematologic characteristics and other labs
                                                    closest to (within 6 months prior to) or on{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    , the date of initiation of treatment with
                                                    epcoritamab.
                                                    <br />
                                                    <br />
                                                </span>
                                            </div>

                                            <span
                                                className="question question-weight"
                                                style={PageDesignConstants.PageDescriptionStyle}
                                            >
                                                <i>
                                                    Please provide values for the following lab tests on
                                                    the initiation of first treatment with epcoritamab on{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    .{" "}
                                                </i>
                                            </span>
                                            <span
                                                className="question question-weight"
                                                style={PageDesignConstants.PageDescriptionStyle}
                                            >
                                                <i>
                                                    If these lab tests were not available on{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    , please enter the test results collected closest to
                                                    this date during the 6 months prior (i.e., from  {" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_dt_minus_6mo} {" "}
                                                    </span>
                                                     to{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index_date}
                                                    </span>
                                                    ).
                                                </i>
                                            </span>
                                            <br />
                                          
                                            {/* {!showLabtable && (        */}
                                            <LabTable data={q28LabData} HandleDateChange={HandleQ28DateChange} HandleFieldChange={HandleQ28FieldChange} 
                                            indexDate={index_date} 
                                            indexDtMinus6mo={index_dt_minus_6mo}
                                            />
                                            {/* )} */}
                                            {/* ST: 25/10/2024 */}
                                           <br />
                                            <div className="labTableNota">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        id="labTableNota"
                                                        name="labTableNota"
                                                        checked={ccDetails.labTableNota}
                                                        onChange={HandleFieldChange}
                                                    />

                                                <span className="radio-text-padding">None of the above</span>

                                                    
                                                </label>
                                            </div>
                                            <ErrorField show={hardErrList.labTableNotaEmpty} message={ClinicalCharacteristics_Msgs.labTableNotaEmpty} />

                                        </div>
                                        <b>COVID-19 </b>
                                        <br />
                                        <div style={PageDesignConstants.PageDescriptionStyle}>
                                            <i>
                                            In this section, you will be asked to answer questions related to the patient’s medical history related to COVID-19 vaccination and diagnosis.
                                            </i>
                                        </div>

                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q29.</span>
                                                <span className="quest-text-pad">
                                                    Did the patient receive a COVID-19 vaccine at any
                                                    point in their medical history?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q29_covid_vax_1"
                                                            name="q29_covid_vax"
                                                            value={1}
                                                            checked={ccDetails.q29_covid_vax === 1}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">Yes{""}</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q29_covid_vax_0"
                                                            name="q29_covid_vax"
                                                            value={0}
                                                            checked={ccDetails.q29_covid_vax === 0}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">No</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q29_covid_vax_99"
                                                            name="q29_covid_vax"
                                                            value={99}
                                                            checked={ccDetails.q29_covid_vax === 99}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">Unknown</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={hardErrList.q29Empty} message={ClinicalCharacteristics_Msgs.q29Empty} />
                                            <ErrorField show={softErrList.q29Unk} message={CommonError_Msgs.UnknownSelect} />

                                        </div>
                                        {/* Q30,31 COVID-19 Vaccine rpt */}
                                        {ccDetails.q29_covid_vax === 1 &&
                                            <div className="dependent-section">
                                                {
                                                    covidVaccineDetails && covidVaccineDetails.length > 0 && (
                                                        covidVaccineDetails.map((data, idx) => (
                                                            <div className="loop-section-ul">
                                                                <div className="loop-head">
                                                                    <span className="question-weight">{GetOrdinal(idx + 1)} COVID-19 Vaccine</span>
                                                                    {idx != 0 && <div className="delete-btn-div">
                                                                        <img
                                                                            width="16px"
                                                                            src="../Assets/images/Icon-material-delete.png"
                                                                            alt=""
                                                                            onClick={() => DeleteCovidVaccineRow(idx)}
                                                                        />
                                                                    </div>}
                                                                </div>
                                                                <div className="sub-question-bot-sapce answer-pad-left">
                                                                    <div className="question question-weight">
                                                                        <span>Q30.</span>
                                                                        <span className="quest-text-pad">
                                                                            What was the date the patient received their {""}
                                                                            {GetOrdinalInWord(idx + 1)} COVID-19 vaccine?
                                                                        </span>
                                                                    </div>
                                                                    <div className="double-dig-answer">
                                                                        <div className="date-field-padding">
                                                                            <input
                                                                                type="radio"
                                                                                id={idx}
                                                                                name={"covid_vax" + idx}
                                                                                value={1}
                                                                                checked={data.covid_vax === 1}
                                                                                onChange={HandleRepeaterFieldChange}
                                                                            />
                                                                            <span className="radio-text-padding">
                                                                                <DateControl
                                                                                    ctrlId={"covid_vax_date-" + idx}
                                                                                    HandleDateChange={HandleRepeaterDateChange}
                                                                                    date={data.covid_vax_date}
                                                                                    hideDay={true}
                                                                                    showUnkInfoTxt={true}
                                                                                />
                                                                                {/* <i>If the exact month is unknown, please enter UNK.</i> */}
                                                                            </span>
                                                                        </div>
                                                                        <div className="">
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={idx}
                                                                                    name={"covid_vax" + idx}
                                                                                    value={99}
                                                                                    checked={data.covid_vax === 99}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding">Unknown</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <ErrorField show={data.covidVaxDateUnkSoft} message={CommonError_Msgs.UnknownSelect} />
                                                                    <ErrorField show={data.covidVaxEmptyHard} message={ClinicalCharacteristics_Msgs.covidVaxEmpty.replace('{id}', GetOrdinalInWord(idx + 1))} />
                                                                    
                                                                    <ErrorField show={data.covidVaxAfterHard} message={ClinicalCharacteristics_Msgs.q16DatOnOrBeforeChartAbs} />
                                                                </div>
                                                                <div className="sub-question-bot-sapce answer-pad-left">
                                                                    <div className="question question-weight">
                                                                        <span>Q31.</span>
                                                                        <span className="quest-text-pad">
                                                                            Which company produced the patient’s COVID-19
                                                                            vaccination?
                                                                        </span>
                                                                    </div>
                                                                    <div className="double-dig-answer">
                                                                        <div>
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={idx}
                                                                                    name={"company" + idx}
                                                                                    value={1}
                                                                                    checked={data.company === 1}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding">Pfizer</span>
                                                                            </label>
                                                                        </div>
                                                                        <div>
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={idx}
                                                                                    name={"company" + idx}
                                                                                    value={2}
                                                                                    checked={data.company === 2}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding">Moderna</span>
                                                                            </label>
                                                                        </div>
                                                                        <div>
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={idx}
                                                                                    name={"company" + idx}
                                                                                    value={3}
                                                                                    checked={data.company === 3}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Other, specify: {""}
                                                                                    <input
                                                                                        id={idx}
                                                                                        name={"company_oth_txt"}
                                                                                        className="input-dash"
                                                                                        type="text"
                                                                                        value={data.company_oth_txt}
                                                                                        onChange={HandleRepeaterFieldChange}
                                                                                        maxLength={100}
                                                                                    />
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div>
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={idx}
                                                                                    name={"company" + idx}
                                                                                    value={99}
                                                                                    checked={data.company === 99}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding">Unknown</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <ErrorField show={data.companyUnkSoft} message={CommonError_Msgs.UnknownSelect} />
                                                                    <ErrorField show={data.companyEmptyHard} message={ClinicalCharacteristics_Msgs.companyEmpty} />
                                                                    <ErrorField show={data.companyTxtEmptyHard} message={ClinicalCharacteristics_Msgs.companyTxtEmpty} />
                                                                </div>
                                                            </div>))
                                                    )
                                                }
                                                {covidVaccineDetails && covidVaccineDetails.length < 10 &&
                                                    <div className="sub-question-bot-sapce-ul mt-2">
                                                        <div className="answer-list-text" >
                                                            <a onClick={AddCovidVaccineRow}>
                                                                <img width="18px" src="../Assets/images/plus.png" alt="" />
                                                            </a>
                                                            <a onClick={AddCovidVaccineRow} className="radio-text-padding">
                                                                Click here to add another dose if the patient has received an additional COVID-19 vaccine.
                                                            </a>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }

                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q33.</span>
                                                <span className="quest-text-pad">
                                                    Does the patient have any recorded COVID-19 diagnosis
                                                    at any point in their medical history?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q33_covid_dx_1"
                                                            name="q33_covid_dx"
                                                            value={1}
                                                            checked={ccDetails.q33_covid_dx === 1}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">Yes{""}</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q33_covid_dx_0"
                                                            name="q33_covid_dx"
                                                            value={0}
                                                            checked={ccDetails.q33_covid_dx === 0}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">No</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id="q33_covid_dx_99"
                                                            name="q33_covid_dx"
                                                            value={99}
                                                            checked={ccDetails.q33_covid_dx === 99}
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">Unknown</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorField show={hardErrList.q33Empty} message={ClinicalCharacteristics_Msgs.q33Empty} />
                                            <ErrorField show={softErrList.q33Unk} message={CommonError_Msgs.UnknownSelect} />

                                        </div>
                                        {/* Q34 COVID-19 Covid Diagnosis rpt */}
                                        {ccDetails.q33_covid_dx === 1 &&
                                            <div className="dependent-section">
                                                {
                                                    covidDiagnosisDetails && covidDiagnosisDetails.length > 0 && (
                                                        covidDiagnosisDetails.map((data, idx) => (
                                                            <div className="loop-section-ul">
                                                                <div className="loop-head">
                                                                    <span className="question-weight">{GetOrdinal(idx + 1)} COVID-19 Diagnosis</span>
                                                                    {idx != 0 && <div className="delete-btn-div">
                                                                        <img
                                                                            width="16px"
                                                                            src="../Assets/images/Icon-material-delete.png"
                                                                            alt=""
                                                                            onClick={() => DeleteCovidDiagnosisRow(idx)}
                                                                        />
                                                                    </div>}
                                                                </div>
                                                                <div className="sub-question-bot-sapce answer-pad-left">
                                                                    <div className="question question-weight">
                                                                        <span>Q34.</span>
                                                                        <span className="quest-text-pad">
                                                                            What was the date of {""}
                                                                            {GetOrdinalInWord(idx + 1)} COVID-19 diagnosis?
                                                                        </span>
                                                                    </div>
                                                                    <div className="double-dig-answer">
                                                                        <div className="date-field-padding">
                                                                            <input
                                                                                type="radio"
                                                                                id={idx}
                                                                                name={"covid_dx" + idx}
                                                                                value={1}
                                                                                checked={data.covid_dx === 1}
                                                                                onChange={HandleRepeaterFieldChange}
                                                                            />
                                                                            <span className="radio-text-padding">
                                                                                <DateControl
                                                                                    ctrlId={"covid_dx_date-" + idx}
                                                                                    HandleDateChange={HandleRepeaterDateChange}
                                                                                    date={data.covid_dx_date}
                                                                                    showUnkInfoTxt={true}
                                                                                />
                                                                                {/* <i>If the exact month and/or day is unknown, please enter UNK.</i> */}
                                                                            </span>
                                                                        </div>
                                                                        <div className="">
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    id={idx}
                                                                                    name={"covid_dx" + idx}
                                                                                    value={99}
                                                                                    checked={data.covid_dx === 99}
                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                />
                                                                                <span className="radio-text-padding">Unknown</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <ErrorField show={data.covidDxDateUnkSoft} message={CommonError_Msgs.UnknownSelect} />
                                                                    <ErrorField show={data.covidDxEmptyHard} message={ClinicalCharacteristics_Msgs.covidDxEmpty.replace('{id}', GetOrdinalInWord(idx + 1))}/>
                                                                </div>
                                                            </div>))
                                                    )
                                                }
                                                {covidDiagnosisDetails && covidDiagnosisDetails.length < 10 &&
                                                    <div className="sub-question-bot-sapce-ul mt-2">
                                                        <div className="answer-list-text" >
                                                            <a onClick={AddCovidDiagnosisRow}>
                                                                <img width="18px" src="../Assets/images/plus.png" alt="" />
                                                            </a>
                                                            <a onClick={AddCovidDiagnosisRow} className="radio-text-padding">
                                                                Click here to add another COVID-19 diagnosis.
                                                            </a>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default ClinicalCharacteristics;

const CCQ18LymClass = ({ HandleFieldChange, q18_lym_class, q18LymClassEmpty, q18LymClassUnk }) => {
    return (
        <>
            <div className="dependent-section pad-left-28">
                <div className="form-group">
                    <label>
                        Patient’s lymphoma classification:
                        <br></br>
                        <label>
                            <input
                                type="radio"
                                id="q18_lym_class_1"
                                name="q18_lym_class"
                                value={1}
                                checked={q18_lym_class === 1}
                                onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                                De novo
                            </span>
                        </label>
                        <br></br>
                        <label>
                            <input
                                type="radio"
                                id="q18_lym_class_2"
                                name="q18_lym_class"
                                value={2}
                                checked={q18_lym_class === 2}
                                onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                                Transformed
                            </span>
                        </label>
                        <br></br>
                        <label>
                            <input
                                type="radio"
                                id="q18_lym_class_99"
                                name="q18_lym_class"
                                value={99}
                                checked={q18_lym_class === 99}
                                onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                                Unknown
                            </span>
                        </label>
                    </label>
                </div>
                <ErrorField show={q18LymClassUnk} message={CommonError_Msgs.UnknownSelect} style={{ paddingLeft: '0px' }} />
                <ErrorField show={q18LymClassEmpty} message={ClinicalCharacteristics_Msgs.q18LymClassEmpty} style={{ paddingLeft: '0px' }} />
            </div>
        </>
    );
};
const CCQ20_21_SubQuestion = ({ ctrlId, value, HandleFieldChange, subOptionsEmpty }) => {
    return (
        <>
            <div className="dependent-section pad-left-28">
                <div className="form-group">
                    <label>
                        <label>
                            <input
                                type="radio"
                                // id="q20_sub_question_1"
                                name={ctrlId}
                                value={1}
                                checked={value === 1}
                                onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                                A (without disease symptoms)
                            </span>
                        </label>
                        <br />
                        <label className="long-text-option"                        >
                            <input
                                type="radio"
                                // id="q20_sub_question_2"
                                name={ctrlId}
                                value={2}
                                checked={value === 2}
                                onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                                B (with symptoms like night sweats, weight loss {">"}10% over 6 months, fever without infection)
                            </span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                // id="q20_sub_question_3"
                                name={ctrlId}
                                value={3}
                                checked={value === 3}
                                onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                                E (extranodal disease)
                            </span>
                        </label>
                        <br></br>
                        <label>
                            <input
                                type="radio"
                                // id="q20_sub_question_4"
                                name={ctrlId}
                                value={4}
                                checked={value === 4}
                                onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                                S (splendic involvement)
                            </span>
                        </label>
                        <br></br>
                        <label>
                            <input
                                type="radio"
                                // id="q20_sub_question_5"
                                name={ctrlId}
                                value={5}
                                checked={value === 5}
                                onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                                X (bulky nodal disease)
                            </span>
                        </label>
                    </label>
                </div>
                <ErrorField show={subOptionsEmpty} message={ClinicalCharacteristics_Msgs.q20SubEmpty} style={{ paddingLeft: '0px' }} />
            </div>
        </>
    )
}