import DateControl from "../../../Components/DateControl";
import ErrorField from "../../../Components/ErrorField";
import {
  ClinicalCharacteristics_Msgs,
  CommonError_Msgs,
} from "../../../Helpers/HelperText";

const LabTest = ({
  labRowValues,
  component,
  options,
  HandleDateChange,
  HandleFieldChange,
  indexDate,
  indexDtMinus6mo
}) => {
  return (
    <tr>
      <td>{component}</td>
      <td>
        <div  style={{paddingLeft:"35px"}}>
          <input
         
            type="checkbox"
            id={labRowValues.labTestTypeId}
            name={`lab_selected${labRowValues.labTestTypeId}`}
            checked={labRowValues.selected}
            onChange={HandleFieldChange}
          />
        </div>
      </td>

      {labRowValues.selected ? (
        <>
          <td>
            <div className="date-container">
              <span>
                <label>
                  <input
                    type="radio"
                    id={labRowValues.labTestTypeId}
                    name={`lab_collection${labRowValues.labTestTypeId}`}
                    value={1}
                    checked={labRowValues.collectionDateSelect === 1}
                    onChange={HandleFieldChange}
                  />
                </label>
              </span>
              <span className="radio-text-padding">
                <DateControl
                  ctrlId={`lab_collection_date-${labRowValues.labTestTypeId}`}
                  HandleDateChange={HandleDateChange}
                  date={labRowValues.collectionDate}
                  removeErrMsgPadding={true}
                />
              </span>
            </div>
            <p>
              <i>If the exact month and/or day is unknown, please enter UNK.</i>
            </p>
            <label>
              <input
                type="radio"
                id={labRowValues.labTestTypeId}
                name={`lab_collection${labRowValues.labTestTypeId}`}
                value={99}
                checked={labRowValues.collectionDateSelect === 99}
                onChange={HandleFieldChange}
              />
              <span className="radio-text-padding">Unknown</span>
            </label>
            <ErrorField
              show={labRowValues.collectDtUnkSoft}
              message={CommonError_Msgs.UnknownSelect}
              style={{ paddingLeft: "0px" }}
            />
            <ErrorField
              show={labRowValues.collectDtEmptyHard}
              message={ClinicalCharacteristics_Msgs.labCollectionDtEmpty}
              style={{ paddingLeft: "0px" }}
            />
            {/* <ErrorField
              show={labRowValues.collectionDateInvalid}
              message={ClinicalCharacteristics_Msgs.collectionDtInvalid}
              style={{ paddingLeft: "0px" }}
            /> */}
            <ErrorField show={labRowValues.collectionDateInvalid} message={ClinicalCharacteristics_Msgs.collectionDtInvalid
            .replace("{index date – 6 months}", indexDtMinus6mo)
            .replace("{index date}", indexDate)}
            style={{ paddingLeft: '0px' }} />
          </td>
          <td>
            <div>
              <label>
                <input
                  type="radio"
                  id={labRowValues.labTestTypeId}
                  name={`q28_assess${labRowValues.labTestTypeId}`}
                  value={1}
                  checked={labRowValues.assessSelect === 1}
                  onChange={HandleFieldChange}
                />
                <span className="radio-text-padding">
                  Result:{" "}
                  <input
                    id={labRowValues.labTestTypeId}
                    name={`q28_assess_txt${labRowValues.labTestTypeId}`}
                    className="sub-input-dash"
                    type="text"
                    value={labRowValues.assessTxt}
                    onChange={HandleFieldChange}
                    style={{ width: "53px" }}
                    maxLength={50}
                  />
                  {/* <input
                                name=""
                                type="number"
                                id="a"
                                maxLength={100}
                                placeholder="___.__"
                                style={{ width: "53px" }}
                            /> */}
                </span>
              </label>
            </div>
            {/* <div>
              <label>
                <input
                  type="radio"
                  id={labRowValues.labTestTypeId}
                  name={`q28_assess${labRowValues.labTestTypeId}`}
                  value={2}
                  checked={labRowValues.assessSelect === 2}
                  onChange={HandleFieldChange}
                />
                <span className="radio-text-padding">Not Assessed</span>
              </label>
            </div> */}
            <div>
              <label>
                <input
                  type="radio"
                  id={labRowValues.labTestTypeId}
                  name={`q28_assess${labRowValues.labTestTypeId}`}
                  value={99}
                  checked={labRowValues.assessSelect === 99}
                  onChange={HandleFieldChange}
                />
                <span className="radio-text-padding">Unknown</span>
              </label>
            </div>
            <ErrorField
              show={labRowValues.assessUnkSoft}
              message={CommonError_Msgs.UnknownSelect}
              style={{ paddingLeft: "0px" }}
            />
            <ErrorField
              show={labRowValues.assessRangeLimitHard}
              message={ClinicalCharacteristics_Msgs.q28RangeLimit}
              style={{ paddingLeft: "0px" }}
            />
            <ErrorField
              show={
                labRowValues.assessEmptyHard || labRowValues.assessTxtEmptyHard
              }
              message={ClinicalCharacteristics_Msgs.q28AssessEmpty}
              style={{ paddingLeft: "0px" }}
            />
          </td>
          <td>
            <div>
              <label>
                <input
                  type="radio"
                  id={labRowValues.labTestTypeId}
                  name={`q28_upper_lim${labRowValues.labTestTypeId}`}
                  value={1}
                  checked={labRowValues.upperLimitSelect === 1}
                  onChange={HandleFieldChange}
                />
                <span className="radio-text-padding">
                  Upper Limit of Normal:{" "}
                  <input
                    id={labRowValues.labTestTypeId}
                    name={`q28_upper_lim_txt${labRowValues.labTestTypeId}`}
                    className="sub-input-dash"
                    type="text"
                    value={labRowValues.upperLimitTxt}
                    onChange={HandleFieldChange}
                    style={{ width: "53px" }}
                  />
                </span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  id={labRowValues.labTestTypeId}
                  name={`q28_upper_lim${labRowValues.labTestTypeId}`}
                  value={99}
                  checked={labRowValues.upperLimitSelect === 99}
                  onChange={HandleFieldChange}
                />
                <span className="radio-text-padding">Unknown</span>
              </label>
            </div>
            <ErrorField
              show={labRowValues.upperLimUnkSoft}
              message={CommonError_Msgs.UnknownSelect}
              style={{ paddingLeft: "0px" }}
            />
            <ErrorField
              show={labRowValues.upperLimRangeLimitHard}
              message={ClinicalCharacteristics_Msgs.q28RangeLimit}
              style={{ paddingLeft: "0px" }}
            />
            <ErrorField
              show={
                labRowValues.upperLimEmptyHard ||
                labRowValues.upperLimTxtEmptyHard
              }
              message={ClinicalCharacteristics_Msgs.q28UpperLimitEmpty}
              style={{ paddingLeft: "0px" }}
            />

            <br />
            <div>
              <label>
                <input
                  type="radio"
                  id={labRowValues.labTestTypeId}
                  name={`q28_lower_lim${labRowValues.labTestTypeId}`}
                  value={1}
                  checked={labRowValues.lowerLimitSelect === 1}
                  onChange={HandleFieldChange}
                />
                <span className="radio-text-padding">
                  Lower Limit of Normal:{" "}
                  <input
                    id={labRowValues.labTestTypeId}
                    name={`q28_lower_lim_txt${labRowValues.labTestTypeId}`}
                    className="sub-input-dash"
                    type="text"
                    value={labRowValues.lowerLimitTxt}
                    onChange={HandleFieldChange}
                    style={{ width: "53px" }}
                  />
                </span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  id={labRowValues.labTestTypeId}
                  name={`q28_lower_lim${labRowValues.labTestTypeId}`}
                  value={99}
                  checked={labRowValues.lowerLimitSelect === 99}
                  onChange={HandleFieldChange}
                />
                <span className="radio-text-padding">Unknown</span>
              </label>
            </div>
            <ErrorField
              show={labRowValues.lowerLimUnkSoft}
              message={CommonError_Msgs.UnknownSelect}
              style={{ paddingLeft: "0px" }}
            />
            <ErrorField
              show={labRowValues.lowerLimRangeLimitHard}
              message={ClinicalCharacteristics_Msgs.q28RangeLimit}
              style={{ paddingLeft: "0px" }}
            />
            <ErrorField
              show={
                labRowValues.lowerLimEmptyHard ||
                labRowValues.lowerLimTxtEmptyHard
              }
              message={ClinicalCharacteristics_Msgs.q28LowerLimitEmpty}
              style={{ paddingLeft: "0px" }}
            />

            {/* <div>
                    <input type="radio" name="reference" value="range1" />
                    <label for="result" className="radio-text-padding">Lower Limit of Normal: </label>
                    <input
                        // className="sub-input-dash"
                        type="number"
                        id="result"
                        step="any"
                        placeholder="___.__"
                        style={{ width: "53px" }}
                    />
                </div>
                <div>
                    <input type="radio" name="reference" value="range2" />
                    <label className="radio-text-padding"> Unknown</label>
                </div> */}
          </td>
          <td>
            {options?.map((a) => {
              return (
                <>
                  {/* {a.label[0] !== "Other:" &&
                                (<div>
                                    <label>
                                        <input
                                            type="radio"
                                            id={labRowValues.labTestTypeId}
                                            name={"q28_unit" + labRowValues.labTestTypeId}
                                            value={a.value}
                                            checked={labRowValues.unitSelect === a.value}
                                            onChange={HandleFieldChange}
                                        />
                                        <span className="radio-text-padding">
                                            {a.label}
                                        </span>
                                    </label>
                                </div>)
                            } */}
                  <div className="input-fbox">
                    <label>
                      <input
                        type="radio"
                        id={labRowValues.labTestTypeId}
                        name={`q28_unit${labRowValues.labTestTypeId}-${
                          a.label[0] === "Other:" ? a.value : null
                        }`}
                        value={a.value}
                        checked={labRowValues.unitSelect === a.value}
                        onChange={HandleFieldChange}
                      />
                      <span className="radio-text-padding">
                        {a.label}{" "}
                        {a.label[0] === "Other:" && (
                          <input
                            id={labRowValues.labTestTypeId}
                            name={
                              "q28_unit_txt" +
                              labRowValues.labTestTypeId +
                              "-" +
                              a.value
                            }
                            className="sub-input-dash"
                            type="text"
                            value={labRowValues.unitTxt}
                            onChange={HandleFieldChange}
                            maxLength={50}
                          />
                        )}
                      </span>
                    </label>
                    {/* <input
                                        type="radio"
                                        id={labRowValues.labTestTypeId}
                                        name={"q28_unit" + labRowValues.labTestTypeId}
                                        value={a.value}
                                        checked={labRowValues.unitSelect === a.value}
                                        onChange={HandleFieldChange}
                                    />
                                    <div className="lab-input-fbox">
                                        <label htmlFor="" className="radio-text-padding">
                                            {a.label}{" "}
                                            <input
                                                id={labRowValues.labTestTypeId}
                                                name={"q28_unit_txt" + labRowValues.labTestTypeId}
                                                className="sub-input-dash"
                                                type="text"
                                                value={labRowValues.unitTxt}
                                                onChange={HandleFieldChange}
                                            />
                                        </label>
                                    </div> */}
                  </div>
                </>
              );
            })}
            <ErrorField
              show={labRowValues.unitUnkSoft}
              message={CommonError_Msgs.UnknownSelect}
              style={{ paddingLeft: "0px" }}
            />
            <ErrorField
              show={labRowValues.unitEmptyHard}
              message={ClinicalCharacteristics_Msgs.q28UnitEmpty}
              style={{ paddingLeft: "0px" }}
            />
            <ErrorField
              show={labRowValues.unitTxtEmptyHard}
              message={"Please enter the text."}
              style={{ paddingLeft: "0px" }}
            />
          </td>
        </>
      ) : (
        <td
          colSpan={4}
          style={{
            border: "1px solid black",
            // alignContent: "flex-start",
            textAlign: "center",
            padding: "8px",
            //textAlign: 'start'
          }}
        >
          <span style={{ textAlign: "center" }} className="not-applicable">
            <b>Check box to display rest of columns.</b>
          </span>
        </td>
      )}
    </tr>
  );
};
export default LabTest;
