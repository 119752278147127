import React, { useContext, useEffect, useState } from "react";
import SideBarMenu from "../../../Components/SideBarMenu";
import {
  DefaultDate,
  HTTPResponse,
  PageDesignConstants,
  SideMenuItems,
  ToastMessageType,
  ToastMessages,
  SupportiveCareEpcoritamab,
  MedicationType,
  TreatmentType,
} from "../../../Helpers/Enums";
import DateControl from "../../../Components/DateControl";
import SaveAndNavigate from "../../../Components/SaveAndNavigate";
import PatientDetails from "../../../Components/PatientInformation";
import { Dropdown } from "react-bootstrap";
import {
  CommonError_Msgs,
  CommonMsgs,
  Epcoritamab_Msgs,
  TreatmentPriorEpco_Msgs,
  Treatment_After_Msgs,
} from "../../../Helpers/HelperText";
import ErrorField from "../../../Components/ErrorField";
import { AppContext } from "../../../Contexts/AppContextProvider";
import {
  AllowOnlyIntegers,
  DisplaySelectedText,
  GetDMUID,
  GetDisplayDate,
  GetLocalStorageData,
  GetLoggedInUserID,
  GetOrdinal,
  GetOrdinalInWord,
  IsAfterIndex,
  IsDateEmpty,
  IsDateValid,
  IsMonthUnk,
  IsValidDate1,
  Is_DateBefore,
  ParseDate,
  SetDateState,
  ValueInRange,
  isStringEmpty,
} from "../../../Helpers/Utilities";
import { TreatmentResponseService } from "../../../WebApiServices/TreatmentResponse.service";
import { HttpStatusCode } from "axios";
import { useParams } from "react-router-dom";
const TreatmentAfterEpcoritamab = () => {
  const { lot } = useParams();
  const { appState, ShowCartPage } = useContext(AppContext);
  const { tmtAfterEpcoLOTCnt } = appState ?? 0;
  const { tmtPriorEpcoLOTCnt } = appState ?? 0;

  const prevLot = Number(lot) - 1;
  const nextLot = Number(lot) + 1;
  const isFirstLot = lot == 1;
  const isLastLot = tmtAfterEpcoLOTCnt == lot;

  let navigatePaths = {
    prevPage: isFirstLot
      ? tmtPriorEpcoLOTCnt == 0
        ? "/Epcoritamab"
        : `/TreatmentsPriorEpcoritamab/${tmtPriorEpcoLOTCnt}`
      : `/TreatmentsAfterEpcoritamab/${prevLot}`,
    currPage: `/TreatmentsAfterEpcoritamab/${lot}`,
    nextPage: isLastLot
      ? appState.showCartPage
        ? "/CARTCellTherapy"
        : "/HRU"
      : `/TreatmentsAfterEpcoritamab/${nextLot}`,
  };

  const { ShowToast, ToggleLoader, HandleSessionTimeout } =
    useContext(AppContext);
  const [selectedLabels, setSelectedLabels] = useState("");

  // State to manage visibility of Q46 based on LYRIC or Lugano selection
  const [showQ88, setShowQ88] = useState([]);

  // State to manage visibility of Q47 based on LYRIC or Lugano selection
  // const [showQ89, setShowQ89] = useState([]);

  const patientId = GetLocalStorageData("patientId") ?? 0;
  const userId = GetLoggedInUserID();

  //New Chanegs Q98.
  // const [medicationTypes, setMedicationTypes] = useState([]);
  const [otherInput, setOtherInput] = useState("");
  const [otherInputs, setOtherInputs] = useState({});
  const [subOtherInputs, setSubOtherInputs] = useState("");
  const [radioSelection, setRadioSelection] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({
    parent: {},
    sub: {},
    subSub: {},
  });

  const [epcorLotNum, setEpcorLotNum] = useState(null);
  const initialSelections = (patientTreatment, treatmentMedicationBridges) => {
    if (
      patientTreatment.medicationNone === null &&
      patientTreatment.medicationUnk === null
    ) {
      const newSelectedOptions = { parent: {}, sub: {}, subSub: {} };
      const newOtherInputs = { parent: "", sub: "" };

      treatmentMedicationBridges.forEach((bridge) => {
        newSelectedOptions.parent[bridge.medicationTypeId] = true;

        if (bridge.medicationTypeId === 8 && bridge.medicationTypeOth) {
          newOtherInputs.parent = bridge.medicationTypeOth;
          setOtherInput(bridge.medicationTypeOth);
          // setSubOtherInput(value);
        }

        if (bridge.subMedicationTypeId) {
          if (!newSelectedOptions.sub[bridge.medicationTypeId]) {
            newSelectedOptions.sub[bridge.medicationTypeId] = {};
          }
          newSelectedOptions.sub[bridge.medicationTypeId][
            bridge.subMedicationTypeId
          ] = true;

          if (bridge.subMedicationTypeId === 6 && bridge.subMedicationTypeOth) {
            newOtherInputs.sub = bridge.subMedicationTypeOth;
            setSubOtherInputs(bridge.subMedicationTypeOth);
          }
        }

        if (bridge.subSubMedicationTypeId) {
          if (!newSelectedOptions.subSub[bridge.subMedicationTypeId]) {
            newSelectedOptions.subSub[bridge.subMedicationTypeId] = {};
          }
          newSelectedOptions.subSub[bridge.subMedicationTypeId][
            bridge.subSubMedicationTypeId
          ] = true;
        }
      });

      setSelectedOptions(newSelectedOptions);
      setOtherInputs(newOtherInputs);
    } else {
      if (patientTreatment.medicationNone) {
        setRadioSelection("None");
      } else {
        setRadioSelection("Unknown");
      }
    }
  };

  const [ptTaeDetails, setPtTaeDetails] = useState({
    treatmentAfterEpcoritamabId: 0,
    indexDate: null,

    q70_Tae_therapy: null,
    // q70_Tae_therapy_txt: "",
    selectedLOT: null,

    q71_TmtReceived_1: null,
    q71_TmtReceived_2: null,
    q71_TmtReceived_3: null,
    q71_TmtReceived_4: null,
    q71_TmtReceived_5: null,
    q71_TmtReceived_6: null,
    q71_TmtReceived_7: null,
    q71_TmtReceived_8: null,
    q71_TmtReceived_9: null,
    q71_TmtReceived_10: null,
    q71_TmtReceived_11: null,
    q71_TmtReceived_12: null,
    q71_TmtReceived_13: null,
    q71_TmtReceived_14: null,
    q71_TmtReceived_15: null,
    q71_TmtReceived_16: null,
    q71_TmtReceived_17: null,
    q71_TmtReceived_18: null,
    q71_TmtReceived_19: null,
    q71_TmtReceived_oth: null,
    q71_TmtReceived_oth_txt: "",

    treatmentTypeUnk:false,

    q72CarT_1: null,
    q72CarT_2: null,
    q72CarT_3: null,
    q72CarT_4: null,
    q72CarT_5: null,
    q72CarT_6: null,
    q72CarT_oth: null,
    q72CarT_oth_txt: "",

    q73_apheresis: null,
    q73_Date: DefaultDate,
    q73_Unk: null,

    q74_bridge: null,

    q75_BT_1: null,
    q75_BT_2: null,
    q75_BT_3: null,
    q75_BT_4: null,
    q75_BT_5: null,
    q75_BT_6: null,
    q75_BT_7: null,
    q75_BT_8: null,
    q75_BT_9: null,
    q75_BT_10: null,
    q75_BT_11: null,
    q75_BT_12: null,
    q75_BT_13: null,
    q75_BT_14: null,
    q75_BT_15: null,
    q75_BT_16: null,
    q75_BT_17: null,
    q75_BT_18: null,
    q75_BT_19: null,
    q75_BT_oth: null,
    q75_BT_oth_txt: "",
    q75_BT_Unk: null,

    q76_BT: null,
    q76_Date: DefaultDate,

    q77_BT_Init: null,
    q77_BT_Init_num: "",

    q78_lt: null,

    q79_therapies_1: null,
    q79_therapies_2: null,
    q79_therapies_3: null,
    q79_Unk: null,
    q79_oth_txt: "",

    q80_lt: null,
    q80_Date: DefaultDate,

    q81_anti: null,
    q81_Date: DefaultDate,

    q82_IPI: null,

    q83_IPI: null,
    q83_Date: DefaultDate,

    q84_PD: null,
    q84_Date: DefaultDate,
    q85_RA: null,
    q86_LBCL_: null,
    q86_LBCL_txt: "",
    q87_stage: null,
    q88_lyric: null,
    q89_type: null,
    q89_type_txt: "",
    q90_rat: null,
    q91_lof: null,

    q71_TmtReceived_6_before: false, //holds value of Cart chkbx status before saving
    dosesOfTocilizumabNumOfDoses: null,

    
    medicationUnk: false,
    medicationNone: false,
  });

  const InitialSoftErrList = {
    q70Unk_err: false,
    q71Unk_err: false,
    q72Unk_err: false,
    q73Unk_err: false,
    q74Unk_err: false,
    q75Unk_err: false,
    q76Unk_err: false,
    q77Unk_err: false,
    q78Unk_err: false,
    q79Unk_err: false,
    q80Unk_err: false,
    q81Unk_err: false,
    q82Unk_err: false,
    q83Unk_err: false,
    q84Unk_err: false,
    q91Unk_err: false,
    dosesOfTocilizumabNumOfDoses_unk: false,
    epco_parent_unknown: false,
    epco_doses_of_tocilizumab_unknown: false,

    tmt_prior_parent_unknown:false,
  };

  const [softErrList, setSoftErrList] = useState(InitialSoftErrList);

  const InitialHardErrList = {
    q70Empty: false,
    // q70EmptyOtherTxt: false,
    q70LOTNull: false,
    q71Empty: false,
    q71EmptyOtherTxt: false,
    q72Empty: false,
    q72EmptyOtherTxt: false,
    q73Empty: false,
    q73DateValid: false,
    q74Empty: false,
    q75Empty: false,
    q75EmptyOtherTxt: false,
    q76Empty: false,
    q76DateValidate:false,
    q77Empty: false,
    q78Empty: false,
    q79Empty: false,
    q79EmptyOtherTxt: false,
    q80Empty: false,
    q80DateValidate:false,
    q81Empty: false,
    q81DateValid: false,
    q81_Date_Validation: false,
    q82Empty: null,
    q83Empty: null,
    q83DateValidate:false,
    q84Empty: null,
    q84DateValid: false,
    q91Empty: null,
    epco_parent_empty:false,
  
    q77SizeOutOfRange: false,
    //Q74. checkbox hard error validation
    selectedOptions_Empty: false,
    selectedOptions_Sub_Empty: false,
    // selectedOptions_SubSub_Empty: false,

    [SupportiveCareEpcoritamab.Dexamethasone]: false,
    [SupportiveCareEpcoritamab.Hydrocortisone]: false,
    [SupportiveCareEpcoritamab.Prednisone]: false,
    [SupportiveCareEpcoritamab.Prednisolone]: false,
    [SupportiveCareEpcoritamab.Methylprednisolone]: false,

    [SupportiveCareEpcoritamab.Other]: false,

    epco_parent_othertext_empty: false,
    epco_sub_othertext_empty: false,

    //Q75. hard error message
    dosesOfTocilizumabNumOfDoses_Empty: false,


   


    tmt_parent_empty:false,
    tmt_parent_othertext_empty:false,
    tmt_parent_othertext2_empty:false,
  };

  const [hardErrList, setHardErrList] = useState(InitialHardErrList);

  const [showTopErrMsg, setShowTopErrMsg] = useState(false);


  const [selectedReceivedTmts, setSelectedReceivedTmts] = useState(new Map());
  const SelReceivedTmtsDefaultText = "[treatment]";
  const [selectedReceivedTmtsStr, setSelectedReceivedTmtsStr] = useState(
    SelReceivedTmtsDefaultText
  );

  const[chk_q49_car_t_cell_therapy,setChk_q49_car_t_cell_therapy] = useState(false);


  const DoseOfTocilizumabRow = {
    treatmentaftersitecarebridgeid: 0,
    // lotid:0,
    siteOfCare_rd: null,
    siteOfCare_other: "",
    siteOfCare_rd_empty: false,
    siteOfCare_other_empty: false,
    siteOfCare_other_unk_soft: false,
  };

  //Repeater section
  const [doseOfTocilizumabDetails, setDoseOfTocilizumabDetails] = useState([
    DoseOfTocilizumabRow,
  ]);

  const DeleteDoseOfTocilizumabRow = (id) => {
    const newArray = [...doseOfTocilizumabDetails];
    newArray.splice(id, 1);
    setDoseOfTocilizumabDetails(newArray);
  };
  const AddDoseOfTocilizumabRow = () => {
    setDoseOfTocilizumabDetails((prev) => [
      ...prev,
      {
        ...DoseOfTocilizumabRow,
      },
    ]);
  };
  /* <summary>
  date: 20-06-2024
  Name: VK
  description: Repeater Section
  <summary>*/

  //# Start Region

  const RadiologicalRow = {
    //EPCORAssesmentRow

    lotradiologicalassessmentafterEpcoritamabid: 0, // pk of child table

    //Question 85
    q85RepeaterUnk: null,
    q85Unk_date: DefaultDate,
    //Error
    q85Soft: false,
    q85EmptyHard: false,
    q85afterindexEmptyHard: false,
    q85beforeQ84dateEmptyHard: false,

    //Question 86
    q86LBLC: null,
    q86_Other_txt: "",
    //Error
    q86Soft: false,
    q86EmptyHard: false,
    q86TxtEmptyHard: false,

    //question 87
    q87LyricLugano: null,
    q87EmptyHard: false,

    //question 88
    q88LyricCriteria: null,
    q88EmptyHard: false,

    //Question 89
    q89Unk: null,
    q89_Other_txt: "",
    //Error
    q89Soft: false,
    q89EmptyHard: false,
    q89TxtEmptyHard: false,
    diseaseStageCriteriaSoft: false,
  };
  const [radiologicalAssessmentsDetails, setRadiologicalAssessmentsDetails] =
    useState([RadiologicalRow]);

  // useEffect(()=>{
  //   console.log(RadiologicalRow)
  // })

  //Q43-Q48 EPCOR Assessment Repeater
  const DeleteEPCORAssessmentRow = (id) => {
    const newArray = [...radiologicalAssessmentsDetails];
    newArray.splice(id, 1);
    setRadiologicalAssessmentsDetails(newArray);
  };

  const AddEPCORAssessmentRow = () => {
    setRadiologicalAssessmentsDetails((prev) => [
      ...prev,
      {
        ...RadiologicalRow,
      },
    ]);
  };

  //Repeater
  const HandleRepeaterDateChange = (name, date, datePart) => {
    const lastIndex = name.lastIndexOf("-");
    const id = name.slice(lastIndex + 1);
    switch (name) {
      case "q85radiological_Date-" + id:
        {
          let updatedArray = [...radiologicalAssessmentsDetails];
          const idx = name.slice(lastIndex + 1);
          updatedArray[id].q85Unk_date = SetDateState(
            updatedArray[id].q85Unk_date,
            date,
            datePart
          );
          updatedArray[id].q85RepeaterUnk = 1;

          updatedArray[id].q85EmptyHard = false;
          updatedArray[id].q85Soft = false;

          updatedArray[id].q85afterindexEmptyHard = false;
          updatedArray[id].q85beforeQ84dateEmptyHard = false;
          setRadiologicalAssessmentsDetails(updatedArray);
        }
        break;
      default:
        break;
    }
  };

  const HandleRepeaterFieldChange = (e) => {
    const { name, id, value } = e.target;
    const value_int = Number(value);

    switch (name) {
      //85
      case "q85radiological" + id: {
        let updatedArray = [...radiologicalAssessmentsDetails];
        updatedArray[id].q85RepeaterUnk = value_int;
        updatedArray[id].q85Unk_date = DefaultDate;

        updatedArray[id].q85Soft = value_int === 99;
        updatedArray[id].q85EmptyHard = false;

        updatedArray[id].q85afterindexEmptyHard = false;
        updatedArray[id].q85beforeQ84dateEmptyHard = false;
        setRadiologicalAssessmentsDetails(updatedArray);
        break;
      }

      // 86
      case "q86_LBCL_status" + id: {
        let updatedArray = [...radiologicalAssessmentsDetails];
        updatedArray[id].q86LBLC = value_int;
        updatedArray[id].q86_Other_txt = "";

        updatedArray[id].q86Soft = value_int === 99;
        updatedArray[id].q86EmptyHard = false;
        updatedArray[id].q86TxtEmptyHard = false;
        setRadiologicalAssessmentsDetails(updatedArray);
        break;
      }

      case "q86_LBCL_txt": {
        let updatedArray = [...radiologicalAssessmentsDetails];
        updatedArray[id].q86LBLC = 5;
        updatedArray[id].q86_Other_txt = value;

        updatedArray[id].q86Soft = value_int === 99;
        updatedArray[id].q86EmptyHard = false;
        updatedArray[id].q86TxtEmptyHard = false;
        setRadiologicalAssessmentsDetails(updatedArray);
        break;
      }

      //87
      case "q87_stage_status" + id: {
        let updatedArray = [...radiologicalAssessmentsDetails];

        if (value_int === 1 || value_int === 3) {
          updatedArray[id].q88LyricCriteria = null;
          //updatedArray[id].q89Unk = null;
          //updatedArray[id].q89_Other_txt = "";

          setShowQ88((prev) => ({ ...prev, [id]: true }));
          //setShowQ89((prev) => ({ ...prev, [id]: false }));
        } else if (value_int === 2 || value_int === 4) {
          updatedArray[id].q88LyricCriteria = null;
          //updatedArray[id].q89Unk = null;
          //updatedArray[id].q89_Other_txt = "";

          setShowQ88((prev) => ({ ...prev, [id]: false }));
          // setShowQ89((prev) => ({ ...prev, [id]: true }));
        }

        updatedArray[id].q87LyricLugano = value_int;

        updatedArray[id].q87EmptyHard = false;
        updatedArray[id].diseaseStageCriteriaSoft = value_int === 99;
        setRadiologicalAssessmentsDetails(updatedArray);

        if (value_int === 1 || value_int === 3) {
          setShowQ88((prev) => ({ ...prev, [id]: true }));
          //setShowQ89((prev) => ({ ...prev, [id]: false }));
        } else if (value_int === 2 || value_int === 99 || value_int === 4) {
          setShowQ88((prev) => ({ ...prev, [id]: false }));
          // setShowQ89((prev) => ({ ...prev, [id]: true }));
        }

        break;
      }

      //88
      case "q88LyricAssess" + id: {
        let updatedArray = [...radiologicalAssessmentsDetails];
        updatedArray[id].q88LyricCriteria = value_int;

        updatedArray[id].q88EmptyHard = false;    
        setRadiologicalAssessmentsDetails(updatedArray);
        break;
      }

      // 89
      case "q89_radiology_type" + id: {
        let updatedArray = [...radiologicalAssessmentsDetails];
        updatedArray[id].q89Unk = value_int;
        updatedArray[id].q89_Other_txt = "";

        updatedArray[id].q89Soft = value_int === 99;
        updatedArray[id].q89EmptyHard = false;
        updatedArray[id].q89TxtEmptyHard = false;
        setRadiologicalAssessmentsDetails(updatedArray);
        break;
      }

      case "q89RadioAssessTypeOtherTxt": {
        let updatedArray = [...radiologicalAssessmentsDetails];
        updatedArray[id].q89Unk = 5;
        updatedArray[id].q89_Other_txt = value;

        updatedArray[id].q89Soft = value_int === 99;
        updatedArray[id].q89EmptyHard = false;
        updatedArray[id].q89TxtEmptyHard = false;
        setRadiologicalAssessmentsDetails(updatedArray);
        break;
      }

      // case "dosesOfTocilizumabNumOfDoses": {
      //   setPtTaeDetails((prev) => ({
      //     ...prev,
      //     [name]: Number(value), //value,
      //   }));

      //   setHardErrList((prev) => ({
      //     ...prev,
      //     dosesOfTocilizumabNumOfDoses_empty: false,
      //   }));

      //   setSoftErrList((prev) => ({
      //     ...prev,
      //     dosesOfTocilizumabNumOfDoses_unk: Number(value) === 99,
      //   }));

      //   setDoseOfTocilizumabDetails([
      //     {
      //       ...DoseOfTocilizumabRow,
      //     },
      //   ]);

      //   break;
      // }

      case "siteOfCare_rd" + id: {
        let updatedArray = [...doseOfTocilizumabDetails];
        updatedArray[id].siteOfCare_rd = value_int;
        updatedArray[id].siteOfCare_other = "";

        updatedArray[id].siteOfCare_rd_empty = false;
        updatedArray[id].siteOfCare_other_empty = false;
        updatedArray[id].siteOfCare_other_unk_soft = value_int === 99;

        setDoseOfTocilizumabDetails(updatedArray);
        break;
      }
      case "siteOfCare_other": {
        let updatedArray = [...doseOfTocilizumabDetails];
        updatedArray[id].siteOfCare_rd = 4;
        updatedArray[id].siteOfCare_other = value;

        updatedArray[id].siteOfCare_rd_empty = false;
        updatedArray[id].siteOfCare_other_empty = false;
        updatedArray[id].siteOfCare_other_unk_soft = value_int === 99;

        setDoseOfTocilizumabDetails(updatedArray);
        break;
      }

      default:
        break;
    }
  };

  //# End Region

  const LoadData = async () => {
    try {
      if (patientId > 0) {
        const response =
          await TreatmentResponseService.GetTreatmentAfterEpcoritamabData(
            patientId,
            lot
          );

        if (response?.status === HttpStatusCode.Ok) {
          // setMedicationTypes(
          //   response.data.patientMedicationData.medicationTypes
          // );
          // initialSelections(
          //   response.data.patientMedicationData.patientTreatment,
          //   response.data.patientMedicationData.treatmentMedicationBridges
          // );
          setEpcorLotNum(response.data.epcorLotNum);

          
          const medicationTypes = response.data.patientMedicationData.medicationTypes;
          let updatedMedicationTypes = [];
          if (medicationTypes && medicationTypes.length > 0) {
            updatedMedicationTypes = medicationTypes.map((medicationType) => {
                const updatedSubMedTypes = medicationType.subMedicationTypes.map((subMedicationType) => {
    
                    const updatedSubSubMedTypes = subMedicationType.subSubMedicationTypes.map((subSubMedType) => {
                     
                        return {
                            ...subSubMedType,
                        // epco_sub_empty: false,//hard err
                        // // epco_sub_oth_txt_empty: false,//hard err
                        };
                    });
    
                    return {
                        ...subMedicationType,
                        subSubMedicationTypes: updatedSubSubMedTypes,
                        epco_sub_sub_empty: false,//hard err
                    };
                });
    
                return {
                    ...medicationType,
                    epco_sub_empty: false,//hard err
                    epco_sub_oth_txt_empty: false,//hard err
                    subMedicationTypes: updatedSubMedTypes,
                };
            });
          }
          // if(medicationTypes && medicationTypes.length >0){
          //   medicationTypes.forEach(a => a.subMedicationTypes.forEach(b => b.subSubMedicationTypes.forEach(c => 
          //     c.epcorsupportivecareDetails.forEach(d => {
          //       d.supportive_careRdEmpty= false;
          //       d.supportive_care_dtEmpty= false;
          //       d.dosage_quantityRdEmpty= false;
          //       d.dosage_quantity_txtEmpty= false;
          //       d.dosefrequencyRdEmpty= false;
          //       d.dosefrequency_oth_txtEmpty= false;
          //       d.durationprescribedRdEmpty= false;
          //       d.durationprescribed_txtEmpty= false;
          //     }))))
          // }
          setMedicationTypes(
            updatedMedicationTypes
          );

          const tocilizumabItem = response.data.patientMedicationData.medicationTypes.find(item => item.medicationTypeID === 6);
          // Check if isSelected is true or false and log it
          if (tocilizumabItem) setTocilizumabChecked(tocilizumabItem.isSelected)




          const treatmentTypes = response.data.patientTreatmentData.treatmentTypes;
          let updatedTreatmentTypes = [];
          const initialSelectedTmts = new Map();
          
          if (treatmentTypes && treatmentTypes.length > 0) {
            updatedTreatmentTypes = treatmentTypes.map((treatmentType,idx) => {          
                const updatedSubTmtTypes = treatmentType.subTreatmentTypes.map((subtreatmentType) => {
                  return {
                    ...subtreatmentType, 
                  };
                });
            if (treatmentType.isSelected) {
             
                if(treatmentType.treatmenttypeid === TreatmentType.Oth_Other ||  treatmentType.treatmenttypeid === TreatmentType.Corti_Other)
                {
                  initialSelectedTmts.set(idx, treatmentType.otherText);
                }
                else{
                initialSelectedTmts.set(idx, treatmentType.name);
              }

              if(treatmentType.treatmenttypeid === TreatmentType.CAR_T){
                setChk_q49_car_t_cell_therapy(true);
              }
            }
          
          
              return {
                ...treatmentType,
                subTreatmentTypes:updatedSubTmtTypes,
                    tmt_sub_empty:false, 
               
              };
  
            });
            setSelectedReceivedTmts(initialSelectedTmts);
            const selTmtStr = DisplaySelectedText(new Map(initialSelectedTmts));  
            setSelectedReceivedTmtsStr(
              isStringEmpty(selTmtStr) ? SelReceivedTmtsDefaultText : selTmtStr
            );
          }
       
          
          setTretamentTypes(
            updatedTreatmentTypes
          );
  





          const { data } = response;
          

          setPtTaeDetails({
            treatmentAfterEpcoritamabId:
              data.treatmentafterepcoritamabid != null
                ? data.treatmentafterepcoritamabid
                : 0,
            indexDate: data.indexDate,

            q70_Tae_therapy: data.lineOfTherapy
              ? 1
              : data.lineOfTherapyUnk
              ? 99
              : null,
            selectedLOT: data.lineOfTherapy,

            q71_TmtReceived_1: data.receiveTreatmentAnticd20,
            q71_TmtReceived_2: data.receiveTreatmentAnthracyclines,
            q71_TmtReceived_3: data.receiveTreatmentAlkylating,
            q71_TmtReceived_4: data.receiveTreatmentAutologous,
            q71_TmtReceived_5: data.receiveTreatmentAllogenic,
            q71_TmtReceived_6: data.receiveTreatmentCartcell,
            q71_TmtReceived_7: data.receiveTreatmentBendamustine,
            q71_TmtReceived_8: data.receiveTreatmentGlofitamab,
            q71_TmtReceived_9: data.receiveTreatmentImvotamab,
            q71_TmtReceived_10: data.receiveTreatmentLenalidomide,
            q71_TmtReceived_11: data.receiveTreatmentLoncastatuximab,
            q71_TmtReceived_12: data.receiveTreatmentMosunetuzumab,
            q71_TmtReceived_13: data.receiveTreatmentOdronextamab,
            q71_TmtReceived_14: data.receiveTreatmentPlamotamab,
            q71_TmtReceived_15: data.receiveTreatmentPolatuzumab,
            q71_TmtReceived_16: data.receiveTreatmentRituximab,
            q71_TmtReceived_17: data.receiveTreatmentSelinexor,
            q71_TmtReceived_18: data.receiveTreatmentTafasitamab,
            q71_TmtReceived_oth: data.receiveTreatmentOth,
            q71_TmtReceived_oth_txt: data.receiveTreatmentOthTxt,
            treatmentTypeUnk:data.treatmentUnk,

            q72CarT_1: data.cartcelltherapyAxicabtagene,
            q72CarT_2: data.cartcelltherapyBrexucabtagene,
            q72CarT_3: data.cartcelltherapyCiltacabtagene,
            q72CarT_4: data.cartcelltherapyIdecabtagene,
            q72CarT_5: data.cartcelltherapyLisocabtagene,
            q72CarT_6: data.cartcelltherapyTisagenlecleucel,
            q72CarT_oth: data.cartcelltherapyOth,
            q72CarT_oth_txt: data.cartcelltherapyOthTxt,

            q73_apheresis: data.undergoApheresisDateUnk
              ? 99
              : data.undergoApheresisDate
              ? 1
              : null,

            q73_Date: !isStringEmpty(data.undergoApheresisDate)
              ? GetDisplayDate(
                  data.undergoApheresisDate,
                  data.undergoApheresisDateDmuid
                )
              : DefaultDate,

            q74_bridge: data.receiveBridgingTherapy,

            q75_BT_1: data.bridgingTherapyAcalabrutinib,
            q75_BT_2: data.bridgingTherapyCisplatin,
            q75_BT_3: data.bridgingTherapyCyclophosphamide,
            q75_BT_4: data.bridgingTherapyCytarabine,
            q75_BT_5: data.bridgingTherapyDexamethasone,
            q75_BT_6: data.bridgingTherapyDoxorubicin,
            q75_BT_7: data.bridgingTherapyEtoposide,
            q75_BT_8: data.bridgingTherapyFludarabine,
            q75_BT_9: data.bridgingTherapyGemcitabine,
            q75_BT_10: data.bridgingTherapyHydroxyurea,
            q75_BT_11: data.bridgingTherapyIbrutinib,
            q75_BT_12: data.bridgingTherapyIdarubicin,
            q75_BT_13: data.bridgingTherapyLenalidomide,
            q75_BT_14: data.bridgingTherapyMercaptopurine,
            q75_BT_15: data.bridgingTherapyMethotrexate,
            q75_BT_16: data.bridgingTherapyRituximab,
            q75_BT_17: data.bridgingTherapySteroids,
            q75_BT_18: data.bridgingTherapyTyrosine,
            q75_BT_19: data.bridgingTherapyVincristine,
            q75_BT_oth: data.bridgingTherapyOth,
            q75_BT_oth_txt: data.bridgingTherapyOthTxt,
            q75_BT_Unk: data.bridgingTherapyUnk,


            medicationNone: data.medicationNone,
            medicationUnk: data.medicationUnk,
                
            q76_BT: data.bridgingTherapyInitiationDateUnk
              ? 99 // If bridgingTherapyInitiationDateUnk is 99, set q76_BT to 99
              : data.bridgingTherapyInitiationDate
              ? 1 // If bridgingTherapyInitiationDate is not null, set q76_BT to its value
              : null, // Otherwise, set q76_BT to null

            q76_Date: !isStringEmpty(data.bridgingTherapyInitiationDate)
              ? GetDisplayDate(
                  data.bridgingTherapyInitiationDate,
                  data.bridgingTherapyInitiationDateDmuid
                )
              : DefaultDate,

            q77_BT_Init: data.bridgingTherapyCycleReceived,
            q77_BT_Init_num: data.bridgingTherapyNoOfCycleTxt,
            
            q78_lt: data.lymphodepletingTherapyReceived,

            q79_therapies_1: data.lymphodepletingTherapyCyclophosphamide,
            q79_therapies_2: data.lymphodepletingTherapyFludarabine,
            q79_therapies_3: data.lymphodepletingTherapyOth,
            q79_Unk: data.lymphodepletingTherapyUnk === true ? 99 : null,
            q79_oth_txt: data.lymphodepletingTherapyOthTxt,

            q80_lt: data.lymphodepletingTherapyDateUnk
              ? 99 // If lymphodepletingTherapyDateUnk is 99, set q80_lt to 99
              : data.lymphodepletingTherapyDate
              ? 1
              : // ? data.lymphodepletingTherapyDate // If lymphodepletingTherapyDate is not null, set q80_lt to its value
                null, // Otherwise, set q80_lt to null

            q80_Date: !isStringEmpty(data.lymphodepletingTherapyDate)
              ? GetDisplayDate(
                  data.lymphodepletingTherapyDate,
                  data.lymphodepletingTherapyDateDmuid
                )
              : DefaultDate,

            q81_anti: data.lotXTxInDateUnk ? 99 : data.lotXTxInDate ? 1 : null,
            q81_Date: !isStringEmpty(data.lotXTxInDate)
              ? GetDisplayDate(data.lotXTxInDate, data.lotXTxInDateDmuid)
              : DefaultDate,

            q82_IPI: data.ipiRiskScore,

            q83_IPI: data.ipiRiskAssessmentDateUnk
              ? 99
              : data.ipiRiskAssessmentDate
              ? 1
              : null,
            q83_Date: !isStringEmpty(data.ipiRiskAssessmentDate)
              ? GetDisplayDate(
                  data.ipiRiskAssessmentDate,
                  data.ipiRiskAssessmentDateDmuid
                )
              : DefaultDate,

            q84_PD: data.treatmentStillOn
              ? 2
              : data.discontinueLotDateUnk
              ? 99
              : data.discontinueLotDate
              ? 1
              : null,
            q84_Date: !isStringEmpty(data.discontinueLotDate)
              ? GetDisplayDate(
                  data.discontinueLotDate,
                  data.discontinueLotDateDmuid
                )
              : DefaultDate,

            q91_lof: data.receiveAdditionalLot,

            //this state keeps record of the status before saving to determine whether CAR t page is to be shown or hidden
            q71_TmtReceived_6_before: data.receiveTreatmentCartcell,

            dosesOfTocilizumabNumOfDoses: data.tocilizumabDosesReceived,
          });

          // Rpeater

          if (
            data.radioLogicalDetailsList &&
            data.radioLogicalDetailsList.length > 0
          ) {
            const { radioLogicalDetailsList } = data;
            const radioLogicalDetailsArray = radioLogicalDetailsList.map(
              (item, index) => {
                const lotradiologicalassessmentafterepcoritamabid =
                  item.lotradiologicalassessmentafterepcoritamabid != null
                    ? item.lotradiologicalassessmentafterepcoritamabid
                    : 0;
                const q85Unk_date = !isStringEmpty(
                  item.radiologicalassessmentDate
                )
                  ? GetDisplayDate(
                      item.radiologicalassessmentDate,
                      item.radiologicalassessmentDateDmuid
                    )
                  : DefaultDate;

                // Determine initial visibility for Q88 and Q89 based on Q87 value
                if (
                  item.diseaseStageAssessedPerLyricLugano === 1 ||
                  item.diseaseStageAssessedPerLyricLugano === 3
                ) {
                  setShowQ88((prev) => ({ ...prev, [index]: true }));
                  // setShowQ89((prev) => ({ ...prev, [index]: false }));
                } else if (
                  item.diseaseStageAssessedPerLyricLugano === 2 ||
                  item.diseaseStageAssessedPerLyricLugano === 4
                ) {
                  setShowQ88((prev) => ({ ...prev, [index]: false }));
                  //setShowQ89((prev) => ({ ...prev, [index]: true }));
                }

                return {
                  ...RadiologicalRow,
                  lotradiologicalassessmentafterepcoritamabid,
                  q85RepeaterUnk: item.radiologicalassessmentDateUnk
                    ? 99
                    : !isStringEmpty(item.radiologicalassessmentDate)
                    ? 1
                    : null,
                  q85Unk_date,

                  q86LBLC: item.lbclStatus,
                  q86_Other_txt: item.lbclStatusOthTxt,

                  q87LyricLugano: item.diseaseStageAssessedPerLyricLugano,

                  q88LyricCriteria: item.diseaseAssessmentPerLyric,

                  q89Unk: item.radiologicalassessmentType,
                  q89_Other_txt: item.radiologicalassessmentTypeOthTxt,
                };
              }
            );
            setRadiologicalAssessmentsDetails(radioLogicalDetailsArray);
          }

          if (
            data.siteofcareDetails != null &&
            data.siteofcareDetails.length > 0
          ) {
            const { siteofcareDetails } = data;
            const tmtAfterDetailsListArray = siteofcareDetails.map(
              (item, index) => {
                const treatmentaftersitecarebridgeid =
                  item.treatmentaftersitecarebridgeid != null
                    ? item.treatmentaftersitecarebridgeid
                    : 0;

                return {
                  ...DoseOfTocilizumabRow,
                  treatmentaftersitecarebridgeid,

                  siteOfCare_rd: item.treatmentafterSiteofcare,
                  siteOfCare_other: item.treatmentafterSiteofcareOthTxt,
                };
              }
            );
            setDoseOfTocilizumabDetails(tmtAfterDetailsListArray);
          }
        } else if (response?.status === HttpStatusCode.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  };

  useEffect(() => {
    async function FetchData() {
      if (!isNaN(patientId) && Number(patientId) !== 0 && lot != 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);

  const handleParentCheckboxChange = (medicationTypeID) => {
    setHardErrList((prev) => ({
      ...prev,
      selectedOptions_Empty: false,

      selectedOptions_SubSub_Empty: false,
    }));

    setSoftErrList((prev) => ({
      ...prev,
      epco_parent_unknown: false,
    }));

    if (medicationTypeID === 7) {
      setHardErrList((prev) => ({
        ...prev,

        selectedOptions_Sub_Empty: false,
        epco_sub_othertext_empty: false,
      }));
    }
    if (medicationTypeID === 8) {
      setHardErrList((prev) => ({
        ...prev,

        // epco_parent_othertext_empty: false,
      }));
    }
    setRadioSelection("");
    setSelectedOptions((prevState) => ({
      ...prevState,
      parent: {
        ...prevState.parent,
        [medicationTypeID]: !prevState.parent[medicationTypeID],
      },
      sub: {
        ...prevState.sub,
        [medicationTypeID]: {},
      },
    }));
    if (medicationTypeID === 8 && selectedOptions.parent[medicationTypeID]) {
      setOtherInputs((prevState) => ({
        ...prevState,
        parent: "",
      }));
    }

    if (medicationTypeID === 6) {
      setDoseOfTocilizumabDetails([
        {
          ...DoseOfTocilizumabRow,
        },
      ]);

      setPtTaeDetails((prev) => ({
        ...prev,
        dosesOfTocilizumabNumOfDoses: null,
      }));
      setHardErrList((prev) => ({
        ...prev,
        dosesOfTocilizumabNumOfDoses_Empty: false,
      }));
      setSoftErrList((prev) => ({
        ...prev,
        dosesOfTocilizumabNumOfDoses_unk: false,
      }));
    }
  };
  const handleSubCheckboxChange = (medicationTypeID, subMedicationTypeID) => {
    setHardErrList((prev) => ({
      ...prev,
      selectedOptions_Sub_Empty: false,
      selectedOptions_SubSub_Empty: false,
      [subMedicationTypeID]: false,
    }));

    setRadioSelection("");
    setSelectedOptions((prevState) => ({
      ...prevState,
      sub: {
        ...prevState.sub,
        [medicationTypeID]: {
          ...prevState.sub[medicationTypeID],
          [subMedicationTypeID]:
            !prevState.sub[medicationTypeID]?.[subMedicationTypeID],
        },
      },
      subSub: {
        ...prevState.subSub,
        [subMedicationTypeID]: {},
      },
    }));
    if (
      subMedicationTypeID === 6 &&
      selectedOptions.sub[medicationTypeID]?.[subMedicationTypeID]
    ) {
      setOtherInputs((prevState) => ({
        ...prevState,
        sub: "",
      }));
      setHardErrList((prev) => ({
        ...prev,

        epco_sub_othertext_empty: false,
      }));
    }
  };
  const handleOtherInputChange = (key, value) => {
    if (key === "parent") {
      setOtherInput(value);
      setHardErrList((prev) => ({
        ...prev,
        selectedOptions_Empty: false,
        selectedOptions_Sub_Empty: false,
        selectedOptions_SubSub_Empty: false,
        // epco_parent_othertext_empty: false,
      }));

      setSoftErrList((prev) => ({
        ...prev,
        epco_parent_unknown: false,
      }));
    }
    if (key === "sub") {
      setSubOtherInputs(value);
      setHardErrList((prev) => ({
        ...prev,
        selectedOptions_Sub_Empty: false,
        selectedOptions_SubSub_Empty: false,
        epco_sub_othertext_empty: false,
      }));
    }
    setOtherInputs((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleRadioChange = (e, name) => {
    setHardErrList((prev) => ({
      ...prev,
      selectedOptions_Empty: false,
      selectedOptions_Sub_Empty: false,
      selectedOptions_SubSub_Empty: false,
      // epco_parent_othertext_empty: false,
      epco_sub_othertext_empty: false,
    }));

    setSoftErrList((prev) => ({
      ...prev,
      epco_parent_unknown: name === "Unknown",
    }));

    setRadioSelection(name);
    setSelectedOptions({
      parent: {},
      sub: {},
      subSub: {},
    });
    setOtherInputs((prevState) => ({
      ...prevState,
      parent: "",
      sub: "",
    }));
  };
  const handleSubSubCheckboxChange = (
    subMedicationTypeID,
    subSubMedicationTypeID,
    index
  ) => {
    setRadioSelection("");
    setSelectedOptions((prevState) => ({
      ...prevState,
      subSub: {
        ...prevState.subSub,
        [subMedicationTypeID]: {
          ...prevState.subSub[subMedicationTypeID],
          [subSubMedicationTypeID]:
            !prevState.subSub[subMedicationTypeID]?.[subSubMedicationTypeID],
        },
      },
    }));
    setHardErrList((prev) => ({
      ...prev,
      [subMedicationTypeID]: false,
    }));
  };

  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);

      // Remove soft errors on click of Save
      setSoftErrList(InitialSoftErrList);
      setHardErrList(InitialHardErrList);

      // Validate form
      // if (!ValidateForm()) {
      //   setShowTopErrMsg(true); // Show top error message
      //   ToggleLoader(false); // Stop loader
      //   return false;
      // }
      // setShowTopErrMsg(false); // Hide top error message
      let isValid = validate ? ValidateForm() : true;

      //73
      if (
        !IsDateEmpty(ptTaeDetails.q73_Date) &&
        !IsDateValid(ptTaeDetails.q73_Date)
      ) {
        isValid = false;
      }

      //76
      if (
        !IsDateEmpty(ptTaeDetails.q76_Date) &&
        !IsDateValid(ptTaeDetails.q76_Date)
      ) {
        isValid = false;
      }

      //80
      if (
        !IsDateEmpty(ptTaeDetails.q80_Date) &&
        !IsDateValid(ptTaeDetails.q80_Date)
      ) {
        isValid = false;
      }

      //81
      if (
        !IsDateEmpty(ptTaeDetails.q81_Date) &&
        !IsDateValid(ptTaeDetails.q81_Date)
      ) {
        isValid = false;
      }

      //83
      if (
        !IsDateEmpty(ptTaeDetails.q83_Date) &&
        !IsDateValid(ptTaeDetails.q83_Date)
      ) {
        isValid = false;
      }

      //84
      if (
        !IsDateEmpty(ptTaeDetails.q84_Date) &&
        !IsDateValid(ptTaeDetails.q84_Date)
      ) {
        isValid = false;
      }

      //85
      if (
        radiologicalAssessmentsDetails &&
        radiologicalAssessmentsDetails.length > 0
      ) {
        radiologicalAssessmentsDetails.map((item) => {
          if (
            !IsDateEmpty(item.q85Unk_date) &&
            !IsDateValid(item.q85Unk_date)
          ) {
            isValid = false;
          }

          return item;
        });
      }

   

      
      if (isValid) {

                // const PatientMedicationData = {
        //   MedicationTypes: medicationTypes
        // }

      let updatedMedicationTypes = [];
      if (medicationTypes && medicationTypes.length > 0) {
        updatedMedicationTypes = medicationTypes.map((medicationType) => {
            const updatedSubMedTypes = medicationType.subMedicationTypes.map((subMedicationType) => {

                const updatedSubSubMedTypes = subMedicationType.subSubMedicationTypes.map((subSubMedType) => {
                
                    return {
                        ...subSubMedType,
                        
                    };
                });

                return {
                    ...subMedicationType,
                    subSubMedicationTypes: updatedSubSubMedTypes,
                };
            });

            return {
                ...medicationType,
                subMedicationTypes: updatedSubMedTypes,
            };
        });
      }


      
      let updatedTreatmentTypes = [];
      if (treatmentTypes && treatmentTypes.length > 0) {
        updatedTreatmentTypes = treatmentTypes.map((treatmentType) => {
            const updatedSubMedTypes = treatmentType.subTreatmentTypes.map((subTreatmentType) => {

                return {
                    ...subTreatmentType,
                   
                };
            });

            return {
                ...treatmentType,
                subTreatmentTypes: updatedSubMedTypes,
            };
        });
      }


        // Send formattedPayload to your API or handle accordingly
        console.log("Payload to save:", updatedMedicationTypes);
        const PatientMedicationData = {
          MedicationTypes: updatedMedicationTypes,
          TreatmentTypes: updatedTreatmentTypes
        }
        setShowTopErrMsg(false);
        const treatmentAfterEpcoritamabModel = {
          // SaveMedication: filteredSaveData,
          PatientMedicationData,
          Patientid: patientId,
          UserId: userId,
          lotNumber: lot,

          Treatmentafterepcoritamabid: ptTaeDetails.treatmentAfterEpcoritamabId,

          TocilizumabDosesReceived: ptTaeDetails.dosesOfTocilizumabNumOfDoses,
          IsTocilizumabSelected: tocilizumabChecked == true? true:false,

          LineOfTherapy:
            ptTaeDetails.q70_Tae_therapy === 1
              ? ptTaeDetails.selectedLOT
              : null,
          LineOfTherapyUnk: ptTaeDetails.q70_Tae_therapy === 99,


          MedicationNone: ptTaeDetails.medicationNone,
          MedicationUnk:ptTaeDetails.medicationUnk,
          //71
          ReceiveTreatmentAnticd20: ptTaeDetails.q71_TmtReceived_1,
          ReceiveTreatmentAnthracyclines: ptTaeDetails.q71_TmtReceived_2,
          ReceiveTreatmentAlkylating: ptTaeDetails.q71_TmtReceived_3,
          ReceiveTreatmentAutologous: ptTaeDetails.q71_TmtReceived_4,
          ReceiveTreatmentAllogenic: ptTaeDetails.q71_TmtReceived_5,
          ReceiveTreatmentCartcell: ptTaeDetails.q71_TmtReceived_6,
          ReceiveTreatmentBendamustine: ptTaeDetails.q71_TmtReceived_7,
          ReceiveTreatmentGlofitamab: ptTaeDetails.q71_TmtReceived_8,
          ReceiveTreatmentImvotamab: ptTaeDetails.q71_TmtReceived_9,
          ReceiveTreatmentLenalidomide: ptTaeDetails.q71_TmtReceived_10,
          ReceiveTreatmentLoncastatuximab: ptTaeDetails.q71_TmtReceived_11,
          ReceiveTreatmentMosunetuzumab: ptTaeDetails.q71_TmtReceived_12,
          ReceiveTreatmentOdronextamab: ptTaeDetails.q71_TmtReceived_13,
          ReceiveTreatmentPlamotamab: ptTaeDetails.q71_TmtReceived_14,
          ReceiveTreatmentPolatuzumab: ptTaeDetails.q71_TmtReceived_15,
          ReceiveTreatmentRituximab: ptTaeDetails.q71_TmtReceived_16,
          ReceiveTreatmentSelinexor: ptTaeDetails.q71_TmtReceived_17,
          ReceiveTreatmentTafasitamab: ptTaeDetails.q71_TmtReceived_18,
          ReceiveTreatmentOth: ptTaeDetails.q71_TmtReceived_oth,
          ReceiveTreatmentOthTxt: ptTaeDetails.q71_TmtReceived_oth_txt,

          //72

          CartcelltherapyAxicabtagene: chk_q49_car_t_cell_therapy
            ? ptTaeDetails.q72CarT_1
            : null,
          CartcelltherapyBrexucabtagene: chk_q49_car_t_cell_therapy
            ? ptTaeDetails.q72CarT_2
            : null,
          CartcelltherapyCiltacabtagene: chk_q49_car_t_cell_therapy
            ? ptTaeDetails.q72CarT_3
            : null,
          CartcelltherapyIdecabtagene: chk_q49_car_t_cell_therapy
            ? ptTaeDetails.q72CarT_4
            : null,
          CartcelltherapyLisocabtagene: chk_q49_car_t_cell_therapy
            ? ptTaeDetails.q72CarT_5
            : null,
          CartcelltherapyTisagenlecleucel: chk_q49_car_t_cell_therapy
            ? ptTaeDetails.q72CarT_6
            : null,
          CartcelltherapyOth: chk_q49_car_t_cell_therapy
            ? ptTaeDetails.q72CarT_oth
            : null,
          CartcelltherapyOthTxt: chk_q49_car_t_cell_therapy
            ? ptTaeDetails.q72CarT_oth_txt
            : null,

          //73
          UndergoApheresisDate:
          chk_q49_car_t_cell_therapy &&
            !IsDateEmpty(ptTaeDetails.q73_Date)
              ? ptTaeDetails.q73_apheresis === 1
                ? ParseDate(ptTaeDetails.q73_Date)
                : null
              : null,
          UndergoApheresisDateDmuid: chk_q49_car_t_cell_therapy
            ? ptTaeDetails.q73_apheresis === 1
              ? GetDMUID(ptTaeDetails.q73_Date)
              : null
            : null,
          UndergoApheresisDateUnk: chk_q49_car_t_cell_therapy
            ? ptTaeDetails.q73_apheresis === 99
              ? ptTaeDetails.q73_apheresis
              : false
            : null,

          //74
          ReceiveBridgingTherapy: chk_q49_car_t_cell_therapy
            ? ptTaeDetails.q74_bridge
            : null,

          //75
          BridgingTherapyAcalabrutinib:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_1
              : null,
          BridgingTherapyCisplatin:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_2
              : null,
          BridgingTherapyCyclophosphamide:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_3
              : null,
          BridgingTherapyCytarabine:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_4
              : null,
          BridgingTherapyDexamethasone:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_5
              : null,
          BridgingTherapyDoxorubicin:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_6
              : null,
          BridgingTherapyEtoposide:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_7
              : null,
          BridgingTherapyFludarabine:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_8
              : null,
          BridgingTherapyGemcitabine:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_9
              : null,
          BridgingTherapyHydroxyurea:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_10
              : null,
          BridgingTherapyIbrutinib:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_11
              : null,
          BridgingTherapyIdarubicin:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_12
              : null,
          BridgingTherapyLenalidomide:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_13
              : null,
          BridgingTherapyMercaptopurine:
          chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_14
              : null,
          BridgingTherapyMethotrexate:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_15
              : null,
          BridgingTherapyRituximab:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_16
              : null,
          BridgingTherapySteroids:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_17
              : null,
          BridgingTherapyTyrosine:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_18
              : null,
          BridgingTherapyVincristine:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_19
              : null,
          BridgingTherapyOth:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_oth
              : null,
          BridgingTherapyOthTxt:
           chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_oth_txt
              : null,
          BridgingTherapyUnk:
           chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q75_BT_Unk
              : null,

          //76
          BridgingTherapyInitiationDate:
            chk_q49_car_t_cell_therapy &&
            ptTaeDetails.q74_bridge === 1 &&
            !IsDateEmpty(ptTaeDetails.q76_Date)
              ? ptTaeDetails.q76_BT === 1
                ? ParseDate(ptTaeDetails.q76_Date)
                : null
              : null,
          BridgingTherapyInitiationDateDmuid:
           chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q76_BT === 1
                ? GetDMUID(ptTaeDetails.q76_Date)
                : null
              : null,
          BridgingTherapyInitiationDateUnk:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q76_BT === 99
                ? ptTaeDetails.q76_BT
                : false
              : null,

          //77
          BridgingTherapyCycleReceived:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q77_BT_Init
              : null,
          BridgingTherapyNoOfCycleTxt:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q74_bridge === 1
              ? ptTaeDetails.q77_BT_Init_num
              : null,

          //78
          LymphodepletingTherapyReceived: chk_q49_car_t_cell_therapy
            ? ptTaeDetails.q78_lt
            : null,

          //79
          LymphodepletingTherapyCyclophosphamide:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q78_lt === 1
              ? ptTaeDetails.q79_therapies_1
              : null,
          LymphodepletingTherapyFludarabine:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q78_lt === 1
              ? ptTaeDetails.q79_therapies_2
              : null,
          LymphodepletingTherapyOth:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q78_lt === 1
              ? ptTaeDetails.q79_therapies_3
              : null,
          LymphodepletingTherapyUnk:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q78_lt === 1
              ? ptTaeDetails.q79_Unk
              : null,
          LymphodepletingTherapyOthTxt:
            chk_q49_car_t_cell_therapy && ptTaeDetails.q78_lt === 1
              ? ptTaeDetails.q79_oth_txt
              : null,

          //80

          LymphodepletingTherapyDate:
            ptTaeDetails.q78_lt == 1 && !IsDateEmpty(ptTaeDetails.q80_Date)
              ? ptTaeDetails.q80_lt === 1
                ? ParseDate(ptTaeDetails.q80_Date)
                : null
              : null,
          LymphodepletingTherapyDateDmuid:
            ptTaeDetails.q80_lt == 1
              ? ptTaeDetails.q80_lt === 1
                ? GetDMUID(ptTaeDetails.q80_Date)
                : null
              : null,
          LymphodepletingTherapyDateUnk:
            ptTaeDetails.q78_lt == 1
              ? ptTaeDetails.q80_lt === 99
                ? ptTaeDetails.q80_lt
                : false
              : null,

          //81
          LotXTxInDate:
            ptTaeDetails.q81_anti === 1 && !IsDateEmpty(ptTaeDetails.q81_Date)
              ? ParseDate(ptTaeDetails.q81_Date)
              : null,
          LotXTxInDateDmuid:
            ptTaeDetails.q81_anti === 1
              ? GetDMUID(ptTaeDetails.q81_Date)
              : null,
          LotXTxInDateUnk:
            ptTaeDetails.q81_anti === 99 ? ptTaeDetails.q81_anti : false,

          IpiRiskScore: ptTaeDetails.q82_IPI,
          //83
          IpiRiskAssessmentDate:
            ptTaeDetails.q83_IPI === 1
              ? ParseDate(ptTaeDetails.q83_Date)
              : null,
          IpiRiskAssessmentDateDmuid:
            ptTaeDetails.q83_IPI === 1 ? GetDMUID(ptTaeDetails.q83_Date) : null,
          IpiRiskAssessmentDateUnk:
            ptTaeDetails.q83_IPI === 99 ? ptTaeDetails.q83_IPI : false,

          IpiRiskAssessmentDate:
            ptTaeDetails.q83_IPI === 1 && !IsDateEmpty(ptTaeDetails.q83_Date)
              ? ParseDate(ptTaeDetails.q83_Date)
              : null,
          IpiRiskAssessmentDateDmuid:
            ptTaeDetails.q83_IPI === 1 ? GetDMUID(ptTaeDetails.q83_Date) : null,
          IpiRiskAssessmentDateUnk:
            ptTaeDetails.q83_IPI === 99 ? ptTaeDetails.q83_IPI : false,
          //84
          DiscontinueLotDate:
            ptTaeDetails.q84_PD === 1 && !IsDateEmpty(ptTaeDetails.q84_Date)
              ? ParseDate(ptTaeDetails.q84_Date)
              : null,
          DiscontinueLotDateDmuid:
            ptTaeDetails.q84_PD === 1 ? GetDMUID(ptTaeDetails.q84_Date) : null,
          DiscontinueLotDateUnk:
            ptTaeDetails.q84_PD === 99 ? ptTaeDetails.q84_PD : false,
          TreatmentStillOn:
            ptTaeDetails.q84_PD === 2 ? ptTaeDetails.q84_PD : false,

          ReceiveAdditionalLot: ptTaeDetails.q91_lof,

          RadioLogicalDetailsList: [],
          SiteofcareDetails: [],
          createdby: GetLoggedInUserID() ?? 0,
          editedby: GetLoggedInUserID() ?? 0,
          userId: GetLoggedInUserID(),
          isValidated: validate,
        };

        if (
          radiologicalAssessmentsDetails &&
          radiologicalAssessmentsDetails.length > 0
        ) {
          treatmentAfterEpcoritamabModel.RadioLogicalDetailsList =
            radiologicalAssessmentsDetails.map((a) => ({
              Lotradiologicalassessmentafterepcoritamabid:
                a.lotradiologicalassessmentafterEpcoritamabid,
              RadiologicalAssessmentDate:
                a.q85RepeaterUnk === 1 && !IsDateEmpty(a.q85Unk_date)
                  ? ParseDate(a.q85Unk_date)
                  : null,
              RadiologicalassessmentDateDmuid:
                a.q85RepeaterUnk === 1 ? GetDMUID(a.q85Unk_date) : null,
              RadiologicalassessmentDateUnk: a.q85RepeaterUnk === 99,

              LbclStatus: a.q86LBLC,
              LbclStatusOthTxt: a.q86_Other_txt,

              DiseaseStageAssessedPerLyricLugano: a.q87LyricLugano,

              DiseaseAssessmentPerLyric:
                a.q87LyricLugano === 1 || a.q87LyricLugano === 3
                  ? a.q88LyricCriteria
                  : null,

              RadiologicalassessmentType: a.q89Unk,

              RadiologicalassessmentTypeOthTxt: a.q89_Other_txt,
            }));
        }

        if (doseOfTocilizumabDetails && doseOfTocilizumabDetails.length > 0) {
          treatmentAfterEpcoritamabModel.SiteofcareDetails =
            doseOfTocilizumabDetails.map((a) => ({
              Treatmentaftersitecarebridgeid: a.treatmentaftersitecarebridgeid,
              // Lotid:a.lotid,
              TreatmentafterSiteofcare: a.siteOfCare_rd,
              TreatmentafterSiteofcareOthTxt: a.siteOfCare_other,
            }));
        }

        if (patientId > 0) {
          const response =
            await TreatmentResponseService.SaveTreatmentAfterEpcoritamabData(
              treatmentAfterEpcoritamabModel
            );
          ToggleLoader(false);
          if (response.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            } else throw response.error;
          } else {
            await LoadData();
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);

            //new lot added when Q91 is Yes nav to next lot
            if (ptTaeDetails.q91_lof === 1) {
              navigatePaths.nextPage = `/TreatmentsAfterEpcoritamab/${nextLot}`;
            }

            //CAR Therapy is selected
            if (chk_q49_car_t_cell_therapy) {
              ShowCartPage(false);

              if (ptTaeDetails.q91_lof === 0 || ptTaeDetails.q91_lof === 99) {
                //subsequent lot/s removed when Q91 is No/Unknown
                navigatePaths.nextPage = "/HRU";
              }
            }
            //if not selected, chk if it was selected earlier
            else if (ptTaeDetails.q71_TmtReceived_6_before) {
              //CAR Therapy was selected now unchecked

              const response =
                await TreatmentResponseService.IsCARTCellTherapySelected(
                  patientId
                );
              if (response?.status === HTTPResponse.OK) {
                const { data } = response;
                ShowCartPage(!data);
                await LoadData();
              } else if (response?.status === HTTPResponse.Unauthorized) {
                HandleSessionTimeout();
              }
              if (ptTaeDetails.q91_lof === 0 || ptTaeDetails.q91_lof === 99) {
                //subsequent lot/s removed when Q91 is No/Unknown
                navigatePaths.nextPage =
                  response?.data ?? appState.showCartPage
                    ? "/HRU"
                    : "/CARTCellTherapy";
              }
            } else {
            }
            //  if( ptTaeDetails.q91_lof === 1){//new lot added when Q91 is Yes
            //    navigatePaths.nextPage = `/TreatmentsAfterEpcoritamab/${nextLot}`;
            //  }
            //  else{//subsequent lot/s removed when Q91 is No/Unknown
            //   navigatePaths.nextPage = appState.showCartPage ? "/CARTCellTherapy" : `/HRU`;
            //  }
          }
        }
        return true;
      } else {
        ToggleLoader(false);
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  const ValidateForm = () => {
    let isValid = true;

    // validation for q70
    if (ptTaeDetails.q70_Tae_therapy === null) {
      setHardErrList((prev) => ({
        ...prev,
        q70Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q70Empty: false,
      }));
    }

    if (
      ptTaeDetails.q70_Tae_therapy === 1 &&
      ptTaeDetails.selectedLOT === null
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q70LOTNull: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q70LOTNull: false,
      }));
    }

    if (
      ptTaeDetails.q70_Tae_therapy === 2 &&
      ptTaeDetails.q70_Tae_therapy_txt.trim() === ""
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q70EmptyOtherTxt: true,
      }));
    
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q70EmptyOtherTxt: false,
      }));
    }

    //validation for 71
    // const {
    //   q71_TmtReceived_1,
    //   q71_TmtReceived_2,
    //   q71_TmtReceived_3,
    //   q71_TmtReceived_4,
    //   q71_TmtReceived_5,
    //   q71_TmtReceived_6,
    //   q71_TmtReceived_7,
    //   q71_TmtReceived_8,
    //   q71_TmtReceived_9,
    //   q71_TmtReceived_10,
    //   q71_TmtReceived_11,
    //   q71_TmtReceived_12,
    //   q71_TmtReceived_13,
    //   q71_TmtReceived_14,
    //   q71_TmtReceived_15,
    //   q71_TmtReceived_16,
    //   q71_TmtReceived_17,
    //   q71_TmtReceived_18,
    //   q71_TmtReceived_oth,
    //   q71_TmtReceived_oth_txt,
    // } = ptTaeDetails;

    // if (
    //   !q71_TmtReceived_1 &&
    //   !q71_TmtReceived_2 &&
    //   !q71_TmtReceived_3 &&
    //   !q71_TmtReceived_4 &&
    //   !q71_TmtReceived_5 &&
    //   !q71_TmtReceived_6 &&
    //   !q71_TmtReceived_7 &&
    //   !q71_TmtReceived_8 &&
    //   !q71_TmtReceived_9 &&
    //   !q71_TmtReceived_10 &&
    //   !q71_TmtReceived_11 &&
    //   !q71_TmtReceived_12 &&
    //   !q71_TmtReceived_13 &&
    //   !q71_TmtReceived_14 &&
    //   !q71_TmtReceived_15 &&
    //   !q71_TmtReceived_16 &&
    //   !q71_TmtReceived_17 &&
    //   !q71_TmtReceived_18 &&
    //   !q71_TmtReceived_oth
    // ) {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     q71Empty: true,
    //   }));
    //   isValid = false;
    // } else {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     q71Empty: false,
    //   }));
    // }

    // if (q71_TmtReceived_oth && q71_TmtReceived_oth_txt.trim() === "") {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     q71EmptyOtherTxt: true,
    //   }));
    //   isValid = false;
    // } else {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     q71EmptyOtherTxt: false,
    //   }));
    // }

    //validation for 72

    // //by pass validations
    if (chk_q49_car_t_cell_therapy) {
      const {
        q72CarT_1,
        q72CarT_2,
        q72CarT_3,
        q72CarT_4,
        q72CarT_5,
        q72CarT_oth,
        q72CarT_oth_txt,
      } = ptTaeDetails;

      if (
        !q72CarT_1 &&
        !q72CarT_2 &&
        !q72CarT_3 &&
        !q72CarT_4 &&
        !q72CarT_5 &&
        !q72CarT_oth
      ) {
        setHardErrList((prev) => ({
          ...prev,
          q72Empty: true,
        }));
        isValid = false;
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q72Empty: false,
        }));
      }

      if (q72CarT_oth && q72CarT_oth_txt.trim() === "") {
        setHardErrList((prev) => ({
          ...prev,
          q72EmptyOtherTxt: true,
        }));
        isValid = false;
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q72EmptyOtherTxt: false,
        }));
      }

      //validation for 73
      // ptTaeDetails.q73_apheresis !== null
      let q73_Date = ParseDate(ptTaeDetails.q73_Date)
      if (
        ptTaeDetails.q73_apheresis === 1 &&
        IsDateEmpty(ptTaeDetails.q73_Date)
      ) {
       
        setHardErrList((prev) => ({
          ...prev,
          q73Empty: true,
        }));
        isValid = false;
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q73Empty: false,
        }));
      }

      // q73DateValid
      if (
        ptTaeDetails.q73_apheresis === 1 &&
        !IsDateEmpty(
          ptTaeDetails.q73_Date) &&
            IsDateValid(ptTaeDetails.q73_Date) && !IsMonthUnk(ptTaeDetails.q73_Date) &&
            !IsMonthUnk(ptTaeDetails.indexDate) &&
            !(IsAfterIndex(ptTaeDetails.q73_Date, ptTaeDetails.indexDate))
        
      ) {
        setHardErrList((prev) => ({
          ...prev,
          q73DateValid: true,
        }));
        isValid = false;
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q73DateValid: false,
        }));
      }

      if (ptTaeDetails.q73_apheresis === null) {
        setHardErrList((prev) => ({
          ...prev,
          q73Empty: true,
        }));
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q73Empty: false,
        }));
      }

      // validation for q74
      if (ptTaeDetails.q74_bridge === null) {
        setHardErrList((prev) => ({
          ...prev,
          q74Empty: true,
        }));
        isValid = false;
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q74Empty: false,
        }));
      }

      // validation for q75
      if (ptTaeDetails.q74_bridge === 1) {
        const {
          q75_BT_1,
          q75_BT_2,
          q75_BT_3,
          q75_BT_4,
          q75_BT_5,
          q75_BT_6,
          q75_BT_7,
          q75_BT_8,
          q75_BT_9,
          q75_BT_10,
          q75_BT_11,
          q75_BT_12,
          q75_BT_13,
          q75_BT_14,
          q75_BT_15,
          q75_BT_16,
          q75_BT_17,
          q75_BT_18,
          q75_BT_19,
          q75_BT_oth,
          q75_BT_oth_txt,
          q75_BT_Unk,
        } = ptTaeDetails;

        if (
          !q75_BT_1 &&
          !q75_BT_2 &&
          !q75_BT_3 &&
          !q75_BT_4 &&
          !q75_BT_5 &&
          !q75_BT_6 &&
          !q75_BT_7 &&
          !q75_BT_8 &&
          !q75_BT_9 &&
          !q75_BT_10 &&
          !q75_BT_11 &&
          !q75_BT_12 &&
          !q75_BT_13 &&
          !q75_BT_14 &&
          !q75_BT_15 &&
          !q75_BT_16 &&
          !q75_BT_17 &&
          !q75_BT_18 &&
          !q75_BT_19 &&
          !q75_BT_oth &&
          q75_BT_Unk !== 99
        ) {
          setHardErrList((prev) => ({
            ...prev,
            q75Empty: true,
          }));
          isValid = false;
        } else {
          setHardErrList((prev) => ({
            ...prev,
            q75Empty: false,
          }));
        }

        if (q75_BT_oth && q75_BT_oth_txt.trim() === "") {
          setHardErrList((prev) => ({
            ...prev,
            q75EmptyOtherTxt: true,
          }));
          isValid = false;
        } else {
          setHardErrList((prev) => ({
            ...prev,
            q75EmptyOtherTxt: false,
          }));
        }

        if (q75_BT_Unk === 99) {
          setSoftErrList((prev) => ({
            ...prev,
            q75Unk_err: true,
          }));
        } else {
          setSoftErrList((prev) => ({
            ...prev,
            q75Unk_err: false,
          }));
        }

        //validation for 76 
        let q76_Date = ParseDate(ptTaeDetails.q76_Date)
        if (ptTaeDetails.q76_BT) {
          if (ptTaeDetails.q76_BT === 1 && IsDateEmpty(ptTaeDetails.q76_Date)) {
            isValid = false;
            setHardErrList((prev) => ({
              ...prev,
              q76Empty: true,
            }));
          }

          else if(ptTaeDetails.q76_BT === 1 && !IsDateEmpty(ptTaeDetails.q76_Date) && IsDateValid(ptTaeDetails.q76_Date) && !IsMonthUnk(ptTaeDetails.q76_Date) &&
          !IsMonthUnk(ptTaeDetails.indexDate) && 
          !(IsAfterIndex(ptTaeDetails.q76_Date,ptTaeDetails.indexDate))
          ){
            isValid = false;
            setHardErrList((prev) => ({
              ...prev,
              q76DateValidate: true,
            }));
          }
        } else if (ptTaeDetails.q76_BT === null) {
          setHardErrList((prev) => ({
            ...prev,
            q76Empty: true,
          }));
        }
        //  else {
        //   setHardErrList((prev) => ({
        //     ...prev,
        //     q76Empty: false,
        //   }));
        // }

        // validation for q77
        if (ptTaeDetails.q77_BT_Init === null) {
          setHardErrList((prev) => ({
            ...prev,
            q77Empty: true,
          }));
          isValid = false;
        } else if (ptTaeDetails.q77_BT_Init === 1) {
          if (ptTaeDetails.q77_BT_Init_num === null) {
            setHardErrList((prev) => ({
              ...prev,
              q77Empty: true,
            }));
            isValid = false;
          } else if (
            !ValueInRange(parseInt(ptTaeDetails.q77_BT_Init_num), 0, 100)
          ) {
            setHardErrList((prev) => ({
              ...prev,
              q77SizeOutOfRange: true,
            }));
            isValid = false;
          }
        }

        // else {
        //   setHardErrList((prev) => ({
        //     ...prev,
        //     q77Empty: false,
        //   }));
        // }
      }

      // validation for q78
      if (ptTaeDetails.q78_lt === null) {
        setHardErrList((prev) => ({
          ...prev,
          q78Empty: true,
        }));
        isValid = false;
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q78Empty: false,
          q79Empty: false,
          q80Empty: false,
          q80DateValidate:false,
        }));
      }
    }

    debugger

    //validation for q79
    if (chk_q49_car_t_cell_therapy && ptTaeDetails.q78_lt === 1) {
      if (
        !ptTaeDetails.q79_therapies_1 &&
        !ptTaeDetails.q79_therapies_2 &&
        !ptTaeDetails.q79_therapies_3 &&
        !ptTaeDetails.q79_Unk
      ) {
        setHardErrList((prev) => ({
          ...prev,
          q79Empty: true,
        }));
        isValid = false;
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q79Empty: false,
        }));
      }

      if (
        ptTaeDetails.q79_therapies_3 &&
        ptTaeDetails.q79_oth_txt.trim() === ""
      ) {
        setHardErrList((prev) => ({
          ...prev,
          q79EmptyOtherTxt: true,
        }));
        isValid = false;
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q79EmptyOtherTxt: false,
        }));
      }



      //80
      let q80_Date = ParseDate(ptTaeDetails.q80_Date)
      if (ptTaeDetails.q80_lt) {
        if (ptTaeDetails.q80_lt === 1 && IsDateEmpty(ptTaeDetails.q80_Date)) {
          isValid = false;
          setHardErrList((prev) => ({
            ...prev,
            q80Empty: true,
          }));
        }
        else if(ptTaeDetails.q80_lt === 1 && !IsDateEmpty(ptTaeDetails.q80_Date) && IsDateValid(ptTaeDetails.q80_Date) && !IsMonthUnk(ptTaeDetails.q80_Date) &&
        !IsMonthUnk(ptTaeDetails.indexDate) &&
        !(IsAfterIndex(ptTaeDetails.q80_Date, ptTaeDetails.indexDate)))
        {
         
          setHardErrList((prev) => ({
            ...prev,
            q80DateValidate: true,
          }));
          isValid = false;
        }
        }

      if (ptTaeDetails.q80_lt === null) {
        setHardErrList((prev) => ({
          ...prev,
          q80Empty: true,
        }));
        isValid = false;
      }
      // else {
      //   setHardErrList((prev) => ({
      //     ...prev,
      //     q80Empty: false,
      //   }));
      // }
    }

    let tmt_parent_empty = false;
    let tmt_parent_othertext_empty = false;
    let tmt_parent_othertext2_empty=false;

if(!ptTaeDetails.treatmentTypeUnk && 
treatmentTypes?.every((a)=>!a.isSelected))
{
  tmt_parent_empty = true;
}

if(!tmt_parent_empty){
const tmtTypeoth = treatmentTypes?.find((a)=>(a.treatmenttypeid === TreatmentType.Oth_Other )&& a.isSelected && isStringEmpty(a.otherText))
if(tmtTypeoth)
{
  tmt_parent_othertext_empty = true;
}


const tmtTypeoth2 = treatmentTypes?.find((a)=>(a.treatmenttypeid === TreatmentType.Corti_Other)&& a.isSelected && isStringEmpty(a.otherText))
if(tmtTypeoth2)
{
  tmt_parent_othertext2_empty = true;
}

}



if (tmt_parent_empty || tmt_parent_othertext_empty || tmt_parent_othertext2_empty){
isValid = false;
}

setHardErrList((prev) => ({
...prev,
tmt_parent_empty,
tmt_parent_othertext_empty,
tmt_parent_othertext2_empty,
}));


debugger
if (treatmentTypes && treatmentTypes.length > 0) {
const updatedTreatmentTypes = treatmentTypes.map((treatmentType) => {
  let tmt_sub_empty = false;

  if (
    treatmentType.subTreatmentTypes.length  > 0
  ) {
    tmt_sub_empty =
    treatmentType.isSelected &&
    treatmentType.subTreatmentTypes?.length > 0 &&
    treatmentType.subTreatmentTypes.every((a) => !a.isSelected);
  }


  if (tmt_sub_empty ) {
    isValid = false;
  }
  return {
    ...treatmentType,
    tmt_sub_empty,
  };
});

setTretamentTypes(updatedTreatmentTypes);
}




    let q81_Date = ParseDate(ptTaeDetails.q81_Date)
    if (ptTaeDetails.q81_anti) {
      //validation for 81
      if (ptTaeDetails.q81_anti === 1) {
        if (
          IsDateValid(ptTaeDetails.q81_Date) &&
          IsDateValid(ptTaeDetails.indexDate) &&
          !IsDateEmpty(ptTaeDetails.q81_Date) && !IsMonthUnk(ptTaeDetails.q81_Date) && 
          !IsMonthUnk(ptTaeDetails.indexDate)
        ) {
          const validDate = IsAfterIndex(
            q81_Date,
            ptTaeDetails.indexDate
          )
          if (!validDate) {
            // Check if the entered date is less than the index date
            setHardErrList((prev) => ({
              ...prev,
              q81DateValid: true,
            }));
            isValid = false;
          }
        }
        // else {
        //   setHardErrList((prev) => ({
        //     ...prev,
        //     q81Empty: true,
        //   }));
        // }
      }
    }

    if (ptTaeDetails.q81_anti === null) {
      setHardErrList((prev) => ({
        ...prev,
        q81Empty: true,
      }));
      isValid = false;
    }

    // validation for q82
    if (ptTaeDetails.q82_IPI === null) {
      setHardErrList((prev) => ({
        ...prev,
        q82Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q82Empty: false,
      }));
    }

    //validation for 83
    //  debugger
    //   if (ptTaeDetails.q82_IPI  !== 99) {
    //     if (ptTaeDetails.q83_IPI === 1 && IsDateEmpty(ptTaeDetails.q83_Date) ) {
    //       isValid = false;
    //       setHardErrList((prev) => ({
    //         ...prev,
    //         q83Empty: true,
    //       }));
    //     }
    //   } else if (ptTaeDetails.q83_IPI === null) {
    //     setHardErrList((prev) => ({
    //       ...prev,
    //       q83Empty: true,
    //     }));
    //     isValid = false;
    //   } else {
    //     setHardErrList((prev) => ({
    //       ...prev,
    //       q83Empty: false,
    //     }));
    //   }

    let q83_Date = ParseDate(ptTaeDetails.q83_Date)
    if (ptTaeDetails.q82_IPI !== 99) {
      if (!ptTaeDetails.q83_IPI) {
        isValid = false;
        setHardErrList((prev) => ({
          ...prev,
          q83Empty: true,
        }));
      } else if (ptTaeDetails.q83_IPI === 1) {
              if (IsDateEmpty(ptTaeDetails.q83_Date)) {
                isValid = false;
                setHardErrList((prev) => ({
                  ...prev,
                  q83Empty: true,
                }));
              }
              else if(!IsDateEmpty(ptTaeDetails.q83_Date) && IsDateValid(ptTaeDetails.q83_Date) &&  !IsMonthUnk(ptTaeDetails.q83_Date) &&  !IsMonthUnk(ptTaeDetails.indexDate) && !(IsAfterIndex(q83_Date,ptTaeDetails.indexDate))){
                isValid = false;
                setHardErrList((prev) => ({
                  ...prev,
                  q83DateValidate: true,
                }));   
              }
        else if (!IsDateValid(q83_Date) && !IsMonthUnk(ptTaeDetails.q83_Date)) {
          isValid = false;
        }
      }
    }

    //validation for 84
    let q84_Date = ParseDate(ptTaeDetails.q84_Date)
    if (ptTaeDetails.q84_PD) {
      if (
        !IsDateEmpty(ptTaeDetails.q84_Date) &&
        IsDateValid(ptTaeDetails.q84_Date) &&
        !IsDateEmpty(ptTaeDetails.q81_Date)
        && !IsMonthUnk(ptTaeDetails.q84_Date) && 
        !IsMonthUnk(ptTaeDetails.q81_Date) 
      ) {
        const validDate = IsAfterIndex(
          ptTaeDetails.q84_Date,
          ptTaeDetails.q81_Date
        ) ;
        if (!validDate) {
          // Check if the entered date is less than the index date
          setHardErrList((prev) => ({
            ...prev,
            q84DateValid: true,
          }));
          isValid = false;
        }
      }
    }
    if (ptTaeDetails.q84_PD === null) {
      setHardErrList((prev) => ({
        ...prev,
        q84Empty: true,
      }));
      isValid = false;
    }

    // else {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     q84Empty: false,
    //   }));
    // }

    // else {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     q84Empty: true,
    //   }));
    //   isValid = false;
    // }

    //Repeater

    if (
      radiologicalAssessmentsDetails &&
      radiologicalAssessmentsDetails.length > 0
    ) {
      const updatedArray = radiologicalAssessmentsDetails.map((item) => {
        let q85Unk_date = ParseDate(item.q85Unk_date)
        let radiologyDxEmptyHard = false;
        if (
          item.q85RepeaterUnk == null ||
          (item.q85RepeaterUnk === 1 && IsDateEmpty(item.q85Unk_date))
        ) {
          radiologyDxEmptyHard = true;
        }
        //85

        let q85afterindexEmptyHard = false;

        if (
          IsDateValid(item.q85Unk_date) &&
          IsDateValid(ptTaeDetails.indexDate)
          && !IsMonthUnk(item.q85Unk_date)
          
        ) {
          q85afterindexEmptyHard =
            !IsDateEmpty(item.q85Unk_date) &&
            !IsDateEmpty(ptTaeDetails.indexDate) &&
            !IsMonthUnk(item.q85Unk_date)  &&
            !IsMonthUnk(ptTaeDetails.indexDate) &&
            !IsAfterIndex(item.q85Unk_date, ptTaeDetails.indexDate)           
        }
        let q85beforeQ84dateEmptyHard = false;
        if (
          IsDateValid(item.q85Unk_date) &&
          IsDateValid(ptTaeDetails.q81_Date)
          // && !IsMonthUnk(item.q85Unk_date)
        ) {
          q85beforeQ84dateEmptyHard =
            !IsDateEmpty(item.q85Unk_date) &&
            !IsDateEmpty(ptTaeDetails.q84_Date) &&
             !IsMonthUnk(item.q85Unk_date) &&
             !IsMonthUnk(ptTaeDetails.q84_Date)&&
            !Is_DateBefore(q85Unk_date, ptTaeDetails.q84_Date)           
        }
        //86
        const q86StatusEmptyHard = item.q86LBLC == null;
        const q86lbclTxtEmptyHard = q86StatusEmptyHard
          ? false
          : item.q86LBLC === 5 && isStringEmpty(item.q86_Other_txt);

        //87
        const q87statusEmptyHard = item.q87LyricLugano == null;
        //88
        const q88LyricEmptyHard =
          (item.q87LyricLugano === 1 || item.q87LyricLugano === 3) &&
          item.q88LyricCriteria === null
            ? true
            : false;

        //89
        const q89radiologyTypeEmptyHard = item.q89Unk == null ? true : false;
        const q89radiologyTypeTxtEmptyHard = q89radiologyTypeEmptyHard
          ? false
          : item.q89Unk === 5 && isStringEmpty(item.q89_Other_txt);

        if (
          radiologyDxEmptyHard ||
          (item.q85RepeaterUnk == 1 && !IsDateValid(q85Unk_date) && !IsMonthUnk(item.q85Unk_date)) ||
          q86StatusEmptyHard ||
          q86lbclTxtEmptyHard ||
          q87statusEmptyHard ||
          q88LyricEmptyHard ||
          q89radiologyTypeEmptyHard ||
          q89radiologyTypeTxtEmptyHard ||
          q85afterindexEmptyHard ||
          q85beforeQ84dateEmptyHard
        ) {
          isValid = false;
        }

        return {
          ...item,
          q85Soft: false,
          q85EmptyHard: radiologyDxEmptyHard,

          q86Soft: false,
          q86EmptyHard: q86StatusEmptyHard,
          q86TxtEmptyHard: q86lbclTxtEmptyHard,
          q87EmptyHard: q87statusEmptyHard,

          q88EmptyHard: q88LyricEmptyHard,

          q89Soft: false,
          q89EmptyHard: q89radiologyTypeEmptyHard,
          q89TxtEmptyHard: q89radiologyTypeTxtEmptyHard,

          q85afterindexEmptyHard: q85afterindexEmptyHard,
          q85beforeQ84dateEmptyHard: q85beforeQ84dateEmptyHard,
          diseaseStageCriteriaSoft: false,
        };
      });
      setRadiologicalAssessmentsDetails(updatedArray);
    }
    // validation for q91
    if (ptTaeDetails.q91_lof === null) {
      setHardErrList((prev) => ({
        ...prev,
        q91Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q91Empty: false,
      }));
    }

    if (
      tocilizumabChecked &&
      doseOfTocilizumabDetails &&
      doseOfTocilizumabDetails.length > 0
    ) {
      const updatedArray = doseOfTocilizumabDetails.map((item) => {
        const siteOfCare_rd_empty = item.siteOfCare_rd === null;
        const siteOfCare_other_empty = siteOfCare_rd_empty
          ? false
          : item.siteOfCare_rd === 4 && isStringEmpty(item.siteOfCare_other);

        if (siteOfCare_rd_empty || siteOfCare_other_empty) {
          isValid = false;
        }
        return {
          ...item,
          siteOfCare_rd_empty,
          siteOfCare_other_empty,
        };
      });
      setDoseOfTocilizumabDetails(updatedArray);
    }

    // if (
    //   ptTaeDetails.dosesOfTocilizumabNumOfDoses == null &&
    //   tocilizumabChecked
    // ) {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     dosesOfTocilizumabNumOfDoses_Empty: true,
    //   }));
    //   isValid = false;
    // }

    //Q98. checkbox Validations

    //Q98. checkbox validations

    // const isParentEmpty = Object.keys(selectedOptions.parent).length === 0;
    // const allFalse = Object.values(selectedOptions.parent).every(
    //   (value) => value === false
    // );
    // const isSubEmpty =
    //   selectedOptions.sub[7] &&
    //   Object.keys(selectedOptions.sub[7]).length === 0;
    // const hasUncheckedSubForSeven =
    //   selectedOptions.parent[7] &&
    //   selectedOptions.sub[7] &&
    //   Object.values(selectedOptions.sub[7]).every((subChecked) => !subChecked);

    // const subsubDexaEmpty =
    //   selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone] &&
    //   Object.keys(
    //     selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone]
    //   )?.length === 0;
    // const subsubDexaFalse =
    //   selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone] &&
    //   Object.keys(
    //     selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone]
    //   )?.length > 0 &&
    //   Object.values(
    //     selectedOptions.subSub[SupportiveCareEpcoritamab.Dexamethasone]
    //   ).every((value) => value === false);

    // const subsubHydroEmpty =
    //   selectedOptions.subSub[SupportiveCareEpcoritamab.Hydrocortisone] &&
    //   Object.keys(
    //     selectedOptions.subSub[SupportiveCareEpcoritamab.Hydrocortisone]
    //   )?.length === 0;
    // const Hydrocortisone =
    //   selectedOptions.subSub[SupportiveCareEpcoritamab.Hydrocortisone];
    // const subsubHydroFalse =
    //   Hydrocortisone &&
    //   Object.keys(Hydrocortisone)?.length > 0 &&
    //   Object.values(Hydrocortisone).every((value) => value === false);

    // const subsubPrednisoneEmpty =
    //   selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone] &&
    //   Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone])
    //     ?.length === 0;
    // const subsubPrednisoneFalse =
    //   selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone] &&
    //   Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone])
    //     ?.length > 0 &&
    //   Object.values(
    //     selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisone]
    //   ).every((value) => value === false);

    // const subsubPrednisoloneEmpty =
    //   selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone] &&
    //   Object.keys(
    //     selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone]
    //   )?.length === 0;
    // const subsubPrednisoloneFalse =
    //   selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone] &&
    //   Object.keys(
    //     selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone]
    //   )?.length > 0 &&
    //   Object.values(
    //     selectedOptions.subSub[SupportiveCareEpcoritamab.Prednisolone]
    //   ).every((value) => value === false);

    // const subsubMethylEmpty =
    //   selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone] &&
    //   Object.keys(
    //     selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone]
    //   )?.length === 0;
    // const subsubMethylFalse =
    //   selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone] &&
    //   Object.keys(
    //     selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone]
    //   )?.length > 0 &&
    //   Object.values(
    //     selectedOptions.subSub[SupportiveCareEpcoritamab.Methylprednisolone]
    //   ).every((value) => value === false);

    // const subsubOtherEmpty =
    //   selectedOptions.subSub[SupportiveCareEpcoritamab.Other] &&
    //   Object.keys(selectedOptions.subSub[SupportiveCareEpcoritamab.Other])
    //     ?.length === 0;

    // const subOtherTextIsEmpty =
    //   selectedOptions.sub &&
    //   selectedOptions.sub[7] &&
    //   selectedOptions.sub[7][6];

    // if (isParentEmpty && radioSelection === "") {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     selectedOptions_Empty: true,
    //   }));
    //   isValid = false;
    // }

    // if (selectedOptions.parent[8] && otherInputs.parent === "") {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     epco_parent_othertext_empty: true,
    //   }));
    //   isValid = false;
    // }

    // if (allFalse && radioSelection === "") {
    //   isValid = false;
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     selectedOptions_Empty: true,
    //   }));
    // }

    // if (!allFalse && !isParentEmpty && selectedOptions.parent[7]) {
    //   if (isSubEmpty || hasUncheckedSubForSeven) {
    //     isValid = false;
    //     setHardErrList((prev) => ({
    //       ...prev,
    //       selectedOptions_Sub_Empty: true,
    //     }));
    //   }
    // }

    // if (subsubDexaEmpty || subsubDexaFalse) {
    //   isValid = false;
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     [SupportiveCareEpcoritamab.Dexamethasone]: true,
    //   }));
    // }

    // if (subsubHydroEmpty || subsubHydroFalse) {
    //   isValid = false;
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     [SupportiveCareEpcoritamab.Hydrocortisone]: true,
    //   }));
    // }
    // if (subsubPrednisoneEmpty || subsubPrednisoneFalse) {
    //   isValid = false;
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     [SupportiveCareEpcoritamab.Prednisone]: true,
    //   }));
    // }

    // if (subsubPrednisoloneEmpty || subsubPrednisoloneFalse) {
    //   isValid = false;
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     [SupportiveCareEpcoritamab.Prednisolone]: true,
    //   }));
    // }

    // if (subsubMethylEmpty || subsubMethylFalse) {
    //   isValid = false;
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     [SupportiveCareEpcoritamab.Methylprednisolone]: true,
    //   }));
    // }

    // if (subOtherTextIsEmpty && otherInputs.sub === "") {
    //   isValid = false;
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     epco_sub_othertext_empty: true,
    //   }));
    // }

debugger

    //Q51
let epco_parent_empty = false;
let epco_parent_othertext_empty = false;
debugger
if (!ptTaeDetails.medicationNone && !ptTaeDetails.medicationUnk && medicationTypes?.every(a => !a.isSelected)) {
  epco_parent_empty = true;
}
if (!epco_parent_empty) {
  const medTypeOth = medicationTypes?.find(a => a.medicationTypeID === MedicationType.Other && a.isSelected)
  if(medTypeOth && isStringEmpty(medTypeOth.medicationTypeOther)){
    epco_parent_othertext_empty = true
  }
}
if(epco_parent_empty || epco_parent_othertext_empty){
  isValid = false;
}
setHardErrList((prev) => ({
  ...prev,
  epco_parent_empty,
  epco_parent_othertext_empty,
}));
//52 to 55 rpt n 51 sub n subsub val.
if (medicationTypes && medicationTypes.length > 0) {
  const updatedMedicationTypes = medicationTypes.map((medicationType) => {
    let epco_sub_empty = false;
    let epco_sub_oth_txt_empty = false;
    let epco_sub_sub_empty = false;
    if(medicationType.subMedicationTypes && medicationType.subMedicationTypes.length>0) {
      epco_sub_empty = medicationType.isSelected && medicationType.subMedicationTypes?.length>0 && medicationType.subMedicationTypes.every(a => !a.isSelected);
    }
    if (!epco_sub_empty) {
      const subMedTypeOth = medicationType.subMedicationTypes?.find(a => a.subMedicationTypeID === SupportiveCareEpcoritamab.Other && a.isSelected)
      if(subMedTypeOth && isStringEmpty(subMedTypeOth.subMedicationTypeOther)){
        epco_sub_oth_txt_empty = true
      }
    }
    const updatedSubMedTypes = medicationType.subMedicationTypes.map((subMedicationType) => {

      epco_sub_sub_empty = subMedicationType.isSelected && subMedicationType.subSubMedicationTypes?.length>0&& subMedicationType.subSubMedicationTypes.every(a => !a.isSelected);
    
      const updatedSubSubMedTypes = subMedicationType.subSubMedicationTypes.map((subSubMedType) => {
        
       

        return {
          ...subSubMedType,
        }; 
      });
      if(epco_sub_sub_empty) {
        isValid = false;
      }
      return {
        ...subMedicationType,
        epco_sub_sub_empty,
        subSubMedicationTypes: updatedSubSubMedTypes,
      };
    });
    if(epco_sub_empty || epco_sub_oth_txt_empty) {
      isValid = false;
    }
    return {
      ...medicationType,
      epco_sub_empty,
      epco_sub_oth_txt_empty,
      subMedicationTypes: updatedSubMedTypes,
    };
  });

  setMedicationTypes(updatedMedicationTypes);
}

    return isValid;
  };

  const HandleDateChange = (name, date, datePart) => {
    switch (name) {
      case "q73Date": {
        setPtTaeDetails((prev) => ({
          ...prev,
          q73_apheresis: 1,
          q73_Date: SetDateState(prev.q73_Date, date, datePart),
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q73Unk_err: null,
        }));
        setHardErrList((prev) => ({
          ...prev,
          q73Empty: false,
          q73DateValid: false,
        }));
        break;
      }

      case "q76Date": {
        setPtTaeDetails((prev) => ({
          ...prev,
          q76_BT: 1,
          q76_Date: SetDateState(prev.q76_Date, date, datePart),
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q76Unk_err: null,
        }));
        setHardErrList((prev) => ({
          ...prev,
          q76Empty: false,
          q76DateValidate:false,
        }));
        break;
      }

      case "q80Date": {
        setPtTaeDetails((prev) => ({
          ...prev,
          q80_lt: 1,
          q80_Date: SetDateState(prev.q80_Date, date, datePart),
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q80Unk_err: null,
        }));
        setHardErrList((prev) => ({
          ...prev,
          q80Empty: false,
          q80DateValidate:false,
        }));
        break;
      }

      case "q81_date": {
        setPtTaeDetails((prev) => ({
          ...prev,
          q81_anti: 1,
          q81_Date: SetDateState(prev.q81_Date, date, datePart),
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q81Unk_err: null,
        }));
        setHardErrList((prev) => ({
          ...prev,
          q81Empty: false,
          q81DateValid:false,
        }));
        break;
      }

      case "q83Date": {
        setPtTaeDetails((prev) => ({
          ...prev,
          q83_IPI: 1,
          q83_Date: SetDateState(prev.q83_Date, date, datePart),
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q83Unk_err: null,
        }));
        setHardErrList((prev) => ({
          ...prev,
          q83Empty: false,
          q83DateValidate:false,
        }));
        break;
      }
      case "q84Date": {
        setPtTaeDetails((prev) => ({
          ...prev,
          q84_PD: 1,
          q84_Date: SetDateState(prev.q84_Date, date, datePart),
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q84Unk_err: null,
        }));
        setHardErrList((prev) => ({
          ...prev,
          q84Empty: false,
          q84DateValid: false,
        }));
        break;
      }
      default: {
        // Handle unexpected name values if necessary
        console.warn(`Unhandled date change for name: ${name}`);
        break;
      }
    }
  };

  //const [selectedLOT, setSelectedLOT] = useState(null);

  const HandleFieldChange = (e) => {
    const { name, id, value, type, checked } = e.target;
    switch (name) {
      case "q70_Tae_therapy": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          // q70_Tae_therapy_txt: "",
          selectedLOT: null,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q70Unk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q70Empty: false,
          //  q70EmptyOtherTxt: false,
          q70LOTNull: false,
        }));

        break;
      }

      case "selectedLOT": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          q70_Tae_therapy: 1,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q70Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q70Empty: false,
        }));
        break;
      }


      case "treatmentTypeUnk": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          treatmentTypeUnkSoft:value
        }));

        setSoftErrList((prev) => ({
          ...prev,
          tmt_prior_parent_unknown: true,
        }));

        setHardErrList((prev) => ({
          ...prev,
          tmt_parent_empty:false,
          tmt_parent_othertext_empty:false,
          tmt_parent_othertext2_empty:false,
        }));
        


        const updatedArray = treatmentTypes.map((medType) => ({
          ...medType,
          isSelected: false,
          otherText: "",
          subTreatmentTypes: medType.subTreatmentTypes.map((subMed) => ({
            ...subMed,
            isSelected: false,
            
          })),
        }));
        setTretamentTypes(updatedArray);


        selectedReceivedTmts.clear();
        setSelectedReceivedTmts(new Map());
       
        const selTmtStr = DisplaySelectedText(new Map());
        setSelectedReceivedTmtsStr(SelReceivedTmtsDefaultText);
        break;
      }

      // case "q70_Tae_therapy_txt": {
      //   setPtTaeDetails((prev) => ({
      //     ...prev,
      //     [name]: value,
      //     q70_Tae_therapy: 2,
      //     setSelectedLOT: null,
      //   }));

      // setHardErrList((prev) => ({
      //   ...prev,
      //   q70EmptyOtherTxt: false,
      // }));

      //  break;
      // }

      case "q70select_LOT": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: value,
          q70_Tae_therapy: 1,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q70Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q70Empty: false,
        }));
        break;
      }

      case "q71_TmtReceived_1":
      case "q71_TmtReceived_2":
      case "q71_TmtReceived_3":
      case "q71_TmtReceived_4":
      case "q71_TmtReceived_5":
      case "q71_TmtReceived_6":
      case "q71_TmtReceived_7":
      case "q71_TmtReceived_8":
      case "q71_TmtReceived_9":
      case "q71_TmtReceived_10":
      case "q71_TmtReceived_11":
      case "q71_TmtReceived_12":
      case "q71_TmtReceived_13":
      case "q71_TmtReceived_14":
      case "q71_TmtReceived_15":
      case "q71_TmtReceived_16":
      case "q71_TmtReceived_17":
      case "q71_TmtReceived_18":
      case "q71_TmtReceived_oth":
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          ...(name === "q71_TmtReceived_oth" && {
            q71_TmtReceived_oth_txt: "",
          }),
          ...(name === "q71_TmtReceived_6" && {
            q72CarT_1: null,
            q72CarT_2: null,
            q72CarT_oth_txt: "",
            q72CarT_3: null,
            q72CarT_4: null,
            q72CarT_5: null,
            q72CarT_6: null,
            q72CarT_oth: null,

            q73_Date: DefaultDate,
            q73_apheresis: null,
            q74_bridge: null,
            q78_lt: null,
          }),
        }));
        const newPtTaeDetails = {
          ...ptTaeDetails,
          [name]: type === "checkbox" ? checked : value,
        };

        

        setSoftErrList((prev) => ({
          ...prev,
          q72EmptyOtherTxt: false,
          q73Unk_err: false,
          q74Unk_err: false,
          q78Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q71Empty: false,
          q71EmptyOtherTxt: false,
          q72Empty: false,
          q72EmptyOtherTxt: false,
          q73Empty: false,
          q73DateValid: false,
          q74Empty: false,
          q78Empty: false,
        }));

        break;

      case "q71_TmtReceived_oth_txt": {
        const newPtTaeDetails = {
          ...ptTaeDetails,
          [name]: type === "text" ? checked : true,
          text: value,
        };
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: value,
          q71_TmtReceived_oth: true,
        }));

        // updateSelectedLabels(newPtTaeDetails);

        setHardErrList((prev) => ({
          ...prev,
          q71Empty: false,
          q71EmptyOtherTxt: false,
        }));

        break;
      }

      case "q72CarT_1":
      case "q72CarT_2":
      case "q72CarT_3":
      case "q72CarT_4":
      case "q72CarT_5":
      case "q72CarT_6":
      case "q72CarT_oth":
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          ...(name === "q72CarT_oth" && { q72CarT_oth_txt: "" }),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q72Empty: false,
          q72EmptyOtherTxt: false,
        }));
        break;

      case "q72CarT_oth_txt": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: value,
          q72CarT_oth: true,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q72Empty: false,
          q72EmptyOtherTxt: false,
        }));

        break;
      }

      case "q73_apheresis": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,

          q73_Date: DefaultDate,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q73Unk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q73Empty: false,
          q73DateValid: false,
        }));

        break;
      }

      case "q74_bridge": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,

          q75_BT_1: null,
          q75_BT_2: null,
          q75_BT_3: null,
          q75_BT_4: null,
          q75_BT_5: null,
          q75_BT_6: null,
          q75_BT_7: null,
          q75_BT_8: null,
          q75_BT_9: null,
          q75_BT_10: null,
          q75_BT_11: null,
          q75_BT_12: null,
          q75_BT_13: null,
          q75_BT_14: null,
          q75_BT_15: null,
          q75_BT_16: null,
          q75_BT_17: null,
          q75_BT_18: null,
          q75_BT_19: null,
          q75_BT_oth: null,
          q75_BT_oth_txt: "",
          q75_BT_Unk: null,

          q76_BT: null,
          q76_Date: DefaultDate,
          q77_BT_Init: null,
          q77_BT_Init_num: null,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q74Unk_err: parseInt(value) === 99,
          q75Unk_err: false,
          q76Unk_err: false,
          q77Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q74Empty: false,
          q75Empty: false,
          q75EmptyOtherTxt: false,
          q76Empty: false,
          q76DateValidate:false,
          q77Empty: false,
          q77SizeOutOfRange: false,
        }));

        break;
      }

      case "q75_BT_1":
      case "q75_BT_2":
      case "q75_BT_3":
      case "q75_BT_4":
      case "q75_BT_5":
      case "q75_BT_6":
      case "q75_BT_7":
      case "q75_BT_8":
      case "q75_BT_9":
      case "q75_BT_10":
      case "q75_BT_11":
      case "q75_BT_12":
      case "q75_BT_13":
      case "q75_BT_14":
      case "q75_BT_15":
      case "q75_BT_16":
      case "q75_BT_17":
      case "q75_BT_18":
      case "q75_BT_19":
      case "q75_BT_oth":
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          q75_BT_Unk: false,
          ...(name === "q75_BT_oth" && { q75_BT_oth_txt: "" }),
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q75Unk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q75Empty: false,
          q75EmptyOtherTxt: false,
        }));

        break;

      case "q75_BT_oth_txt": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: value,
          q75_BT_oth: true,
          q75_BT_Unk: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q75Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q75Empty: false,
          q75EmptyOtherTxt: false,
        }));

        break;
      }

      case "q75_BT_Unk": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          q75_BT_1: false,
          q75_BT_2: false,
          q75_BT_3: false,
          q75_BT_4: false,
          q75_BT_5: false,
          q75_BT_6: false,
          q75_BT_7: false,
          q75_BT_8: false,
          q75_BT_9: false,
          q75_BT_10: false,
          q75_BT_11: false,
          q75_BT_12: false,
          q75_BT_13: false,
          q75_BT_14: false,
          q75_BT_15: false,
          q75_BT_16: false,
          q75_BT_17: false,
          q75_BT_18: false,
          q75_BT_19: false,
          q75_BT_oth: false,
          q75_BT_oth_txt: "",
          q75EmptyOtherTxt: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q75Unk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q75Empty: false,
          q75EmptyOtherTxt: false,
        }));

        break;
      }

      //validation 76
      case "q76_BT": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q76_Date: DefaultDate,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q76Unk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q76Empty: false,
          q76DateValidate:false,
        }));

        break;
      }

      case "q77_BT_Init": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q77_BT_Init_num: false,
          q77_BT_Init_num: parseInt(value) === 1 ? prev.q77_BT_Init_num : "",
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q77Unk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q77Empty: false,
          q77SizeOutOfRange: false,
        }));

        break;
      }

      case "q77_BT_Init_num": {
        if (value !== "" && !AllowOnlyIntegers(value)) {
          break;
        }
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: value,
          q77_BT_Init: 1,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q77Unk_err: false,
        }));

        break;
      }

      case "q78_lt": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q79_therapies_1: false,
          q79_therapies_2: false,
          q79_therapies_3: false,
          q79_Unk: null,
          q79_oth_txt: "",
          q80_Date: DefaultDate,
          q80_lt: null,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q78Unk_err: parseInt(value) === 99,
          q79Unk_err: false,
          q80Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q78Empty: false,
          q79Empty: false,
          q80Empty: false,
          q80DateValidate:false,
          q79EmptyOtherTxt: false,
        }));

        break;
      }

      case "q79_therapies_1":
      case "q79_therapies_2":
      case "q79_therapies_3": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          q79_Unk: false,
          ...(name === "q79_therapies_3" && { q79_oth_txt: "" }),
        }));
        setSoftErrList((prev) => ({
          ...prev,
          q79Unk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q79Empty: false,
          q79EmptyOtherTxt: false,
        }));
        break;
      }

      case "q79_oth_txt": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: value,
          q79_therapies_3: true,
          q79_Unk: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q79Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q79Empty: false,
          q79EmptyOtherTxt: false,
        }));
        break;
      }

      case "q79_Unk": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          q79_therapies_1: false,
          q79_therapies_2: false,
          q79_therapies_3: false,
          q79_oth_txt: "",
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q79Unk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q79Empty: false,
          q79EmptyOtherTxt: false,
        }));

        break;
      }

      case "q80_lt": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q80_Date: DefaultDate,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q80Unk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q80Empty: false,
          q80DateValidate:false,
        }));

        break;
      }

      case "q81_anti": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q81_Date: DefaultDate,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          q81Unk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q81Empty: false,
          q81DateValid:false,
        }));

        break;
      }

      case "q82_IPI": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          ...(Number(value) === 99 && { q83_IPI: null }),
          ...(Number(value) === 99 && { q83_Date: DefaultDate }),
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q82Unk_err: parseInt(value) === 99,
          ...(Number(value) === 99 && { q83Unk_err: false }),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q82Empty: false,
          ...(Number(value) === 99 && { q83Empty: false }),
          q83DateValidate:false,
        }));
        break;
      }

      case "q83_IPI": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q83_Date: DefaultDate,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q83Unk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q83Empty: false,
          q83DateValidate:false,
        }));

        break;
      }

      case "q84_PD": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q84_Date: DefaultDate,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          q84Unk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q84Empty: false,
        }));

        break;
      }

      case "q85_RA": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
        }));
        break;
      }

      case "q86_LBCL_": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q86_LBCL_txt: "",
        }));
        break;
      }

      case "q86_LBCL_txt": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: value,
          q86_LBCL_: 5,
        }));

        break;
      }

      case "q87_stage": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
        }));
        break;
      }

      case "q88_lyric": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
        }));
        break;
      }

      case "q89_type": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q89_type_txt: "",
        }));
        break;
      }

      case "q89_type_txt": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: value,
          q89_type: 5,
        }));

        break;
      }

      case "q90_rat": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
        }));
        break;
      }

      case "q91_lof": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q91Unk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q91Empty: false,
        }));

        break;
      }

      case "dosesOfTocilizumabNumOfDoses": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
        }));
        setHardErrList((prev) => ({
          ...prev,
          dosesOfTocilizumabNumOfDoses_Empty: false,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          dosesOfTocilizumabNumOfDoses_unk: Number(value) === 99,
        }));

        setDoseOfTocilizumabDetails([
          {
            ...DoseOfTocilizumabRow,
          },
        ]);

        break;
      }



           
      case "medicationUnk": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: true,
          medicationNone: false,
        }));

        const updatedArray =
          medicationTypes.map(medType => ({
            ...medType,
            isSelected: false,
            medicationTypeOther: '',
            subMedicationTypes: medType.subMedicationTypes.map(subMed => ({
              ...subMed,
              isSelected: false, // Reset isSelected for subMedicationType
              subMedicationTypeOther: '',
              subSubMedicationTypes: subMed.subSubMedicationTypes.map(subSubMed => ({
                ...subSubMed,
                isSelected: false, // Reset isSelected for subSubMedicationType
                
              })),
            })),
          }));
        setMedicationTypes(updatedArray)

        setTocilizumabChecked(false)

        setPtTaeDetails((prev) => ({
          ...prev,
          dosesOfTocilizumabNumOfDoses: null
        }));

        setDoseOfTocilizumabDetails([
          {
            ...DoseOfTocilizumabRow,
          },
        ]);
        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: true,
          epco_doses_of_tocilizumab_unknown: false,
        }));
        setHardErrList((prev) => ({
          ...prev,
          epco_parent_empty: false,
          epco_parent_othertext_empty: false,
          dosesOfTocilizumabNumOfDoses_Empty:false
        }));

        setTocSelected(false);         

        break;
      }

      case "medicationNone": {
        setPtTaeDetails((prev) => ({
          ...prev,
          [name]: true,
          medicationUnk: false,
        }));

        const updatedArray =
          medicationTypes.map(medType => ({
            ...medType,
            isSelected: false,
            medicationTypeOther: '',
            subMedicationTypes: medType.subMedicationTypes.map(subMed => ({
              ...subMed,
              isSelected: false, // Reset isSelected for subMedicationType
              subMedicationTypeOther: '',
              subSubMedicationTypes: subMed.subSubMedicationTypes.map(subSubMed => ({
                ...subSubMed,
                isSelected: false, // Reset isSelected for subSubMedicationType
              })),
            })),
          }));
        setMedicationTypes(updatedArray)

        setTocilizumabChecked(false)

        setPtTaeDetails((prev) => ({
          ...prev,
          dosesOfTocilizumabNumOfDoses: null
        }));

        setDoseOfTocilizumabDetails([
          {
            ...DoseOfTocilizumabRow,
          },
        ]);

        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: false,
          epco_doses_of_tocilizumab_unknown: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          epco_parent_empty: false,
          epco_parent_othertext_empty: false,
          dosesOfTocilizumabNumOfDoses_Empty:false
        }));

        setTocSelected(false);         

        break;
      }

      default:
        break;
    }
  };



  const initialGlucocorticoidsRow = {
    epcoritamabsupportivecarebridgeid: 0,
    epcoritamabtreatmentMedicationtypeid: 0,
    medtypeid: 0,
    submedtyeid: 0,
    subsubmedtypeid: 0,
    name: "",
    supportive_careRd: null,
    supportive_care_date: DefaultDate,
    supportive_careRdEmpty: false,
    supportive_care_dtEmpty: false,    
    supportive_careUnkselect:false,
    supportive_care_dtAfterIndex:false,
    supportive_care_dtBefore_discnt_dt:false,

    dosage_quantityRd: null,
    dosage_quantity_txt: "",
    dosage_quantityRdEmpty: false,
    dosage_quantity_txtEmpty: false,
    dose_quantity_Unkselect:false,
    dose_quantityRange:false,
    dosefrequencyRd: null,
    dosefrequency_oth_txt: "",
    dosefrequencyRdEmpty: false,
    dosefrequency_oth_txtEmpty: false,
    dosefrequency_Unkselect:false,
    durationprescribedRd: null,
    durationprescribed_txt: "",
    durationprescribedRdEmpty: false,
    durationprescribed_txtEmpty: false,
    durationprescribedUnkselect:false,
  };




  
  const initialState = {
    treatmentMedicationBridgeId: null,
    PatientId: null,
    medicationTypeID: null,
    medicationTypeOther: '',
    isSelected: false,
    medication_none: null,
    medication_Unk: null,
        epco_sub_empty: false,//hard err
        epco_sub_oth_txt_empty: false,//hard err

    subMedicationTypes: [
      {
        subMedicationTypeID: null,
        name: '',
        isSelected: false,
        subMedicationTypeOther: '',
        epco_sub_sub_empty: false,//hard err
        subSubMedicationTypes: [
          {
            subSubMedicationTypeID: null,
            name: '',
            isSelected: false,
            // epcorsupportivecareDetails: [initialGlucocorticoidsRow],//remove initialGlucocorticoidsRow
            // epco_sub_sub_empty: false,//hard err
          },
        ],
      },
    ],
  };
  const [medicationTypes, setMedicationTypes] = useState([initialState]);



  const tmttypeinitialState = {
    lottreatmenttypebridgeid: null, //pk
    treatmenttypeid: null,
    subtreatmenttypeid:null,
    heading:'',
    otherText: '',
    isSelected: false,
    name:'',
// receivedTypes:[{
//   isSelected: false,
//   subTreatmentTypeID: null,
//   name: '',

tmt_sub_empty:false,

subTreatmentTypes: [
  {
    subSubMedicationTypeID: null,
    treatmentTypeID:null,
    name: '',
    // receivedType:'',
    isSelected: false,
  //  epcorsupportivecareDetails: [initialGlucocorticoidsRow]
  },
],
// }],
  };



const[treatmentTypes, setTretamentTypes] =  useState([tmttypeinitialState]);













  const [tocilizumabChecked, setTocilizumabChecked] = useState(false)
  const[tocSelected,setTocSelected] =useState(false);
  const handleInputChange = (
    e,
    medtypeid,
    name,
    idx,
    subindex,
    subsubindex,
    subMedTypeName,
    subMedicationTypeID
  ) => {
    const { id, value, checked } = e.target;
    let updatedArray = [...medicationTypes];
    const index = id.split("_").pop();

    debugger;
    switch (id) {
      case `medType_${name}_${index}`: {
        let epco_parent_othertext_empty =
          hardErrList.epco_parent_othertext_empty;

        updatedArray[idx].isSelected = !updatedArray[idx].isSelected;
        updatedArray[idx].epco_sub_empty = false;
        updatedArray[idx].epco_sub_oth_txt_empty = false;

        if (idx === 5 && updatedArray[idx].isSelected === true) {
          setTocSelected(true);
        } else {
          setTocSelected(false);
        }

        if (medtypeid === MedicationType.Glucocorticoids) {
          updatedArray[idx].subMedicationTypes = updatedArray[
            idx
          ].subMedicationTypes.map((subMed) => ({
            ...subMed,
            isSelected: false,
            subMedicationTypeOther: "",
            epco_sub_sub_empty: false,
            subSubMedicationTypes: subMed.subSubMedicationTypes.map(
              (subSubMed) => ({
                ...subSubMed,
                isSelected: false,
              })
            ),
          }));
        }

        if (medtypeid == MedicationType.Other) {
          updatedArray[idx] = {
            ...updatedArray[idx],
            medicationTypeOther: "",
          };
          epco_parent_othertext_empty = false;
        }

        if (medtypeid === MedicationType.Tocilizumab) {
          setTocilizumabChecked(updatedArray[idx].isSelected);
          setDoseOfTocilizumabDetails([
            {
              ...DoseOfTocilizumabRow,
            },
          ]);
        }
        // if (medtypeid === MedicationType.Tocilizumab && !updatedArray[idx].isSelected) {
        //   setPtTaeDetails((prev) => ({
        //     ...prev,
        //     dosesOfTocilizumabNumOfDoses: null,
        //   }));

        // setSoftErrList((prev) => ({
        //   ...prev,
        //   epco_doses_of_tocilizumab_unknown: false,
        // }));
        // }

        setPtTaeDetails((prev) => ({
          ...prev,
          medicationUnk: false,
          medicationNone: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: false,
        }));
        setHardErrList((prev) => ({
          ...prev,
          epco_parent_empty: false,
          epco_parent_othertext_empty,
        }));
        break;
      }

      case `medType_txt_${index}`: {
        updatedArray[idx].medicationTypeOther = value;
        updatedArray[idx].isSelected = true; //Boolean(value);

        setPtTaeDetails((prev) => ({
          ...prev,
          medicationUnk: false,
          medicationNone: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          epco_parent_empty: false,
          epco_parent_othertext_empty: false,
        }));
        break;
      }

      case `subMedType_${name}_${index}`: {
        // Update the sub-medication type at the specified subindex
        let innerSubMedArray = [...updatedArray[idx].subMedicationTypes];
        let epco_sub_oth_txt_empty = updatedArray[idx].epco_sub_oth_txt_empty;
        innerSubMedArray[subindex] = {
          ...innerSubMedArray[subindex],
          isSelected: !innerSubMedArray[subindex].isSelected,
          subSubMedicationTypes: innerSubMedArray[
            subindex
          ].subSubMedicationTypes.map((a) => ({
            ...a,
            isSelected: false,
          })),
          epco_sub_sub_empty: false,
        };
        if (subMedicationTypeID == SupportiveCareEpcoritamab.Other) {
          innerSubMedArray[subindex] = {
            ...innerSubMedArray[subindex],
            subMedicationTypeOther: "",
          };
          epco_sub_oth_txt_empty = false;
        }
        updatedArray[idx] = {
          ...updatedArray[idx],
          subMedicationTypes: innerSubMedArray,
          epco_sub_empty: false,
          epco_sub_oth_txt_empty,
        };
        setPtTaeDetails((prev) => ({
          ...prev,
          medicationUnk: false,
          medicationNone: false,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: false,
        }));
        break;
      }

      case `subMedType_txt_${index}`: {
        // Update the SubMedicationTypeOther for a specific sub-medication type
        let innerSubMedArray = [...updatedArray[idx].subMedicationTypes];
        innerSubMedArray[subindex] = {
          ...innerSubMedArray[subindex],
          subMedicationTypeOther: value,
          isSelected: true, //Boolean(value)
        };
        updatedArray[idx] = {
          ...updatedArray[idx],
          subMedicationTypes: innerSubMedArray,
          epco_sub_empty: false,
          epco_sub_oth_txt_empty: false,
        };
        setPtTaeDetails((prev) => ({
          ...prev,
          medicationUnk: false,
          medicationNone: false,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: false,
        }));
        break;
      }

      case `subSubMedType_${name}_${index}`: {
        // Update the sub-sub-medication type at the specified subsubindex within a specific sub-medication
        let innerSubMedArray = [...updatedArray[idx].subMedicationTypes];
        let innerSubSubMedArray = [
          ...innerSubMedArray[subindex].subSubMedicationTypes,
        ];

        let epcorsupportivecareDetails = [];
        if (checked) {
          epcorsupportivecareDetails = [{ ...initialGlucocorticoidsRow }];
        }
        innerSubSubMedArray[subsubindex] = {
          ...innerSubSubMedArray[subsubindex],
          isSelected: !innerSubSubMedArray[subsubindex].isSelected,
          epcorsupportivecareDetails,
        };
        innerSubMedArray[subindex] = {
          ...innerSubMedArray[subindex],
          subSubMedicationTypes: innerSubSubMedArray,
          epco_sub_sub_empty: false,
        };
        updatedArray[idx] = {
          ...updatedArray[idx],
          subMedicationTypes: innerSubMedArray,
          // epcorsupportivecareDetails:medicationData
        };

        setPtTaeDetails((prev) => ({
          ...prev,
          medicationUnk: false,
          medicationNone: false,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: false,
        }));

        break;
      }

      default:
        break;
    }

    setMedicationTypes(updatedArray);
  };



  const handleTreatmentTypeFieldChange = (
    e, tmtTypeid, name, idx,subindex
  ) => {
      const { id, value, checked } = e.target;
    let updatedTreatmentTypes = [...treatmentTypes];
    const index = id.split('_').pop();
  debugger
    switch (id) {
      case `treatmentType_${name}_${index}`: {
       
        setPtTaeDetails((prev) => ({
          ...prev,
          treatmentTypeUnk:false
          
        }));

        updatedTreatmentTypes[idx]={
          ...updatedTreatmentTypes[idx],
          isSelected:!updatedTreatmentTypes[idx].isSelected,
          tmt_sub_empty:false,
          subTreatmentTypes:updatedTreatmentTypes[idx].subTreatmentTypes?.map((a) => ({
            ...a,
            isSelected: false,
          })),
        }
       
        if (updatedTreatmentTypes[idx].treatmenttypeid === TreatmentType.CAR_T ) {

          if(updatedTreatmentTypes[idx].isSelected ===true)
          {
            setChk_q49_car_t_cell_therapy(true);
          }
          else{

            setPtTaeDetails((prev) => ({
              ...prev,
              q72CarT_1: null,
              q72CarT_2: null,
              q72CarT_3: null,
              q72CarT_4: null,
              q72CarT_5: null,
              q72CarT_6: null,
              q72CarT_oth: null,
              q72CarT_oth_txt: "",
              q73_apheresis: null,
              q73_Date: DefaultDate,
              q73_Unk: null,
              q74_bridge: null,
            q78_lt: null,
            }));



            setSoftErrList((prev) => ({
              ...prev,
              q72EmptyOtherTxt: false,
              q73Unk_err: false,
              q74Unk_err: false,
              q78Unk_err: false,
            }));
    
            setHardErrList((prev) => ({
              ...prev,
              q71Empty: false,
              q71EmptyOtherTxt: false,
              q72Empty: false,
              q72EmptyOtherTxt: false,
              q73Empty: false,
              q73DateValid: false,
              q74Empty: false,
              q78Empty: false,
            }));



            setChk_q49_car_t_cell_therapy(false);
          }
        }


        if (updatedTreatmentTypes[idx].treatmenttypeid === TreatmentType.Oth_Other || updatedTreatmentTypes[idx].treatmenttypeid === TreatmentType.Corti_Other ) {

          if(updatedTreatmentTypes[idx].isSelected ===false)
          {
            updatedTreatmentTypes[idx].otherText =""
          }        
        }
        

        setSoftErrList((prev) => ({
          ...prev,
          tmt_prior_parent_unknown: false,
        }));

        setHardErrList((prev) => {
          debugger
          let tmt_parent_othertext_empty = prev.tmt_parent_othertext_empty; 
          if(!updatedTreatmentTypes[idx].isSelected  && updatedTreatmentTypes[idx].treatmenttypeid === TreatmentType.Oth_Other){
            tmt_parent_othertext_empty=false
          }
          let tmt_parent_othertext2_empty = prev.tmt_parent_othertext2_empty; 
          if(!updatedTreatmentTypes[idx].isSelected && updatedTreatmentTypes[idx].treatmenttypeid === TreatmentType.Corti_Other){
            tmt_parent_othertext2_empty=false
          }
          return {
          ...prev,
          tmt_parent_empty:false,
          tmt_parent_othertext_empty,
          tmt_parent_othertext2_empty
        }});

debugger

        if (updatedTreatmentTypes[idx].isSelected && !(updatedTreatmentTypes[idx].treatmenttypeid === TreatmentType.Oth_Other || updatedTreatmentTypes[idx].treatmenttypeid === TreatmentType.Corti_Other)) {
          // Add if checked
          setSelectedReceivedTmts(
            new Map(selectedReceivedTmts).set(Number(index), value)
          );
          const selTmtStr = DisplaySelectedText(
            new Map(selectedReceivedTmts).set(Number(index), value)
          );
          setSelectedReceivedTmtsStr(
            isStringEmpty(selTmtStr) ? SelReceivedTmtsDefaultText : selTmtStr
          );
        } else {
          // Remove if unchecked
          selectedReceivedTmts.delete(Number(index));
          setSelectedReceivedTmts(new Map(selectedReceivedTmts));
          const selTmtStr = DisplaySelectedText(
            new Map(selectedReceivedTmts)
          );
          setSelectedReceivedTmtsStr(
            isStringEmpty(selTmtStr) ? SelReceivedTmtsDefaultText : selTmtStr
          );
        }
        
        
        break;
      }

   
      case `subTreatmentType_${name}_${index}`: {
        let innerSubTmtArray = [...updatedTreatmentTypes[idx].subTreatmentTypes];
        innerSubTmtArray[subindex] = {
          ...innerSubTmtArray[subindex],
          isSelected: !innerSubTmtArray[subindex].isSelected,
        };
        updatedTreatmentTypes[idx] = {
          ...updatedTreatmentTypes[idx],
          subTreatmentTypes: innerSubTmtArray,
          tmt_sub_empty:false,
        };

        break;
      }


      case `other_treatmentType_${name}_${index}`: {
        updatedTreatmentTypes[idx].otherText = value;
        updatedTreatmentTypes[idx].isSelected = true;


        setPtTaeDetails((prev) => ({
          ...prev,
          treatmentTypeUnk:false
          
        }));


        setSoftErrList((prev) => ({
          ...prev,
          tmt_prior_parent_unknown: false,
        }));

        setHardErrList((prev) => {
          debugger
          let tmt_parent_othertext_empty = prev.tmt_parent_othertext_empty; 
          if(updatedTreatmentTypes[idx].treatmenttypeid === TreatmentType.Oth_Other && !isStringEmpty(updatedTreatmentTypes[idx].otherText)){
            tmt_parent_othertext_empty=false
          }
          let tmt_parent_othertext2_empty = prev.tmt_parent_othertext2_empty; 
          if(updatedTreatmentTypes[idx].treatmenttypeid === TreatmentType.Corti_Other && !isStringEmpty(updatedTreatmentTypes[idx].otherText)){
            tmt_parent_othertext2_empty=false
          }
          return {
          ...prev,
          tmt_parent_empty:false,
          tmt_parent_othertext_empty,
          tmt_parent_othertext2_empty
        }});
        


        if (!isStringEmpty(value)) {
          // Add if checked
          setSelectedReceivedTmts(
            new Map(selectedReceivedTmts).set(Number(index), value)
          );
          const selTmtStr = DisplaySelectedText(
            new Map(selectedReceivedTmts).set(Number(index), value)
          );
          setSelectedReceivedTmtsStr(
            isStringEmpty(selTmtStr) ? SelReceivedTmtsDefaultText : selTmtStr
          );
        } else {
          // Remove if unchecked
          selectedReceivedTmts.delete(Number(index));
          setSelectedReceivedTmts(new Map(selectedReceivedTmts));
          const selTmtStr = DisplaySelectedText(
            new Map(selectedReceivedTmts)
          );
          setSelectedReceivedTmtsStr(
            isStringEmpty(selTmtStr) ? SelReceivedTmtsDefaultText : selTmtStr
          );
        }

        break;
      }
 
      default:
        break;
    }
  
    setTretamentTypes(updatedTreatmentTypes);
  };
  



  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>

      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu
            activePageId={
              SideMenuItems.TreatmentsAfterEpcoritamab.toString() +
              lot.toString()
            }
          />
          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>Treatments After Initiating Epcoritamab </h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div style={PageDesignConstants.PageDescriptionStyle}>
                    <i>
                      In this section, you will be asked to provide information
                      about any treatment received after initiating epcoritamab.
                    </i>
                  </div>
                  <div className="question-answer">
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q89.</span>
                        <span className="quest-text-pad">
                          Please select line of therapy (LOT):{" "}
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input
                            type="radio"
                            id="q70_Tae_therapy_1"
                            name="q70_Tae_therapy"
                            value={1}
                            checked={ptTaeDetails.q70_Tae_therapy === 1}
                            onChange={HandleFieldChange}
                          />
                          {/* <label htmlFor="q36Select_1" >Select LOT */}
                          <span className="radio-text-padding">
                            <Dropdown className="custom-dropdown-caret">
                              <Dropdown.Toggle
                                variant=""
                                id="dropdown-basic"
                                className="w110 epcoritamab-custom-dropdown-button"
                              >
                                {ptTaeDetails.selectedLOT !== null
                                  ? ptTaeDetails.selectedLOT
                                  : "Select LOT"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="epcoritamab-custom-dropdown-menu">
                                {(
                                  epcorLotNum !== null && epcorLotNum !== undefined
                                    ? Array.from({ length: 10 - epcorLotNum }, (_, i) => i + epcorLotNum + 1)
                                    : [2, 3, 4, 5, 6, 7, 8, 9, 10]
                                ).map((num) => (
                                  <Dropdown.Item
                                    as="button"
                                    name="selectedLOT"
                                    key={num}
                                    value={num}
                                    className={`custom-dropdown-item ${
                                      num === ptTaeDetails.selectedLOT &&
                                      "custom-dropdown-item-selected"
                                    }`}
                                    style={{ width: "100% !important" }}
                                    onClick={HandleFieldChange} //setSelectedStateID(item.stateid); HandleDDChange(item)
                                  >
                                    {num}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </span>
                          {/* </label> */}
                        </div>
                        {/* <div className="">
                          <input
                            type="radio"
                            id="q70_Tae_therapy_other"
                            name="q70_Tae_therapy"
                            value={2}
                            checked={ptTaeDetails.q70_Tae_therapy === 2}
                            onChange={HandleFieldChange}
                          />
                          <label>
                            <span className="radio-text-padding">
                              Other, specify:{" "}
                              <input
                                className="input-dash"
                                type="text"
                                name="q70_Tae_therapy_txt"
                                value={ptTaeDetails.q70_Tae_therapy_txt}
                                onChange={HandleFieldChange}
                              />
                            </span>
                          </label>
                        </div> */}
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="q70_unknown99"
                              name="q70_Tae_therapy"
                              value={99}
                              checked={ptTaeDetails.q70_Tae_therapy === 99}
                              onChange={HandleFieldChange}
                            />

                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>

                      <ErrorField
                        show={softErrList.q70Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q70Empty}
                        message={Treatment_After_Msgs.Q70}
                      />
                      {/* <ErrorField
                        show={hardErrList.q70EmptyOtherTxt}
                        message="Please specify the LOT."
                      /> */}
                      <ErrorField
                        show={hardErrList.q70LOTNull}
                        message="Please specify the LOT."
                      />
                    </div>














                    <div className="question question-weight">
                        <span>Q90.</span>
                        <span className="quest-text-pad">
                          What treatments did the patient receive in LOT {lot}?{" "}
                          {""}
                          <i>
                            (select all treatments received as part of the same
                            LOT)
                          </i>
                        </span>
                      </div>

    <div>
      {treatmentTypes.map((type,index) => (    
        <div key={type.id} className="radio-text-padding ">
          {
          index === 0 || treatmentTypes[index - 1].heading !== type.heading ? ( 
            <>
        <br></br>
          <h6>{type.heading}</h6> 
          </>
           ) : null
          }
          <h7>
            <label>
              <input
                type="checkbox"
                checked={type.isSelected || false} 
              onChange={(e) => handleTreatmentTypeFieldChange(e,type.treatmenttypeid,type.name,index)}
              id={`treatmentType_${type.name}_${index}`}
              value = {type.name}
              />
              <span className="radio-text-padding">{type.name}</span>

              
              {(type.treatmenttypeid === TreatmentType.Oth_Other ||type.treatmenttypeid === TreatmentType.Corti_Other)
                                                      && (
                                                      <>
                                                        <input
                                                          type="text"
                                                          className="input-dash"
                                                          id={`other_treatmentType_${type.name}_${index}`}
                                                          
                                                          value={
                                                             type.otherText
                                                          }
                                                          onChange={(e) =>
                                                            handleTreatmentTypeFieldChange(e,type.treatmenttypeid,type.name,index)
                                                          }
                                                          placeholder=""
                                                          maxLength={100}
                                                        />
                                                      </>
                                                    )}
            </label>
          </h7>
          
          {/* Render options for corticosteroid subtypes (IDs 29-34) only */}
          {type.heading === "Corticosteroids" && type.isSelected && (
        <div>

              <div>
                {type.subTreatmentTypes.map((subType,sidx) => (
      
                  <div className="indent-check">
                  <label key={subType.subSubMedicationTypeID} className="radio-text-padding">
                    <input
                      type="checkbox"
                      id={`subTreatmentType_${subType.name}_${subType.subSubMedicationTypeID}`}
                      checked={subType.isSelected || false} 
                       onChange={(e) => handleTreatmentTypeFieldChange(e,type.treatmenttypeid,subType.name,index,sidx,subType.subSubMedicationTypeID)}
                    />
                    <span className="radio-text-padding">{subType.name}</span>
                  </label>
                  </div>
                  
                 
                ))
                }
       
       <ErrorField
                                    show={type.tmt_sub_empty}
                                    message={Epcoritamab_Msgs.sub}
                                  />
              </div>
          
        </div>
      )}
        </div>
      ))}


<div style={{marginLeft:"14px"}}>
                        
                        <div>
                          <label>
                            <input
                              type="radio"
                              value={99}
                               name="treatmentTypeUnk"
                               id="treatmentTypeUnk_Unk"
                               onChange={HandleFieldChange}
                               checked={ptTaeDetails.treatmentTypeUnk}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                        <ErrorField
                        show={softErrList.tmt_prior_parent_unknown}
                        message={CommonError_Msgs.UnknownSelect}
                      />

<ErrorField
                        show={hardErrList.tmt_parent_empty}
                        message={TreatmentPriorEpco_Msgs.parent}
                      />
                      <ErrorField
                        show={hardErrList.tmt_parent_othertext_empty || hardErrList.tmt_parent_othertext2_empty}
                        message={Epcoritamab_Msgs.epco_parent_othertext_empty}
                      />


                      </div>
    </div><br></br>




                    {chk_q49_car_t_cell_therapy && (
                      <>
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q91.</span>
                            <span className="quest-text-pad">
                              What type of CAR T therapy did the patient
                              undergo?
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="">
                              <div className="">
                                <input
                                  type="checkbox"
                                  id="q72CarT_1"
                                  name="q72CarT_1"
                                  checked={ptTaeDetails.q72CarT_1}
                                  onChange={HandleFieldChange}
                                />
                                <label htmlFor="q72CarT_1">
                                  <span className="radio-text-padding">
                                    Axicabtagene ciloleucel
                                  </span>
                                </label>

                                <div>
                                  <input
                                    type="checkbox"
                                    id="q72CarT_2"
                                    name="q72CarT_2"
                                    checked={ptTaeDetails.q72CarT_2}
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="q72CarT_2">
                                    <span className="radio-text-padding">
                                      Brexucabtagene autoleucel
                                    </span>
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="checkbox"
                                    id="q72CarT_3"
                                    name="q72CarT_3"
                                    checked={ptTaeDetails.q72CarT_3}
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="q72CarT_3">
                                    <span className="radio-text-padding">
                                      Ciltacabtagene autoleucel
                                    </span>
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="checkbox"
                                    id="q72CarT_4"
                                    name="q72CarT_4"
                                    checked={ptTaeDetails.q72CarT_4}
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="q72CarT_4">
                                    <span className="radio-text-padding">
                                      Idecabtagene vicleucel
                                    </span>
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="checkbox"
                                    id="q72CarT_5"
                                    name="q72CarT_5"
                                    checked={ptTaeDetails.q72CarT_5}
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="q72CarT_5">
                                    <span className="radio-text-padding">
                                      Lisocabtagene maraleucel
                                    </span>
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="checkbox"
                                    id="q72CarT_6"
                                    name="q72CarT_6"
                                    checked={ptTaeDetails.q72CarT_6}
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="q72CarT_6">
                                    <span className="radio-text-padding">
                                      Tisagenlecleucel
                                    </span>
                                  </label>
                                </div>
                                <div>
                                  <input
                                    type="checkbox"
                                    id="q72CarT_oth"
                                    name="q72CarT_oth"
                                    checked={ptTaeDetails.q72CarT_oth}
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="q72CarT_oth">
                                    <span className="radio-text-padding">
                                      Other, specify:{" "}
                                      <input
                                        name="q72CarT_oth_txt"
                                        className="input-dash"
                                        type="text"
                                        value={ptTaeDetails.q72CarT_oth_txt}
                                        onChange={HandleFieldChange}
                                        maxLength={100}
                                      />
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <ErrorField show={softErrList.q72Unk_err} message={CommonMsgs.Unknown} /> */}
                          <ErrorField
                            show={hardErrList.q72Empty}
                            message={Treatment_After_Msgs.Q72}
                          />
                          <ErrorField
                            show={hardErrList.q72EmptyOtherTxt}
                            message="Please specify the other text."
                          />
                        </div>

                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q92.</span>
                            <span className="quest-text-pad">
                              When did the patient undergo apheresis?
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="date-field-padding">
                              <input
                                type="radio"
                                id="q73Apheresis"
                                value={1}
                                name="q73_apheresis"
                                checked={ptTaeDetails.q73_apheresis === 1}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                <DateControl
                                  ctrlId={"q73Date"}
                                  HandleDateChange={HandleDateChange}
                                  date={ptTaeDetails.q73_Date}
                                />
                                <i>
                                  If the exact month and/or day is unknown,
                                  please enter UNK.
                                </i>
                              </span>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  id="q73_apheresis__99"
                                  name="q73_apheresis"
                                  value={99}
                                  checked={ptTaeDetails.q73_apheresis === 99}
                                  onChange={HandleFieldChange}
                                />

                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={softErrList.q73Unk_err}
                            message={CommonMsgs.Unknown}
                          />
                          <ErrorField
                            show={hardErrList.q73Empty}
                            message={Treatment_After_Msgs.Q73}
                          />

                          <ErrorField
                            show={hardErrList.q73DateValid}
                            message={Treatment_After_Msgs.Date_after_Index}
                          />
                        </div>

                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q93.</span>
                            <span className="quest-text-pad">
                              Did the patient receive bridging therapy prior to
                              initiating CAR T cell therapy?
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="">
                              <div>
                                <input
                                  type="radio"
                                  id="q74_bridge_1"
                                  name="q74_bridge"
                                  value={1}
                                  checked={ptTaeDetails.q74_bridge === 1}
                                  onChange={HandleFieldChange}
                                />
                                <label htmlFor="q74_bridge_1">
                                  <span className="radio-text-padding">
                                    Yes
                                  </span>
                                </label>
                              </div>

                              <div>
                                <input
                                  type="radio"
                                  id="q74_bridge_0"
                                  value={0}
                                  name="q74_bridge"
                                  checked={ptTaeDetails.q74_bridge === 0}
                                  onChange={HandleFieldChange}
                                />
                                <label htmlFor="q74_bridge_0">
                                  <span className="radio-text-padding">No</span>
                                </label>
                              </div>

                              <div>
                                <input
                                  type="radio"
                                  id="q74_bridge_99"
                                  name="q74_bridge"
                                  value={99}
                                  checked={ptTaeDetails.q74_bridge === 99}
                                  onChange={HandleFieldChange}
                                />
                                <label htmlFor="q74_bridge_99">
                                  <span className="radio-text-padding">
                                    Unknown
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <ErrorField
                            show={softErrList.q74Unk_err}
                            message={CommonMsgs.Unknown}
                          />
                          <ErrorField
                            show={hardErrList.q74Empty}
                            message={Treatment_After_Msgs.Q74}
                          />
                        </div>

                        {ptTaeDetails.q74_bridge === 1 && (
                          <>
                            <div className="question-bot-sapce">
                              <div className="question question-weight">
                                <span>Q94.</span>
                                <span className="quest-text-pad">
                                  What treatments did the patient receive as
                                  bridging therapies prior to initiating CAR T
                                  cell therapy?{" "}
                                  <i>
                                    (select all treatments received as part of
                                    the same LOT)
                                  </i>
                                </span>
                              </div>
                              <div className="double-dig-answer">
                                <div className="">
                                  <div className="">
                                    <input
                                      type="checkbox"
                                      id="q75_BT_1"
                                      name="q75_BT_1"
                                      checked={ptTaeDetails.q75_BT_1}
                                      onChange={HandleFieldChange}
                                    />
                                    <label htmlFor="q75_BT_1">
                                      <span className="radio-text-padding">
                                        Acalabrutinib
                                      </span>
                                    </label>

                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_2"
                                        name="q75_BT_2"
                                        checked={ptTaeDetails.q75_BT_2}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_2">
                                        <span className="radio-text-padding">
                                          Cisplatin
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_3"
                                        name="q75_BT_3"
                                        checked={ptTaeDetails.q75_BT_3}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_3">
                                        <span className="radio-text-padding">
                                          Cyclophosphamide
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_4"
                                        name="q75_BT_4"
                                        checked={ptTaeDetails.q75_BT_4}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_4">
                                        <span className="radio-text-padding">
                                          Cytarabine{" "}
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_5"
                                        name="q75_BT_5"
                                        checked={ptTaeDetails.q75_BT_5}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_5">
                                        <span className="radio-text-padding">
                                          Dexamethasone
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_6"
                                        name="q75_BT_6"
                                        checked={ptTaeDetails.q75_BT_6}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_6">
                                        <span className="radio-text-padding">
                                          Doxorubicin
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_7"
                                        name="q75_BT_7"
                                        checked={ptTaeDetails.q75_BT_7}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_7">
                                        <span className="radio-text-padding">
                                          Etoposide
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_8"
                                        name="q75_BT_8"
                                        checked={ptTaeDetails.q75_BT_8}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_8">
                                        <span className="radio-text-padding">
                                          Fludarabine
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_9"
                                        name="q75_BT_9"
                                        checked={ptTaeDetails.q75_BT_9}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_9">
                                        <span className="radio-text-padding">
                                          Gemcitabine
                                        </span>
                                      </label>
                                    </div>

                                    <input
                                      type="checkbox"
                                      id="q75_BT_10"
                                      name="q75_BT_10"
                                      checked={ptTaeDetails.q75_BT_10}
                                      onChange={HandleFieldChange}
                                    />
                                    <label htmlFor="q75_BT_10">
                                      <span className="radio-text-padding">
                                        Hydroxyurea
                                      </span>
                                    </label>

                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_11"
                                        name="q75_BT_11"
                                        checked={ptTaeDetails.q75_BT_11}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_11">
                                        <span className="radio-text-padding">
                                          Ibrutinib
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_12"
                                        name="q75_BT_12"
                                        checked={ptTaeDetails.q75_BT_12}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_12">
                                        <span className="radio-text-padding">
                                          Idarubicin
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_13"
                                        name="q75_BT_13"
                                        checked={ptTaeDetails.q75_BT_13}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_13">
                                        <span className="radio-text-padding">
                                          Lenalidomide
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_14"
                                        name="q75_BT_14"
                                        checked={ptTaeDetails.q75_BT_14}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_14">
                                        <span className="radio-text-padding">
                                          Mercaptopurine
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_15"
                                        name="q75_BT_15"
                                        checked={ptTaeDetails.q75_BT_15}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_15">
                                        <span className="radio-text-padding">
                                          Methotrexate
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_16"
                                        name="q75_BT_16"
                                        checked={ptTaeDetails.q75_BT_16}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_16">
                                        <span className="radio-text-padding">
                                          Rituximab
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_17"
                                        name="q75_BT_17"
                                        checked={ptTaeDetails.q75_BT_17}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_17">
                                        <span className="radio-text-padding">
                                          Steroids
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_18"
                                        name="q75_BT_18"
                                        checked={ptTaeDetails.q75_BT_18}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_18">
                                        <span className="radio-text-padding">
                                          Tyrosine kinase inhibitor
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_19"
                                        name="q75_BT_19"
                                        checked={ptTaeDetails.q75_BT_19}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_19">
                                        <span className="radio-text-padding">
                                          Vincristine
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <input
                                        type="checkbox"
                                        id="q75_BT_oth"
                                        name="q75_BT_oth"
                                        checked={ptTaeDetails.q75_BT_oth}
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q75_BT_oth">
                                        <span className="radio-text-padding">
                                          Other, specify:{" "}
                                          <input
                                            name="q75_BT_oth_txt"
                                            className="input-dash"
                                            type="text"
                                            value={ptTaeDetails.q75_BT_oth_txt}
                                            onChange={HandleFieldChange}
                                            maxLength={100}
                                          />
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          id="q75_BT_Unk_99"
                                          name="q75_BT_Unk"
                                          value={99}
                                          checked={
                                            ptTaeDetails.q75_BT_Unk === 99
                                          }
                                          onChange={HandleFieldChange}
                                        />

                                        <span className="radio-text-padding">
                                          Unknown
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <ErrorField
                                show={softErrList.q75Unk_err}
                                message={CommonMsgs.Unknown}
                              />
                              <ErrorField
                                show={hardErrList.q75Empty}
                                message={Treatment_After_Msgs.Q75}
                              />
                              <ErrorField
                                show={hardErrList.q75EmptyOtherTxt}
                                message="Please specify the other text."
                              />
                            </div>

                            <div className="question-bot-sapce">
                              <div className="question question-weight">
                                <span>Q95.</span>
                                <span className="quest-text-pad">
                                  When did the patient initiate the bridging
                                  therapies prior to initiating CAR T cell
                                  therapy?
                                </span>
                              </div>
                              <div className="double-dig-answer">
                                <div className="date-field-padding">
                                  <input
                                    type="radio"
                                    id="q76_1"
                                    name="q76_BT"
                                    value={1}
                                    checked={ptTaeDetails.q76_BT === 1}
                                    onChange={HandleFieldChange}
                                  />
                                  <span className="radio-text-padding">
                                    <DateControl
                                      ctrlId={"q76Date"}
                                      HandleDateChange={HandleDateChange}
                                      date={ptTaeDetails.q76_Date}
                                    />
                                    <i>
                                      If the exact month and/or day is unknown,
                                      please enter UNK.
                                    </i>
                                  </span>
                                </div>
                                <div className="">
                                  <input
                                    type="radio"
                                    id="q76_99"
                                    name="q76_BT"
                                    value={99}
                                    checked={ptTaeDetails.q76_BT === 99}
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="q76_99">
                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={softErrList.q76Unk_err}
                                message={CommonMsgs.Unknown}
                              />
                              <ErrorField
                                show={hardErrList.q76Empty}
                                message={Treatment_After_Msgs.Q76}
                              />
                              
                              <ErrorField
                                show={hardErrList.q76DateValidate}
                                message={Treatment_After_Msgs.Date_after_Index}
                              />
                            </div>
                            <div className="question-bot-sapce">
                              <div className="question question-weight">
                                <span>Q96.</span>
                                <span className="quest-text-pad">
                                  How many cycles of bridging therapy did the
                                  patient receive?
                                </span>
                              </div>
                              <div className="double-dig-answer">
                                <div className="">
                                  <div>
                                    <input
                                      type="radio"
                                      id="q77_1"
                                      name="q77_BT_Init"
                                      value={1}
                                      checked={ptTaeDetails.q77_BT_Init === 1}
                                      onChange={HandleFieldChange}
                                    />
                                    <label htmlFor="q77_1">
                                      <span className="radio-text-padding">
                                        Specify number of cycles:
                                        <input
                                          name="q77_BT_Init_num"
                                          className="sub-input-dash"
                                          type="text"
                                          value={ptTaeDetails.q77_BT_Init_num}
                                          onChange={HandleFieldChange}
                                        />
                                      </span>
                                    </label>
                                  </div>

                                  <div className="">
                                    <div>
                                      <input
                                        type="radio"
                                        id="q77_BT_Init_99"
                                        name="q77_BT_Init"
                                        value={99}
                                        checked={
                                          ptTaeDetails.q77_BT_Init === 99
                                        }
                                        onChange={HandleFieldChange}
                                      />
                                      <label htmlFor="q77_BT_Init_99">
                                        <span className="radio-text-padding">
                                          Unknown
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ErrorField
                                show={softErrList.q77Unk_err}
                                message={CommonMsgs.Unknown}
                              />
                              <ErrorField
                                show={hardErrList.q77Empty}
                                message={Treatment_After_Msgs.Q77}
                              />

                              <ErrorField
                                show={hardErrList.q77SizeOutOfRange}
                                message={Treatment_After_Msgs.DaysRangeLimit}
                              />
                            </div>
                          </>
                        )}
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q97.</span>
                            <span className="quest-text-pad">
                              Did the patient receive lymphodepleting therapy
                              prior to initiating CAR T cell therapy?
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="">
                              <div>
                                <input
                                  type="radio"
                                  id="q78_1"
                                  name="q78_lt"
                                  value={1}
                                  checked={ptTaeDetails.q78_lt === 1}
                                  onChange={HandleFieldChange}
                                />
                                <label htmlFor="q78_1">
                                  <span className="radio-text-padding">
                                    Yes
                                  </span>
                                </label>
                              </div>

                              <div className="">
                                <div>
                                  <input
                                    type="radio"
                                    id="q78_0"
                                    name="q78_lt"
                                    value={0}
                                    checked={ptTaeDetails.q78_lt === 0}
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="q78_0">
                                    <span className="radio-text-padding">
                                      No
                                    </span>
                                  </label>
                                </div>
                              </div>

                              <div className="">
                                <div>
                                  <input
                                    type="radio"
                                    id="q78_99"
                                    name="q78_lt"
                                    value={99}
                                    checked={ptTaeDetails.q78_lt === 99}
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="q78_99">
                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ErrorField
                            show={softErrList.q78Unk_err}
                            message={CommonMsgs.Unknown}
                          />
                          <ErrorField
                            show={hardErrList.q78Empty}
                            message={Treatment_After_Msgs.Q78}
                          />
                        </div>
                      </>
                    )}

                    {ptTaeDetails.q78_lt === 1 &&
                     chk_q49_car_t_cell_therapy && (
                        <>
                          <div className="question-bot-sapce">
                            <div className="question question-weight">
                              <span>Q98.</span>
                              <span className="quest-text-pad">
                                What treatments did the patient receive as
                                lymphodepleting therapies prior to initiating
                                CAR T cell therapy?{" "}
                                <i>
                                  (select all treatments received as part of the
                                  same LOT)
                                </i>
                              </span>
                            </div>
                            <div className="double-dig-answer">
                              <div className="">
                                <div>
                                  <input
                                    type="checkbox"
                                    id="q79_therapies_1"
                                    name="q79_therapies_1"
                                    checked={ptTaeDetails.q79_therapies_1}
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="q79_therapies_1">
                                    <span className="radio-text-padding">
                                      Cyclophosphamide
                                    </span>
                                  </label>

                                  <div>
                                    <input
                                      type="checkbox"
                                      id="q79_therapies_2"
                                      name="q79_therapies_2"
                                      checked={ptTaeDetails.q79_therapies_2}
                                      onChange={HandleFieldChange}
                                    />
                                    <label htmlFor="q79_therapies_2">
                                      <span className="radio-text-padding">
                                        Fludarabine
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      id="q79_therapies_3"
                                      name="q79_therapies_3"
                                      checked={ptTaeDetails.q79_therapies_3}
                                      onChange={HandleFieldChange}
                                    />
                                    <label htmlFor="q79_therapies_3">
                                      <span className="radio-text-padding">
                                        Other, specify:{" "}
                                        <input
                                          name="q79_oth_txt"
                                          className="input-dash"
                                          type="text"
                                          value={ptTaeDetails.q79_oth_txt}
                                          onChange={HandleFieldChange}
                                          maxLength={100}
                                        />
                                      </span>
                                    </label>
                                  </div>

                                  <div>
                                    <input
                                      type="radio"
                                      id="q79_therapies_unk99"
                                      name="q79_Unk"
                                      value={99}
                                      checked={ptTaeDetails.q79_Unk === 99}
                                      onChange={HandleFieldChange}
                                    />
                                    <label htmlFor="q79_therapies_unk99">
                                      <span className="radio-text-padding">
                                        Unknown
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ErrorField
                              show={softErrList.q79Unk_err}
                              message={CommonMsgs.Unknown}
                            />
                            <ErrorField
                              show={hardErrList.q79Empty}
                              message={Treatment_After_Msgs.Q79}
                            />
                            <ErrorField
                              show={hardErrList.q79EmptyOtherTxt}
                              message="Please specify the other text."
                            />
                          </div>

                          <div className="question-bot-sapce">
                            <div className="question question-weight">
                              <span>Q99.</span>
                              <span className="quest-text-pad">
                                When did the patient initiate the
                                lymphodepleting therapies prior to initiating
                                CAR T cell therapy?
                              </span>
                            </div>
                            <div className="double-dig-answer">
                              <div className="date-field-padding">
                                <input
                                  type="radio"
                                  id="q80_lt_select1"
                                  name="q80_lt"
                                  value={1}
                                  checked={ptTaeDetails.q80_lt === 1}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  <DateControl
                                    ctrlId={"q80Date"}
                                    HandleDateChange={HandleDateChange}
                                    date={ptTaeDetails.q80_Date}
                                  />
                                  <i>
                                    If the exact month and/or day is unknown,
                                    please enter UNK.
                                  </i>
                                </span>
                              </div>
                              <div className="">
                                <input
                                  type="radio"
                                  id="q80_lt_select2"
                                  name="q80_lt"
                                  value={99}
                                  checked={ptTaeDetails.q80_lt === 99}
                                  onChange={HandleFieldChange}
                                />
                                <label htmlFor="q80_lt_select2">
                                  <span className="radio-text-padding">
                                    Unknown
                                  </span>
                                </label>
                              </div>
                            </div>
                            <ErrorField
                              show={softErrList.q80Unk_err}
                              message={CommonMsgs.Unknown}
                            />
                            <ErrorField
                              show={hardErrList.q80Empty}
                              message={Treatment_After_Msgs.Q80}
                            />
                            
                            <ErrorField
                              show={hardErrList.q80DateValidate}
                              message={Treatment_After_Msgs.Date_after_Index}
                            />
                          </div>
                        </>
                      )}

                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q100.</span>
                        <span className="quest-text-pad">
                          When did the patient initiate{" "}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                             {selectedReceivedTmtsStr}
                          </span>
                          ?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="date-field-padding">
                          <input
                            type="radio"
                            id="q81_select1"
                            name="q81_anti"
                            value={1}
                            checked={ptTaeDetails.q81_anti === 1}
                            onChange={HandleFieldChange}
                          />
                          <span className="radio-text-padding">
                            <DateControl
                              ctrlId={"q81_date"}
                              HandleDateChange={HandleDateChange}
                              date={ptTaeDetails.q81_Date}
                            />
                            <i>
                              If the exact month and/or day is unknown, please
                              enter UNK.
                            </i>
                          </span>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            id="q81_select99"
                            name="q81_anti"
                            value={99}
                            checked={ptTaeDetails.q81_anti === 99}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q81_select99">
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={softErrList.q81Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q81Empty}
                        message={Treatment_After_Msgs.Q81}
                      />
                      <ErrorField
                        show={hardErrList.q81DateValid}
                        message={Treatment_After_Msgs.Q81_Date}
                      />
                    </div>

                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q101.</span>
                        <span className="quest-text-pad">
                          What was the patient’s International Prognostic Index
                          (IPI) risk score closest to or at{" "}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                             {selectedReceivedTmtsStr}
                          </span>{" "}
                          initiation?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="">
                          <div>
                            <input
                              type="radio"
                              id="q82_select1"
                              name="q82_IPI"
                              value={1}
                              checked={ptTaeDetails.q82_IPI === 1}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q82_select1">
                              <span className="radio-text-padding">
                                0-1 points (low risk)
                              </span>
                            </label>
                          </div>

                          <div>
                            <input
                              type="radio"
                              id="q82_select2"
                              name="q82_IPI"
                              value={2}
                              checked={ptTaeDetails.q82_IPI === 2}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q82_select2">
                              <span className="radio-text-padding">
                                2 points (low-intermediate risk)
                              </span>
                            </label>
                          </div>

                          <div>
                            <input
                              type="radio"
                              id="q82_select3"
                              name="q82_IPI"
                              value={3}
                              checked={ptTaeDetails.q82_IPI === 3}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q82_select3">
                              <span className="radio-text-padding">
                                3 points (high-intermediate risk)
                              </span>
                            </label>
                          </div>

                          <div>
                            <input
                              type="radio"
                              id="q82_select4"
                              name="q82_IPI"
                              value={4}
                              checked={ptTaeDetails.q82_IPI === 4}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q82_select4">
                              <span className="radio-text-padding">
                                4-5 points (high-risk)
                              </span>
                            </label>
                          </div>

                          <div>
                            <input
                              type="radio"
                              id="q82_select99"
                              name="q82_IPI"
                              value={99}
                              checked={ptTaeDetails.q82_IPI === 99}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q82_select99">
                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <ErrorField
                        show={softErrList.q82Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q82Empty}
                        message={Treatment_After_Msgs.Q82}
                      />
                    </div>

                    {[1, 2, 3, 4].includes(ptTaeDetails.q82_IPI) && (
                      <>
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q102.</span>
                            <span className="quest-text-pad">
                              What was the date of assessment of the patient’s
                              International Prognostic Index (IPI) risk score?
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="date-field-padding">
                              <input
                                type="radio"
                                id="q83_select1"
                                name="q83_IPI"
                                value={1}
                                checked={ptTaeDetails.q83_IPI === 1}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                <DateControl
                                  ctrlId={"q83Date"}
                                  HandleDateChange={HandleDateChange}
                                  date={ptTaeDetails.q83_Date}
                                />
                                <i>
                                  If the exact month and/or day is unknown,
                                  please enter UNK.
                                </i>
                              </span>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id="q83_select2"
                                name="q83_IPI"
                                value={99}
                                checked={ptTaeDetails.q83_IPI == 99}
                                onChange={HandleFieldChange}
                              />
                              <label htmlFor="q83_select2">
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={softErrList.q83Unk_err}
                            message={CommonMsgs.Unknown}
                          />
                          <ErrorField
                            show={hardErrList.q83Empty}
                            message={Treatment_After_Msgs.Q83}
                          />
                          
                          <ErrorField
                            show={hardErrList.q83DateValidate}
                            message={Treatment_After_Msgs.Date_after_Index}
                          />
                        </div>
                      </>
                    )}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q103.</span>
                        <span className="quest-text-pad">
                          When did the patient discontinue{" "}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                             {selectedReceivedTmtsStr}
                          </span>
                          ?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="date-field-padding">
                          <input
                            type="radio"
                            id="q84_PD_select1"
                            name="q84_PD"
                            value={1}
                            checked={ptTaeDetails.q84_PD === 1}
                            onChange={HandleFieldChange}
                          />
                          <span className="radio-text-padding">
                            <DateControl
                              ctrlId={"q84Date"}
                              HandleDateChange={HandleDateChange}
                              date={ptTaeDetails.q84_Date}
                            />
                            <i>
                              If the exact month and/or day is unknown, please
                              enter UNK.
                            </i>
                          </span>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            id="q84_PD_select2"
                            name="q84_PD"
                            value={2}
                            checked={ptTaeDetails.q84_PD === 2}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q84_PD_select2">
                            <span className="radio-text-padding">
                              The patient is still on treatment
                            </span>
                          </label>
                        </div>

                        <div>
                          <input
                            type="radio"
                            id="q84_99"
                            name="q84_PD"
                            value={99}
                            checked={ptTaeDetails.q84_PD === 99}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q84_99">
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={softErrList.q84Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q84Empty}
                        message={Treatment_After_Msgs.Q84}
                      />
                      <ErrorField
                        show={hardErrList.q84DateValid}
                        message={Treatment_After_Msgs.Q84After}
                      />
                    </div>

                    <div className="dependent-section">
                      {/* Repeater 85-90 Set1*/}
                      {radiologicalAssessmentsDetails &&
                        radiologicalAssessmentsDetails.length > 0 &&
                        radiologicalAssessmentsDetails.map((data, idx) => (
                          <div className="loop-section-ul">
                            <div className="loop-head">
                              {/* <span className="question-weight">1<sup>st</sup> Radiological Assessment</span> */}
                              <span className="question-weight">
                                {GetOrdinal(idx + 1)} Radiological Assessment
                              </span>
                              {idx != 0 && (
                                <div className="delete-btn-div">
                                  <img
                                    width="16px"
                                    src="../Assets/images/Icon-material-delete.png"
                                    alt=""
                                    onClick={() =>
                                      DeleteEPCORAssessmentRow(idx)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q104.</span>
                                <span className="quest-text-pad">
                                  What was the date of the{" "}
                                  {GetOrdinalInWord(idx + 1)} radiological
                                  assessment after the patient initiated{" "}
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  >
                                     {selectedReceivedTmtsStr}
                                  </span>
                                  ?
                                </span>
                              </div>
                              <div className="double-dig-answer">
                                <div className="date-field-padding">
                                  <input
                                    type="radio"
                                    id={idx}
                                    name={"q85radiological" + idx}
                                    value={1}
                                    checked={data.q85RepeaterUnk === 1}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                  <span className="radio-text-padding">
                                    <DateControl
                                      ctrlId={"q85radiological_Date-" + idx}
                                      HandleDateChange={
                                        HandleRepeaterDateChange
                                      }
                                      date={data.q85Unk_date}
                                      removeErrMsgPadding={true}
                                    />
                                    <i>
                                      If the exact month and/or day is unknown,
                                      please enter UNK.
                                    </i>
                                  </span>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q85radiological" + idx}
                                      value={99}
                                      checked={data.q85RepeaterUnk === 99}
                                      onChange={HandleRepeaterFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q85Soft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                              <ErrorField
                                show={data.q85EmptyHard}
                                message={Treatment_After_Msgs.Q85_Date.replace(
                                  "{id}",
                                  GetOrdinalInWord(idx + 1)
                                )}
                              />

                              <ErrorField
                                show={data.q85afterindexEmptyHard}
                                message={Treatment_After_Msgs.Q85_Date_Before.replace(
                                  "{id}",
                                  GetOrdinalInWord(idx + 1)
                                )}
                              />
                              <ErrorField
                                show={data.q85beforeQ84dateEmptyHard}
                                message={Treatment_After_Msgs.Q85_Date_After.replace(
                                  "{id}",
                                  GetOrdinalInWord(idx + 1)
                                )}
                              />
                            </div>

                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q105.</span>
                                <span className="quest-text-pad">
                                  Which of the following categories best
                                  describes the patient’s LBCL disease status at
                                  this radiological assessment? This can be
                                  based on the conclusion of the radiologic
                                  report or physician’s notes in the chart.{" "}
                                </span>
                              </div>
                              <div className="double-dig-answer">
                                <div className="">
                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q86_LBCL_status" + idx}
                                        value={1}
                                        checked={data.q86LBLC === 1}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Clinician-assessed complete response
                                      </span>
                                    </label>
                                  </div>

                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q86_LBCL_status" + idx}
                                        value={2}
                                        checked={data.q86LBLC === 2}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Clinician-assessed less-than-complete
                                        response (significant tumor shrinkage)
                                      </span>
                                    </label>
                                  </div>

                                  <div className="long-text-option">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q86_LBCL_status" + idx}
                                        value={3}
                                        checked={data.q86LBLC === 3}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Clinician-assessed stable disease (may
                                        include minimal increases or decreases
                                        in size of tumor or permitting ongoing
                                        systemic therapy)
                                      </span>
                                    </label>
                                  </div>

                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q86_LBCL_status" + idx}
                                        value={4}
                                        checked={data.q86LBLC === 4}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Progressive disease
                                      </span>
                                    </label>
                                  </div>

                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q86_LBCL_status" + idx}
                                        value={5}
                                        checked={data.q86LBLC === 5}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Other, specify:{" "}
                                        <input
                                          name={"q86_LBCL_txt"}
                                          id={idx}
                                          className="input-dash"
                                          type="text"
                                          value={data.q86_Other_txt}
                                          onChange={HandleRepeaterFieldChange}
                                          maxLength={100}
                                        />
                                      </span>
                                    </label>
                                  </div>

                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q86_LBCL_status" + idx}
                                        value={99}
                                        checked={data.q86LBLC === 99}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Unknown
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <ErrorField
                                show={data.q86EmptyHard}
                                message={Treatment_After_Msgs.Q86}
                              />
                              <ErrorField
                                show={data.q86Soft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                              <ErrorField
                                show={data.q86TxtEmptyHard}
                                message={Epcoritamab_Msgs.q44lbclTxtEmpty}
                              />
                            </div>

                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q106.</span>
                                <span className="quest-text-pad">
                                  Was the disease stage assessed per LYRIC or
                                  Lugano criteria?{" "}
                                </span>
                              </div>
                              <div className="double-dig-answer">
                                <div className="">
                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q87_stage_status" + idx}
                                        value={1}
                                        checked={data.q87LyricLugano === 1}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        LYRIC
                                      </span>
                                    </label>
                                  </div>

                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q87_stage_status" + idx}
                                        value={2}
                                        checked={data.q87LyricLugano === 2}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Lugano
                                      </span>
                                    </label>
                                  </div>

                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q87_stage_status" + idx}
                                        value={3}
                                        checked={data.q87LyricLugano === 3}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        LYRIC and Lugano
                                      </span>
                                    </label>
                                  </div>

                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q87_stage_status" + idx}
                                        value={99}
                                        checked={data.q87LyricLugano === 99}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Unknown
                                      </span>
                                    </label>
                                  </div>

                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q87_stage_status" + idx}
                                        value={4}
                                        checked={data.q87LyricLugano === 4}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Neither
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q87EmptyHard}
                                message={Treatment_After_Msgs.Q87}
                              />
                               <ErrorField
                                show={data.diseaseStageCriteriaSoft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                            </div>
                            {showQ88[idx] && (
                              <div className="sub-question-bot-sapce answer-pad-left">
                                <div className="question question-weight">
                                  <span>Q107.</span>
                                  <span className="quest-text-pad">
                                    What was the disease assessment per LYRIC
                                    criteria?
                                  </span>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="">
                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          id={idx}
                                          name={"q88LyricAssess" + idx}
                                          value={1}
                                          checked={data.q88LyricCriteria === 1}
                                          onChange={HandleRepeaterFieldChange}
                                        />

                                        <span className="radio-text-padding">
                                          IR1
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          id={idx}
                                          name={"q88LyricAssess" + idx}
                                          value={2}
                                          checked={data.q88LyricCriteria === 2}
                                          onChange={HandleRepeaterFieldChange}
                                        />

                                        <span className="radio-text-padding">
                                          IR2
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          id={idx}
                                          name={"q88LyricAssess" + idx}
                                          value={3}
                                          checked={data.q88LyricCriteria === 3}
                                          onChange={HandleRepeaterFieldChange}
                                        />

                                        <span className="radio-text-padding">
                                          IR1 and IR3
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          id={idx}
                                          name={"q88LyricAssess" + idx}
                                          value={4}
                                          checked={data.q88LyricCriteria === 4}
                                          onChange={HandleRepeaterFieldChange}
                                        />

                                        <span className="radio-text-padding">
                                          IR2 and IR3
                                        </span>
                                      </label>
                                    </div>

                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          id={idx}
                                          name={"q88LyricAssess" + idx}
                                          value={5}
                                          checked={data.q88LyricCriteria === 5}
                                          onChange={HandleRepeaterFieldChange}
                                        />

                                        <span className="radio-text-padding">
                                          IR3
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          id={idx}
                                          name={"q88LyricAssess" + idx}
                                          value={6}
                                          checked={data.q88LyricCriteria === 6}
                                          onChange={HandleRepeaterFieldChange}
                                        />

                                        <span className="radio-text-padding">
                                          Stable disease
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          id={idx}
                                          name={"q88LyricAssess" + idx}
                                          value={7}
                                          checked={data.q88LyricCriteria === 7}
                                          onChange={HandleRepeaterFieldChange}
                                        />

                                        <span className="radio-text-padding">
                                          Partial response
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          id={idx}
                                          name={"q88LyricAssess" + idx}
                                          value={8}
                                          checked={data.q88LyricCriteria === 8}
                                          onChange={HandleRepeaterFieldChange}
                                        />

                                        <span className="radio-text-padding">
                                          Complete response
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          id={idx}
                                          name={"q88LyricAssess" + idx}
                                          value={9}
                                          checked={data.q88LyricCriteria === 9}
                                          onChange={HandleRepeaterFieldChange}
                                        />

                                        <span className="radio-text-padding">
                                          Progressive disease
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <ErrorField
                                  show={data.q88EmptyHard}
                                  message={Treatment_After_Msgs.Q88}
                                />
                              </div>
                            )}
                            {/* {showQ89[idx] && ( */}
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q108.</span>
                                <span className="quest-text-pad">
                                  What type of radiological assessment was used?{" "}
                                </span>
                              </div>
                              <div className="double-dig-answer">
                                <div className="">
                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q89_radiology_type" + idx}
                                        value={1}
                                        checked={data.q89Unk === 1}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        PET or PET/CT
                                      </span>
                                    </label>
                                  </div>

                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q89_radiology_type" + idx}
                                        value={2}
                                        checked={data.q89Unk === 2}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        CT
                                      </span>
                                    </label>
                                  </div>
{/* 
                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q89_radiology_type" + idx}
                                        value={3}
                                        checked={data.q89Unk === 3}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Other imaging
                                      </span>
                                    </label>
                                  </div> */}

                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q89_radiology_type" + idx}
                                        value={4}
                                        checked={data.q89Unk === 4}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Clinical only (no imaging)
                                      </span>
                                    </label>
                                  </div>

                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q89_radiology_type" + idx}
                                        value={5}
                                        checked={data.q89Unk === 5}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Other, specify:{" "}
                                        <input
                                          name={"q89RadioAssessTypeOtherTxt"}
                                          id={idx}
                                          className="input-dash"
                                          type="text"
                                          value={data.q89_Other_txt}
                                          onChange={HandleRepeaterFieldChange}
                                          maxLength={100}
                                        />
                                      </span>
                                    </label>
                                  </div>

                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q89_radiology_type" + idx}
                                        value={99}
                                        checked={data.q89Unk === 99}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Unknown
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <ErrorField
                                show={data.q89EmptyHard}
                                message={Treatment_After_Msgs.Q89}
                              />
                              <ErrorField
                                show={data.q89Soft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                              <ErrorField
                                show={data.q89TxtEmptyHard}
                                message={Epcoritamab_Msgs.q47TxtEmpty}
                              />
                            </div>
                            {/* )} */}
                          </div>
                        ))}
                      {radiologicalAssessmentsDetails &&
                        radiologicalAssessmentsDetails.length < 8 && (
                          <div className="sub-question-bot-sapce-ul mt-2">
                            <div className="answer-list-text">
                              <a onClick={AddEPCORAssessmentRow}>
                                <img
                                  width="18px"
                                  src="../Assets/images/plus.png"
                                  alt=""
                                />
                              </a>
                              <a
                                onClick={AddEPCORAssessmentRow}
                                className="radio-text-padding"
                              >
                                {/* Click here to add another ECOG assessment after{" "}
                                {ptTaeDetails.indexDate} */}
                                Click here to add another radiological
                                assessment
                              </a>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="sub-question-bot-sapce answer-pad-left">
                      <div className="question question-weight">
                        <span>Q110.</span>
                        <span className="quest-text-pad">
                          Did the patient receive any of the following
                          supportive care or concomitant medication in addition
                          to    <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                             {selectedReceivedTmtsStr}
                          </span>?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        {medicationTypes.map((medType, idx) => (
                          <div key={idx}>
                            <label>
                              <input
                                type="checkbox"
                                id={`medType_${medType.name}_${idx}`}
                                checked={
                                  medType.isSelected

                                }
                                onChange={(e) =>
                                  handleInputChange(e, medType.medicationTypeID, medType.name, idx
                                  )
                                }
                              />
                              <span className="radio-text-padding">
                                {medType.name}
                              </span>

                              {medType.medicationTypeID === MedicationType.Other && (
                                //   selectedOptions.parent[medType.medicationTypeID] &&
                                <>
                                  , specify:
                                  <input
                                    type="text"
                                    className="input-dash"
                                    value={medType.medicationTypeOther}
                                    id={`medType_txt_${idx}`}
                                    onChange={(e) => handleInputChange(e, medType.medicationTypeID, medType.name, idx)}
                                    placeholder=""
                                    maxLength={100}
                                  />
                                </>
                              )}
                            </label>
                            {
                              medType.subMedicationTypes && medType.subMedicationTypes.length > 0 && (
                                <>
                                <div className="child-checkbox">

                                  {medType.isSelected === true && medType.medicationTypeID === MedicationType.Glucocorticoids && (<>
                                    {medType?.subMedicationTypes?.map(
                                      (subMedType, sub_idx) => (
                                        <>
                                          <div key={sub_idx}>
                                            <label>
                                              <input
                                                type="checkbox"
                                                id={`subMedType_${subMedType.name}_${sub_idx}`}
                                                checked={subMedType.isSelected}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    e, medType.medicationTypeID, subMedType.name, idx, sub_idx, null, subMedType.name, subMedType.subMedicationTypeID
                                                  )
                                                }
                                              />
                                              <span className="radio-text-padding">
                                                {subMedType.name}
                                              </span>

                                              {subMedType.subMedicationTypeID === SupportiveCareEpcoritamab.Other && (
                                                <>
                                                  , specify:
                                                  <input
                                                    type="text"
                                                    className="input-dash"
                                                    id={`subMedType_txt_${idx}`}
                                                    value={subMedType.subMedicationTypeOther}
                                                    onChange={(e) => handleInputChange(e, medType.medicationTypeID, subMedType.name, idx, sub_idx, subMedType.name, subMedType.subMedicationTypeID)}
                                                    placeholder=""
                                                    maxLength={100}
                                                  />
                                                </>
                                              )}
                                            </label>
                                            {
                                              medType.subMedicationTypes[sub_idx]?.subSubMedicationTypes && (
                                                <div className="child-checkbox">

                                                  {subMedType.isSelected && (
                                                    <>
                                                      {medType.subMedicationTypes[sub_idx]?.subSubMedicationTypes.map(
                                                        (subSubMedType, sub_sub_idx) => (
                                                          <>
                                                          <div
                                                            key={sub_sub_idx}
                                                          >
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                // id={`subSubMedType-${subSubMedType.subSubMedicationTypeID}`}
                                                                id={`subSubMedType_${subSubMedType.name}_${sub_sub_idx}`}

                                                                checked={
                                                                  subSubMedType.isSelected
                                                                }
                                                                onChange={(e) =>
                                                                  handleInputChange(
                                                                    e, medType.medicationTypeID, subSubMedType.name, idx, sub_idx, sub_sub_idx, subMedType.name, subMedType.subMedicationTypeID
                                                                  )
                                                                }
                                                              />
                                                              <span className="radio-text-padding">
                                                                {subSubMedType.name}
                                                              </span>
                                                            </label>
                                                          </div>
                                                          </>
                                                        )
                                                      )}
                                                    </>)}
                                                </div>
                                              )}
                                              <ErrorField
                                                show={subMedType.epco_sub_sub_empty}
                                                message={Epcoritamab_Msgs.subsub}
                                              />
                                          </div>
                                        </>
                                      )
                                    )}
                                  </>)}
                                </div>
                                          <ErrorField
                                            show={medType.epco_sub_empty}
                                            message={Epcoritamab_Msgs.sub}
                                          />
                                          <ErrorField
                                            show={medType.epco_sub_oth_txt_empty}
                                            message={Epcoritamab_Msgs.epco_sub_othertext_empty}
                                          />
                                          </>
                            )}
                          </div>
                        ))}
                        <div>
                          <label>
                            <input
                              type="radio"
                              id="medicationNone_"
                              name="medicationNone"
                              checked={ptTaeDetails.medicationNone}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">None</span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              name="medicationUnk"
                              id="medicationUnk_"
                              checked={ptTaeDetails.medicationUnk}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={softErrList.epco_parent_unknown}
                        message={CommonError_Msgs.UnknownSelect}
                      />
                      <ErrorField
                        show={hardErrList.epco_parent_empty}
                        message={Epcoritamab_Msgs.parent}
                      />
                      <ErrorField
                        show={hardErrList.epco_parent_othertext_empty}
                        message={Epcoritamab_Msgs.epco_parent_othertext_empty}
                      />
                    </div>

                    {tocilizumabChecked && (
                      <>
                        {/* Q99. */}
                        {/* <div className="sub-question-bot-sapce answer-pad-left">
                          <div className="question question-weight">
                            <span>Q111.</span>
                            <span className="quest-text-pad">
                              Did the patient receive any doses of tocilizumab?
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  value={1}
                                  name="dosesOfTocilizumabNumOfDoses"
                                  id="dosesOfTocilizumabNumOfDoses_Yes"
                                  onClick={HandleFieldChange}
                                  checked={
                                    ptTaeDetails.dosesOfTocilizumabNumOfDoses ===
                                    1
                                  }
                                />
                                <span className="radio-text-padding">Yes</span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  value={99}
                                  name="dosesOfTocilizumabNumOfDoses"
                                  id="dosesOfTocilizumabNumOfDoses_Unk"
                                  onClick={HandleFieldChange}
                                  checked={
                                    ptTaeDetails.dosesOfTocilizumabNumOfDoses ===
                                    99
                                  }
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={
                              hardErrList.dosesOfTocilizumabNumOfDoses_Empty
                            }
                            message={
                              Epcoritamab_Msgs.epco_doses_of_tocilizumab_empty
                            }
                          />
                          <ErrorField
                            show={softErrList.dosesOfTocilizumabNumOfDoses_unk}
                            message={CommonError_Msgs.UnknownSelect}
                          />
                        </div> */}
                        {/* loop 100
                    {ptTaeDetails.dosesOfTocilizumabNumOfDoses === 1 && (
                      <div className="dependent-section">
                        <div className="loop-section-ul">
                          <div className="loop-head">
                            <span className="question-weight"></span>
                            <div className="delete-btn-div">
                              <img
                                width="16px"
                                src="../Assets/images/Icon-material-delete.png"
                                alt=""
                              />
                            </div>
                          </div> */}

                        {/* Q100.
                          <div className="sub-question-bot-sapce answer-pad-left">
                            <div className="question question-weight">
                              <span>Q100.</span>
                              <span className="quest-text-pad">
                                What best describes the site of care where the
                                patient received the [Insert “first”,
                                “second”[…]”, “third […]”, etc., up to the
                                specified number of doses in Q51] tocilizumab?
                              </span>
                            </div>
                            <div className="double-dig-answer">
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    value={1}
                                    name="SiteOfCareTocilizumabOutpatientClinic"
                                  />
                                  <span className="radio-text-padding">
                                    Outpatient clinic
                                  </span>
                                </label>
                              </div>
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    value={2}
                                    name="SiteOfCareTocilizumabHospital"
                                  />
                                  <span className="radio-text-padding">
                                    Hospital
                                  </span>
                                </label>
                              </div>
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    value={3}
                                    name="SiteOfCareTocilizumabAtHome"
                                  />
                                  <span className="radio-text-padding">
                                    At home
                                  </span>
                                </label>
                              </div>
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    value={4}
                                    name="SiteOfCareTocilizumabOther"
                                  />
                                  <span className="radio-text-padding">
                                    Other, specify:{" "}
                                    <input
                                      className="input-dash"
                                      name="SiteOfCareTocilizumabOtherText"
                                      type="text"
                                      maxLength={100}
                                    />
                                  </span>
                                </label>
                              </div>
                              <div>
                                <label>
                                  <input type="radio" value={99} />
                                  <span className="radio-text-padding">
                                    Unknown
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div> */}

                        {tocilizumabChecked && (
                          <div className="dependent-section">
                            {doseOfTocilizumabDetails &&
                              doseOfTocilizumabDetails.length > 0 &&
                              doseOfTocilizumabDetails.map((data, index) => (
                                <div key={index} className="loop-section-ul">
                                  <div className="loop-head">
                                    <span className="question-weight">
                                      {GetOrdinal(index + 1)} Dose
                                    </span>
                                    {index !== 0 && (
                                      <div className="delete-btn-div">
                                        <img
                                          width="16px"
                                          src="../Assets/images/Icon-material-delete.png"
                                          alt=""
                                          onClick={() =>
                                            DeleteDoseOfTocilizumabRow(index)
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>

                                  {/* Q100. */}
                                  <div className="sub-question-bot-sapce answer-pad-left">
                                    <div className="question question-weight">
                                      <span>Q111.</span>
                                      <span className="quest-text-pad">
                                        What best describes the site of care
                                        where the patient received the{" "}
                                        <span className="question-weight">
                                          {GetOrdinal(index + 1)}{" "}
                                        </span>
                                        tocilizumab?
                                      </span>
                                    </div>
                                    <div className="double-dig-answer">
                                      <div>
                                        <label>
                                          <input
                                            type="radio"
                                            value={1}
                                            name={"siteOfCare_rd" + index}
                                            checked={data.siteOfCare_rd === 1}
                                            onChange={HandleRepeaterFieldChange}
                                            id={index}
                                          />
                                          <span className="radio-text-padding">
                                            Outpatient clinic
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label>
                                          <input
                                            type="radio"
                                            value={2}
                                            name={"siteOfCare_rd" + index}
                                            checked={data.siteOfCare_rd === 2}
                                            onChange={HandleRepeaterFieldChange}
                                            id={index}
                                          />
                                          <span className="radio-text-padding">
                                            Hospital
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label>
                                          <input
                                            type="radio"
                                            value={3}
                                            name={"siteOfCare_rd" + index}
                                            checked={data.siteOfCare_rd === 3}
                                            onChange={HandleRepeaterFieldChange}
                                            id={index}
                                          />
                                          <span className="radio-text-padding">
                                            At home
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label>
                                          <input
                                            type="radio"
                                            value={4}
                                            name={"siteOfCare_rd" + index}
                                            checked={data.siteOfCare_rd === 4}
                                            onChange={HandleRepeaterFieldChange}
                                            id={index}
                                          />
                                          <span className="radio-text-padding">
                                            Other, specify:{" "}
                                            <input
                                              className="input-dash"
                                              name={"siteOfCare_other"}
                                              value={data.siteOfCare_other}
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                              type="text"
                                              maxLength={100}
                                              id={index}
                                            />
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label>
                                          <input
                                            type="radio"
                                            value={99}
                                            name={"siteOfCare_rd" + index}
                                            checked={data.siteOfCare_rd === 99}
                                            onChange={HandleRepeaterFieldChange}
                                            id={index}
                                          />
                                          <span className="radio-text-padding">
                                            Unknown
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <ErrorField
                                    show={data.siteOfCare_other_unk_soft}
                                    message={CommonError_Msgs.UnknownSelect}
                                  />
                                  <ErrorField
                                    show={data.siteOfCare_rd_empty}
                                    message={Epcoritamab_Msgs.epco_siteofcare}
                                  />
                                  <ErrorField
                                    show={data.siteOfCare_other_empty}
                                    message={
                                      Epcoritamab_Msgs.epco_siteofcare_othertext
                                    }
                                  />
                                </div>
                              ))}
                            {doseOfTocilizumabDetails &&
                              doseOfTocilizumabDetails.length < 10 && (
                                <div className="sub-question-bot-sapce-ul mt-2">
                                  <div className="answer-list-text">
                                    <a onClick={AddDoseOfTocilizumabRow}>
                                      <img
                                        width="18px"
                                        src="../Assets/images/plus.png"
                                        alt=""
                                      />
                                    </a>
                                    <a
                                      onClick={AddDoseOfTocilizumabRow}
                                      className="radio-text-padding"
                                    >
                                      Click here to add another dose of
                                      Tocilizumab
                                    </a>
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                      </>
                    )}

                    {/* </div>
                        <div className="sub-question-bot-sapce-ul mt-2">
                          <div className="answer-list-text">
                            <a>
                              <img
                                width="18px"
                                src="../Assets/images/plus.png"
                                alt=""
                              />
                            </a>
                            <a className="radio-text-padding">
                              Click here to add another Hospitalization
                            </a>
                          </div>
                        </div>
                      </div>
                    )} */}

                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q113.</span>
                        <span className="quest-text-pad">
                          Did the patient receive additional lines of therapy?{" "}
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="">
                          <div>
                            <input
                              type="radio"
                              id="q91_1"
                              name="q91_lof"
                              value={1}
                              checked={ptTaeDetails.q91_lof === 1}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q91_1">
                              <span className="radio-text-padding">Yes</span>
                            </label>
                          </div>

                          <div className="">
                            <div>
                              <input
                                type="radio"
                                id="q91_0"
                                name="q91_lof"
                                value={0}
                                checked={ptTaeDetails.q91_lof === 0}
                                onChange={HandleFieldChange}
                              />
                              <label htmlFor="q91_0">
                                <span className="radio-text-padding">No</span>
                              </label>
                            </div>
                          </div>

                          <div className="">
                            <div>
                              <input
                                type="radio"
                                id="q91_99"
                                name="q91_lof"
                                value={99}
                                checked={ptTaeDetails.q91_lof === 99}
                                onChange={HandleFieldChange}
                              />
                              <label htmlFor="q91_99">
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ErrorField
                        show={softErrList.q91Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q91Empty}
                        message={Treatment_After_Msgs.Q91}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TreatmentAfterEpcoritamab;
