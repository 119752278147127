export const ToastTimeout = 4000;
export const HTTPResponse = {
  OK: 200,
  BadRequest: 400,
  Unauthorized: 401,
  NotFound: 404,
  NotAllowed: 405,
  Conflict: 409,
  InternalServerError: 500,
};
export const AppContextIntialState = {
  isLoading: false,
  showToast: false,
  toastMessage: "",
  actionPopUp: {
    isOpen: false,
    title: "Alert",
    description: "Are you sure?",
    confirmButtonLabel: "Yes",
    disableCancelBtn: false,
  },
  toastType: undefined,
  pageDataChanged: false,
  showTopMenu: false,
  activeTab: undefined,
  showCartPage: true,
  tmtPriorEpcoLOTCnt: null,
  tmtAfterEpcoLOTCnt: null,
};
export const ToastMessageType = {
  Success: 0,
  Failed: 1,
};

export const TopMenuItems = {
  Patients: 1,
  Centers: 2,
  Abstractors: 3,
  Logout: 4,
};
export const SideMenuItems = {
  EligibilityCriteria: 1,
  PatientScreening: 2,
  Demographics: 3,
  ClinicalCharacteristics: 4,
  EpcoritamabTreatment: 5,
  TreatmentsPriorEpcoritamab: 6,
  TreatmentsAfterEpcoritamab: 7,
  CarTCellTherapy: 8,
  HRU: 9,
  ValidateSubmit: 10,
};

export const SideMenuPageList = [
  { title: "ELIGIBILITY CRITERIA AND PATIENT SCREENING", notClickable: true },
  { id: 1, title: "ELIGIBILITY CRITERIA", path: "/EligibilityCriteria", hasParent: true }, 
  { id: 2, title: "PATIENT SCREENING", path: "/PatientScreening", hasParent: true },
  { id: 3, title: "DEMOGRAPHIC CHARACTERISTICS", path: "/Demographics", incomplete: false },
  { id: 4, title: "CLINICAL CHARACTERISTICS", path: "/ClinicalCharacteristics", incomplete: false },

  { title: "TREATMENT AND RESPONSE", notClickable: true },
  { id: 5, title: "EPCORITAMAB TREATMENT", path: "/Epcoritamab", hasParent: true, incomplete: false },
  { id: 6, title: "TREATMENTS PRIOR TO INITIATING EPCORITAMAB", path: "/TreatmentsPriorEpcoritamab", hasParent: true, notClickable: true },
  { id: 7, title: "TREATMENTS AFTER INITIATING EPCORITAMAB", path: "/TreatmentsAfterEpcoritamab", hasParent: true, notClickable: true },
  { id: 8, title: "INTENTION TO TREAT WITH CAR T CELL THERAPY", path: "/CARTCellTherapy", hasParent: true, incomplete: false },
  { id: 9, title: "HEALTHCARE RESOURCE UTILIZATION", path: "/HRU", incomplete: false },
  { id: 10, title: "VALIDATE & SUBMIT", path: "/ValidateSubmit"}
];

export const ToastMessages = {
  Save_Success: "Data has been Successfully saved.",
  Fail: "Oops!, Something went wrong.",
};

export const PageAction = {
  add: 1,
  edit: 2,
};

export const DefaultAGGridProps = {
  ROWS_PER_PAGE: 10,
  ROW_HEIGHT: 50,
  TABLE_HEIGHT: 50 * 10 + 100,
  NOT_FOUND: "No Records Found",
};
export const TableConstants = {
  PAGE_SIZE: 7,
  NOT_FOUND: "No Records Found",
};
export const PageDesignConstants = {
  backgroundColor: "#dff3f6",
  fontColor: "steelblue",
  fontWeight: "bold",
  PageDescriptionStyle: {
    fontWeight: "500",
    paddingBottom: "10px",
    textDecoration: "underline",
    fontStyle: "italic",
  },
};
export const DatePickerDefaultProps = {
  showMonthDropdown: true,
  showYearDropdown: true,
  dropdownMode: "select",
  placeholderText: "MM/DD/YYYY",
  dateFormat: "MM/dd/yyyy",
  //isClearable
  //closeOnScroll: true,
  //peekNextMonth:
};
export const ErrorMessages = {
  SAVE_ERROR: "Error occurred while saving.",
  LOAD_ERROR: "Error occurred while loading.",
  EMIAL_NONEXISTENT: "Email address does not exists.",
};

export const Role = {
  Admin: 1,
  Center: 2,
};
export const DatePart = {
  Day: 1,
  Month: 2,
  Year: 3
}
export const DayMonthUnknown = {
  DayUnk: 1,
  MonthUnk: 2,
  DayMonthUnk: 3
}
export const DefaultDate = "MM/DD/YYYY";

export const SupportiveCareEpcoritamab = {
  Dexamethasone: 1,
  Hydrocortisone: 2,
  Prednisone: 3,
  Prednisolone: 4,
  Methylprednisolone: 5,
  Other: 6,
}

export const MedicationType = {
	Antibiotics: 1,
  Antivirals: 2,
  Antifungals: 3,
  Immunoglobulin: 4,
  Granulocyte: 5,
  Tocilizumab: 6,
  Glucocorticoids: 7,
  Other: 8
}

export const TreatmentType = {
  Bendamustine: 1,
  Carboplatin: 2,
  Cisplatin: 3,
  Chlorambucil: 4,
  Cyclophosphamide: 5,
  Cytarabine: 6,
  Doxorubicin: 7,
  Etoposide: 8,
  Gemcitabine: 9,
  Ifosfamide: 10,
  Oxaliplatin: 11,
  Vincristine: 12,
  Rituximab: 13,
  Obinutuzumab: 14,
  Ofatumumab: 15,
  Tafasitamab: 16,
  Loncastatuximab: 17,
  Glofitamab: 18,
  Epcoritamab: 19,
  CAR_T: 20,
  Autologous_stem_cell_transplant: 21,
  Allogenic_stem_cell_transplant: 22,
  Radiation_therapy: 23,
  Brentuximab_vedotin: 24,
  Lenalidomide: 25,
  Pembrolizumab: 26,
  Polatuzumab_vedotin: 27,
  Selinexor: 28,
  Oth_Other: 29,
  Dexamethasone: 30,
  Hydrocortisone: 31,
  Methylprednisolone: 32,
  Prednisone: 33,
  Prednisolone: 34,
  Corti_Other: 35
}
